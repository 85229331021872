import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Lup Modules
import { LayoutModule } from './layouts/layout.module';

// Translate modules
import { TranslateModule } from '@ngx-translate/core';

// Languages
import './languages';

// Focus visible
import 'focus-visible/dist/focus-visible.min.js';

// Core Modules
import { FormlyModule } from '@ngx-formly/core';
import { NgxBootstrapModule } from './angie-shared/modules/ngx-bootstrap.module';
import { CoreModule } from './core/core.module';

import { environment } from '../environments/environment';
import { InsightsModule } from './modules/insights/insights.module';

/** As we are only testing Sentry, it is disabled on Production */
const providers =
	environment.env === 'staging'
		? [
				{
					provide: ErrorHandler,
					useValue: Sentry.createErrorHandler({
						showDialog: false
					})
				},
				{
					provide: Sentry.TraceService,
					deps: [Router]
				},
				{
					provide: APP_INITIALIZER,
					useFactory: () => () => {},
					deps: [Sentry.TraceService],
					multi: true
				}
			]
		: [];

/**
 * All of the required imports that we use inside our app module
 */
const imports = [
	InsightsModule,
	LayoutModule,
	BrowserAnimationsModule,
	AppRoutingModule,
	HttpClientModule,
	CoreModule.forRoot(),
	NgxBootstrapModule.forRoot(),
	TranslateModule.forRoot(),
	FormlyModule.forRoot({
		extras: {
			checkExpressionOn: 'changeDetectionCheck'
		}
	})
];

/**
 * All of the required declarations that we use inside our app module
 */
const declarations = [AppComponent];

/**
 * AppModule is our root level module - It is in charge for initial bootstrap of our app
 * will handle all things that are required for our app init and root level routing
 */
@NgModule({
	declarations,
	imports,
	providers,
	bootstrap: [AppComponent]
})
export class AppModule {}
