import { LayoutType } from '../../../core';
import { LayoutConfigBuilder } from '../../../layouts/default-layout/builders/layout-config.builder';
import { LayoutConfigPresets } from '../../../layouts/default-layout/models/layout-structures.models';

export enum LearningJourneyProgressionRoutesConfig {
	LJ_PROGRESSION_BASE_URL = 'learning-journey-progression',
	LJ_PROGRESSION_DETAILS_ID_VERSION_USER_ENROLLMENT_SEGMENT = ':workflow_id/:version_id/:user_id/:enrollment_id'
}

const layoutConfig = new LayoutConfigBuilder(LayoutConfigPresets.default)
	.setBodyCssClasses(['bg--white'])
	.showDefaultSubHeader(false)
	.setContentCssClasses(['wrap--no-subheader'])
	.setHideAdminNav(true)
	.setAdminWrapCssClasses(['admin-wrap--no-sidenav'])
	.build();

export const learningJourneyPageData = {
	layout: LayoutType.LARGE,
	layoutConfig: layoutConfig
};
