import { APP_INITIALIZER, NgModule, inject } from '@angular/core';
import { InsightsService } from './services/insights.service';

@NgModule({
	providers: [
		InsightsService,
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: () => {
				const dataInsightsService = inject(InsightsService);
				dataInsightsService.init();

				return () => {};
			},
			deps: [InsightsService]
		}
	]
})
export class InsightsModule {}
