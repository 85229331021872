import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { LuFormlyInputBuilder } from 'lu-formly';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/angie-shared/components/base/base.component';
import { CoreRoutes, DataService, DsConfig, ListResponse, LuUser, StateService } from 'src/app/core';
import { LuUserRoleCode } from 'src/app/core/core.models';
import { TYPE_AHEAD_DEBOUNCE_TIME } from 'src/app/globals';
import { environment } from 'src/environments/environment';
import { Portal } from './my-portals.models';

/**
 * My portals (AKA portal jumper)
 *
 * Show list of all the portals within one realm.
 */
@Component({
	selector: 'angie-my-portals',
	templateUrl: './my-portals.component.html',
	styleUrls: ['./my-portals.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyPortalsComponent extends BaseComponent implements OnInit {
	/**
	 * Reference to portals list - we'll need this one for scroll functionality on load more
	 */
	@ViewChild('clientsList') clientsList: any;
	/**
	 * List of all loaded portals within portal jumper
	 */
	portals: Portal[] = [];
	/**
	 * Loading indicator
	 */
	loading: boolean;
	/**
	 * After we search for specific portal, we'll emit value to this behaviour subject
	 */
	search$: BehaviorSubject<string> = new BehaviorSubject<string>('');
	/**
	 * Has Next indicator - in charge for infinite scroll
	 */
	hasNext: boolean;
	/**
	 * Page - for infinite scroll
	 */
	page = 1;
	/**
	 * CDNPath - it is required in HTML for blank state
	 */
	cdnPath: string = environment.cdnBase;
	/**
	 * Lu user instance - we show different icon if it is admin or not
	 */
	luUser: LuUser = this.stateService.luUser;
	/**
	 * LuUserRoleCode enum to have comparations inside HTML
	 */
	LuUserRoleCode = LuUserRoleCode;

	/**
	 * Formly Search
	 */
	searchForm = new UntypedFormGroup({});
	searchModel = { search: '' };
	searchField: FormlyFieldConfig[];

	/**
	 * Constructor
	 */
	constructor(
		private readonly dataService: DataService,
		private readonly stateService: StateService,
		private readonly changeDetectorRef: ChangeDetectorRef,
		private readonly translateService: TranslateService
	) {
		super();
	}

	ngOnInit(): void {
		this.buildForm();
	}
	/**
	 * Load Portals on dropdown shown
	 */
	loadPortals(): void {
		this.destroy$ = new Subject<void>();
		this.search$
			.pipe(
				takeUntil(this.destroy$),
				debounceTime(TYPE_AHEAD_DEBOUNCE_TIME),
				distinctUntilChanged(),
				tap(() => {
					this.resetData();
				}),
				switchMap(searchString => this.dataService.getList<Portal>(this.configRequest(searchString))),
				tap(result => {
					this.handleResults(result);
					this.changeDetectorRef.detectChanges();
				})
			)
			.subscribe();
	}

	/**
	 * Get configuration for data service based on search
	 */
	private configRequest(search?: string): DsConfig {
		const dsConfig: DsConfig = this.dataService.getDefaultConfig(CoreRoutes.MY_PORTALS, {
			page: this.page,
			search
		});
		dsConfig.primarySpinnerConfig.show = false;
		this.loading = true;
		return dsConfig;
	}

	/**
	 * Load More - infinite scroll inside portal component
	 */
	loadMore(): void {
		if (this.hasNext) {
			this.page++;
			const initiallyScrolled = this.clientsList.nativeElement.scrollTop;
			this.dataService
				.getList<Portal>(this.configRequest(this.searchModel?.search))
				.pipe(takeUntil(this.destroy$))
				.subscribe(result => {
					this.handleResults(result);
					this.changeDetectorRef.detectChanges();
					this.clientsList.nativeElement.scrollTop = initiallyScrolled;
				});
		}
	}

	/**
	 * Once it is hidden - we reset data and complete subscription
	 */
	onHidden(): void {
		this.destroy$.next();
		this.destroy$.complete();
		this.resetData();
	}

	/**
	 * Reset data to default state (empty results set / start from first page / loading false
	 */
	resetData(): void {
		this.page = 1;
		this.loading = false;
		this.portals = [];
	}

	/**
	 * After we fetch results - handle these accordingly
	 */
	handleResults(result: ListResponse<Portal>): void {
		this.portals = [...this.portals, ...result.data];
		this.loading = false;
		this.hasNext = result.has_next;
	}
	buildForm(): void {
		const searchField = new LuFormlyInputBuilder(
			'search',
			this.translateService.instant('lup.header.my_portals'),
			this.translateService.instant('lup.header.my_portals'),
			'def-focus'
		)
			.hideWrapperLabel(true)
			.build();

		searchField.templateOptions.keyup = e => {
			this.search$.next(e.model.search);
		};
		this.searchField = [searchField];
	}
}
