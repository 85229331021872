import { SessionStorageService } from '../../../core/services/session-storage.service';
import { LocalStorageService } from '../../../core/services/local-storage.service';

export interface LuStickyFilters {
	[path: string]: LuStickyFilter;
}
export interface LuStickyFilter {
	[modelName: string]: any;
}

export interface ILuStickyFilter {
	stickyFilter: LuStickyFilter;
	saveFilter(path: string, modelName: string, data: any): void;
	clearFilter(path: string, modelName?: string): void;
}

export const LU_STICKY_FILTERS_STORAGE_KEY = 'luStickyFilters';

export type StorageService = SessionStorageService | LocalStorageService;
