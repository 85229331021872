import { LayoutType } from 'src/app/core';
import { LayoutConfigBuilder } from 'src/app/layouts/default-layout/builders/layout-config.builder';
import { LayoutConfigPresets } from 'src/app/layouts/default-layout/models/layout-structures.models';

export enum LearningJourneyRoutesConfig {
	LJ_BASE_URL = 'learning-journey',
	LJ_ID_VERSION_SEGMENT = ':id/:version',
	EDIT_LJ_SEGMENT = 'edit',
	ENROLLMENTS_SEGMENT = 'enrollments'
}

export const LJRouteTitles = {
	createLearningJourney: 'lup.ljw.create_learning_journey',
	listLearningJourneys: 'lup.ljw.learning_journeys',
	enrollments: 'Enrollments'
};

const learningJourneyCanvasLayoutConfig = new LayoutConfigBuilder(LayoutConfigPresets.minimal)
	.setBodyCssClasses(['bg--dots'])
	.setContentCssClasses(['wrap--minimal-and-subheader', 'wrap--all-items-responsive'])
	.setHeaderCssClasses(['header--no-scroll'])
	.setFooterCssClasses(['footer-white'])
	.setAdminWrapCssClasses(['admin-wrap--no-sidenav'])
	.showDefaultSubHeader(true)
	.build();

const listLearningJourneysLayoutConfig = new LayoutConfigBuilder(LayoutConfigPresets.default)
	.setBodyCssClasses(['bg--gray-lighter'])
	.build();

const learningJourneyEnrollmentsLayoutConfig = new LayoutConfigBuilder(LayoutConfigPresets.minimal)
	.setBodyCssClasses(['bg--gray-lighter'])
	.setContentCssClasses(['wrap--minimal-and-subheader', 'wrap--all-items-responsive'])
	.setHeaderCssClasses(['header--no-scroll'])
	.setFooterCssClasses(['footer-white'])
	.setAdminWrapCssClasses(['admin-wrap--no-sidenav'])
	.showDefaultSubHeader(true)
	.build();

export const dataForCanvasLayoutConfig = {
	layout: LayoutType.BASE,
	layoutConfig: learningJourneyCanvasLayoutConfig
};

export const dataForEnrollmentsLayoutConfig = {
	layout: LayoutType.LARGE,
	layoutConfig: learningJourneyEnrollmentsLayoutConfig
};

export const dataForListLearningJourneysLayoutConfig = {
	layout: LayoutType.LARGE,
	layoutConfig: listLearningJourneysLayoutConfig,
	breadcrumb: 'lup.ljw.breadcrumb_list'
};
