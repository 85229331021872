import { Injectable } from '@angular/core';
import { DataService, DsConfig } from '../../core';
import { Observable } from 'rxjs';
import { ProxyBannerMessage } from '../default-layout/default.layout.models';
import { BaseResponse } from 'lu-services';

@Injectable()
export class ProxyBannerService {
	constructor(private dataService: DataService) {}

	recordAction(resolutionMessage = ''): Observable<BaseResponse<ProxyBannerMessage>> {
		const dsConfig: DsConfig = this.dataService.getDefaultConfig('/users/return_to_impersonator.json');
		return this.dataService.post<ProxyBannerMessage>(dsConfig, { resolution_message: resolutionMessage });
	}
}
