/**
 * Support access routes
 */
export class SupportAccessRoutes {
	/**
	 * Check Support Access API
	 */
	public static CHECK_SUPPORT_ACCESS = '/support/confirmed_for_access.json';
	/**
	 * Send Verification Email if profile not verified
	 */
	public static SEND_VERIFICATION_EMAIL = '/support_desk_users/confirmations/create.json';
}
