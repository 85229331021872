import { angieRoutePrefix } from 'src/app/globals';

/**
 * Flash Message Routes definition
 */
export class FlashMessagesRoutes {
	/**
	 * Dismiss flash message
	 */
	public static FLASH_MESSAGE_DISMISS = (id: number): string =>
		angieRoutePrefix.concat(`/flash-messages/${id}/dismiss.json`);
	/**
	 * Fetch Questions if flash message style is - IN_APP_SURVEY
	 */
	public static FLASH_MESSAGE_FETCH_QUESTIONS = (id: number): string =>
		angieRoutePrefix.concat(`/flash-messages/${id}/questions.json`);
}
