import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AngieAppRoutes } from 'src/app/angie-app.routes';
import { LuUserRoleCode, StateService } from 'src/app/core';
import { IltsRoutesConfig } from '../../modules/instructor-led-trainings/routes/ilts-routes.config';

@Injectable({
	providedIn: 'root'
})
export class CanActivateLiveTrainingsGuard {
	private allowedChildRoutes = [
		IltsRoutesConfig.OVERVIEW_TAB_URL_SEGMENT,
		IltsRoutesConfig.SESSIONS_TAB_URL_SEGMENT,
		IltsRoutesConfig.EDIT_TRAINING_URL_SEGMENT
	];
	private stateRouteSegments: IltsRoutesConfig[] = [];

	constructor(
		private stateService: StateService,
		private router: Router
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const { portalMeta, luUser } = this.stateService;

		const currentRouteSegments = state.url.split('/');
		this.stateRouteSegments = Object.values(IltsRoutesConfig).filter(
			value => currentRouteSegments.indexOf(value) !== -1
		);

		if (!portalMeta.can_access_live_trainings && !this.canAccessIltChildRoute()) {
			return this.allowOrRedirect(false);
		}

		switch (luUser.role_code) {
			case LuUserRoleCode.ADMIN:
				return this.allowOrRedirect(true);

			case LuUserRoleCode.MANAGER:
				return this.allowOrRedirect(luUser.permissions.can_act_as_instructor);

			case LuUserRoleCode.INSTRUCTOR:
				return true;

			default:
				return this.allowOrRedirect(false);
		}
	}

	allowOrRedirect(canPass: boolean): boolean {
		if (canPass) {
			return true;
		}
		this.router.navigate([AngieAppRoutes.FORBIDDEN]);
		return false;
	}

	private canAccessIltChildRoute(): boolean {
		const { trainings_read_only_enabled } = this.stateService.portalMeta;
		return (
			trainings_read_only_enabled &&
			this.stateRouteSegments.some(route => this.allowedChildRoutes.indexOf(route) !== -1)
		);
	}
}

export const canActivateLiveTrainingsGuardFn: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
) => {
	return inject(CanActivateLiveTrainingsGuard).canActivate(route, state);
};
