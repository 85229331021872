import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LuModalConfig } from 'lu-modal';
import { LuResourcesTile } from '../../angie-smart-search/models/tiles.models';
import { NullStateOptions } from '../../null-state/null-state.models';

/**
 * Resources description modal component
 *
 * Show information about resource
 */
@Component({
	templateUrl: './resources-description-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourcesDescriptionModalComponent {
	/**
	 * Modal Options Config - initialState passed
	 */
	modalOptions: LuModalConfig;
	/**
	 * Null State Options - initialState passed
	 */
	nullStateOptions: NullStateOptions;
	/**
	 * Resource - initialState passed
	 */
	resource: LuResourcesTile;
}
