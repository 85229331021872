import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/angie-shared/components/base/base.component';
import { avatarInitials } from 'src/app/angie-shared/utils';
import { AngieClientAction, CoreRoutes, DataService, DsConfig, StageChange, StateService } from 'src/app/core';
import { environment } from 'src/environments/environment';
import { Message } from './inbox.models';

/**
 * InboxComponent
 *
 * in charge for handling inbox dropdown layout where we would see list of unread messages
 */
@Component({
	selector: 'angie-inbox',
	templateUrl: './inbox.component.html',
	styleUrls: ['./inbox.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InboxComponent extends BaseComponent implements OnInit, OnDestroy {
	/**
	 * Avatar Initials util that we'll use to get only sender's initials
	 */
	avatarInitials = avatarInitials;
	/**
	 * Loading indecator
	 */
	loading: boolean;
	/**
	 * List of messages that is loaded
	 */
	messages: Message[];
	/**
	 * Unread messages that are read from state and displayed as bubble on envelop button
	 */
	unreadMessages = this.stateService.unreadMessages;
	/**
	 * CDN path that is used in HTML to get img (blank state)
	 */
	cdnPath: string = environment.cdnBase;

	/**
	 * Constructor
	 */
	constructor(
		private readonly dataService: DataService,
		private readonly stateService: StateService,
		private readonly changeDetectorRef: ChangeDetectorRef
	) {
		super();
	}

	/**
	 * OnInit LifeCycle hook
	 */
	ngOnInit(): void {
		this.dataService.stateChange$.pipe(takeUntil(this.destroy$)).subscribe((stateChange: StageChange) => {
			if (stateChange.action === AngieClientAction.MESSAGES_RELOAD) {
				// TODO handle nicely unread messages
				console.log('TODO handle reload nicely');
			}
		});
	}

	/**
	 * LoadInbox messages once inbox has been shown
	 */
	loadInbox(): void {
		const dsConfig: DsConfig = this.dataService.getDefaultConfig(CoreRoutes.INBOX);
		dsConfig.primarySpinnerConfig.show = false;
		this.loading = true;
		this.dataService
			.getList<Message>(dsConfig)
			.pipe(takeUntil(this.destroy$))
			.subscribe(result => {
				this.messages = result.data;
				this.loading = false;
				this.changeDetectorRef.detectChanges();
			});
	}
}
