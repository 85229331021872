<nav class="sub-nav" role="navigation" aria-label="Secondary" dropdown angieCloseOnEscapeKey>
	<div class="overlay"></div>
	<div *ngIf="middleNavigationItems.length" class="dropdown-toggle" [ngClass]="{'hidden': windowSize?.w > screenBreakPoint}" dropdownToggle>
		<a type="button" class="sub-nav__link active" tabindex="0" *ngIf="activeItem">
			<span>{{ activeItem.label | translate }}</span>
			<lu-icon class="btn__ico btn__ico--r" [type]="LuIconType.SOLID" icon="caret-down"></lu-icon>
		</a>
	</div>
	<ul class="sub-nav smart-list inline" *ngIf="windowSize?.w > screenBreakPoint">
		<li class="sub-nav__item" *ngFor="let item of middleNavigationItems">
			<a class="sub-nav__link"
				 *ngIf="!item.external"
				 (click)="updateActive(item)"
				 [routerLink]="item.url"
				 [ngClass]="{'active': item.active}">
				{{ item.label | translate }}
			</a>
			<a class="sub-nav__link"
				 [href]="item.url"
				 (click)="navigateAndFocusToMainContent($event, item.url)"
				 [ngClass]="{'active': item.active}"
				 *ngIf="item.external">
				{{ item.label | translate }}
			</a>
		</li>
	</ul>
	<div class="dropdown-menu dropdown-menu--sub-nav dropdown-menu-center" [ngClass]="{'hidden': windowSize?.w > screenBreakPoint}" *dropdownMenu>
		<ul class="sub-nav">
			<li class="sub-nav__item" *ngFor="let item of middleNavigationItems">
				<a class="sub-nav__link"
					 (click)="updateActive(item)"
					 [routerLink]="item.url"
					 *ngIf="!item.external"
					 [ngClass]="{'active': item.active}">
					{{ item.label | translate }}
				</a>
				<a class="sub-nav__link"
					 [href]="item.url"
					 [ngClass]="{'active': item.active}"
					 *ngIf="item.external">
					{{ item.label | translate }}
				</a>
			</li>
		</ul>
	</div>
</nav>
