import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { LuIconType } from 'lu-icon';
import { BaseNavigationComponent } from 'src/app/angie-shared/components/base/base-navigation.component';
import { UserService } from 'src/app/angie-shared/services/user.service';
import { IGroupedMenuNav, NavigationService, PrimaryNavigationItem, StateService } from 'src/app/core';
import { WindowRefService } from 'lu-services';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
	selector: 'angie-primary-admin-nav',
	templateUrl: './primary-admin-nav.component.html',
	styleUrls: ['./primary-admin-nav.component.scss']
})
export class PrimaryAdminNavComponent extends BaseNavigationComponent implements OnInit {
	@Output() clickToggle = new EventEmitter<boolean>();

	public readonly LuIconType = LuIconType;
	navOpened: boolean = true;
	navHovered: boolean = false;
	mobileOpened: boolean = false;

	/**
	 * Toggle for open/close admin nav
	 */
	toggle(): void {
		this.navOpened = !this.navOpened;
		this.localStorageService.setData('navOpen', this.navOpened);
		this.clickToggle.emit(this.navOpened);
	}

	toggleMobile(): void {
		this.mobileOpened = !this.mobileOpened;
	}

	/**
	 * Primary Navigation Items - fetch from service and shown in UI
	 */
	navs: IGroupedMenuNav;
	/**
	 * Current Active Primary Navigation item
	 */
	currentNav: PrimaryNavigationItem;

	constructor(
		changeDetectorRef: ChangeDetectorRef,
		navigationService: NavigationService,
		public router: Router,
		private readonly stateService: StateService,
		private readonly userService: UserService,
		private readonly windowService: WindowRefService,
		private readonly localStorageService: LocalStorageService
	) {
		super(router, changeDetectorRef, navigationService);
		this.navigationService
			.getPrimaryNavigation()
			.pipe(
				takeUntil(this.destroy$),
				map(nav =>
					nav.filter(navItem =>
						navItem.permissionCheck
							? navItem.permissionCheck({
									stateService: this.stateService,
									userService: this.userService
								})
							: true
					)
				)
			)
			.subscribe(navigation => {
				this.navs = this.navigationService.groupNavigationOptions(navigation);
			});
	}

	/**
	 * OnInit LifeCycle Hook
	 */
	ngOnInit(): void {
		super.ngOnInit();
		this.updateCurrentNav(this.router.url.toString().split('?')[0]);
		this.checkNavStatus();
	}

	/**
	 * Url redirect angie/main_app
	 */
	redirect(nav: PrimaryNavigationItem): void {
		if (nav?.external) {
			this.windowService.getWindow().location.href = nav.url;
		} else {
			this.router.navigateByUrl(nav.url);
		}
		this.mobileOpened = false;
	}

	/**
	 * On route change callback function
	 */
	protected onRouteChangeCallback(event): void {
		this.updateCurrentNav(event.urlAfterRedirects);
	}

	/**
	 * Update current Nav if URL matches
	 */
	private updateCurrentNav(url: string): void {
		url = this.navigationService.removeParams(`/${url.split('/')[1]}`);
		const { group_a, group_b, group_c, group_d } = this.navs;
		const allNavs = [...group_a, ...group_b, ...group_c, ...group_d];

		for (const nav of allNavs) {
			if (nav.url === url) {
				this.currentNav = nav;
				this.changeDetectorRef.detectChanges();
				return;
			}
		}
		// if there is no active nav item -> fallback to default one
		this.currentNav = allNavs[0];
	}

	/**
	 * Check for amdin nav status collapsed/expanded
	 */
	private checkNavStatus(): void {
		const navStatus = this.localStorageService.getData('navOpen');

		if (navStatus) {
			this.navOpened = JSON.parse(navStatus);
			this.clickToggle.emit(this.navOpened);
		} else {
			this.localStorageService.setData('navOpen', this.navOpened);
		}
	}
}
