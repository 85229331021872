// imports
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { FORMLY_CONFIG } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { MetaService } from '../angie-shared/services';
import { XsrfInterceptor } from './interceptors/xsrf.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ActionFooterService } from './services/action-footer.service';
import { DataService } from './services/data.service';
import { registerTranslateExtension } from './services/formy-extend.service';
import { LanguageService } from './services/language.service';
import { MinimalHeaderService } from './services/minimal-header.service';
import { NavigationService } from './services/navigation.service';
import { S3Service } from './services/s3.service';
import { SpinnerService } from './services/spinner.service';
import { StateService } from './services/state.service';
import { WindowRefService } from './services/window-ref.service';

/**
 * State Factory - called on APP_INIT so we make sure we fetch data before app is even loaded
 */
export function stateFactory(provider: StateService): () => Observable<boolean> {
	return () => provider.initialLoad();
}

/**
 * Core Module
 *
 * In charge for handling bootstrapping of our application
 *
 * We define all of the things that are strictly related to core of the app
 */

@NgModule()
export class CoreModule {
	static forRoot(): ModuleWithProviders<CoreModule> {
		return {
			ngModule: CoreModule,
			providers: [
				DataService,
				S3Service,
				NavigationService,
				ActionFooterService,
				SpinnerService,
				StateService,
				MetaService,
				LanguageService,
				WindowRefService,
				MinimalHeaderService,
				{
					provide: APP_INITIALIZER,
					useFactory: stateFactory,
					deps: [StateService],
					multi: true
				},
				{
					provide: HTTP_INTERCEPTORS,
					useClass: XsrfInterceptor,
					multi: true
				},
				{
					provide: HTTP_INTERCEPTORS,
					useClass: AuthInterceptor,
					multi: true
				},
				{
					provide: FORMLY_CONFIG,
					multi: true,
					useFactory: registerTranslateExtension,
					deps: [TranslateService]
				}
			]
		};
	}
}
