import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { map, takeUntil } from 'rxjs/operators';
import { BaseNavigationComponent } from '../../../../angie-shared/components/base/base-navigation.component';
import { A11yHelperService } from '../../../../angie-shared/services/a11y-helper.service';
import { UserService } from '../../../../angie-shared/services/user.service';
import { NavigationService, SecondaryNavigationItem, StateService } from '../../../../core';
import { WindowSize, WindowSizerService } from '../../../../core/services/window-sizer.service';
import { SCREEN_BREAK_POINT } from '../../../../globals';

@Component({
	selector: 'angie-middle-nav',
	templateUrl: './middle-nav.component.html',
	styleUrls: ['./middle-nav.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MiddleNavComponent extends BaseNavigationComponent implements OnInit {
	/**
	 * Middle Navigation items array - subscribed from service
	 */
	middleNavigationItems: SecondaryNavigationItem[] = [];
	/**
	 * Access dropdown directive so we can close it on window breakpoint if it is opened
	 */
	@ViewChild(BsDropdownDirective) secondaryNavigationDropdown: BsDropdownDirective;
	/**
	 * Window size - subscribe from window sizer service where we have conditionals based on browser width
	 */
	windowSize: WindowSize;
	/**
	 * Track middle navigation active item
	 */
	activeItem: SecondaryNavigationItem;
	/**
	 * Mid navigation break into pills once we reach this defined breakpoint
	 */
	screenBreakPoint = SCREEN_BREAK_POINT;

	constructor(
		router: Router,
		changeDetectorRef: ChangeDetectorRef,
		navigationService: NavigationService,
		private readonly stateService: StateService,
		private readonly userService: UserService,
		private readonly windowSizer: WindowSizerService,
		private readonly a11yHelperService: A11yHelperService
	) {
		super(router, changeDetectorRef, navigationService);
	}

	/**
	 * OnInit LifeCycle Hook
	 *
	 * We subscribe to service nad filter items:
	 * - based on role
	 * - based on required meta (portal meta)
	 * - we update active navigation route if url matches some of these routes
	 */
	ngOnInit(): void {
		super.ngOnInit();
		this.subscribeToMiddleNavigationChanges();
		this.subscribeToWindowResize();
	}

	/**
	 * After navigation - focus to main content (append #main-content) to url
	 */
	navigateAndFocusToMainContent(event: Event, url: string, isExternal: boolean = true): void {
		if (this.a11yHelperService.checkIfClickedByKeyboard(event)) {
			event.preventDefault();
			const mainContentUrl = url + '#main-content';

			if (isExternal) {
				window.location.href = mainContentUrl;
				return;
			}
			this.router.navigate([mainContentUrl]);
		}
	}

	/**
	 * On middle navigation click - update active item
	 */
	updateActive(item: SecondaryNavigationItem): void {
		this.middleNavigationItems = this.middleNavigationItems.map(nav => {
			if (nav.id === item.id) {
				this.activeItem = nav;
				return {
					...nav,
					active: true
				};
			}
			return {
				...nav,
				active: false
			};
		});
	}

	/**
	 * If url matches - set active item to the right one that matches URL
	 */
	updateActiveRoute(url: string): void {
		this.middleNavigationItems = this.middleNavigationItems.map(item => {
			if ((url.indexOf(item.url) === 0 && url.length === item.url.length) || item.active) {
				this.activeItem = item;
				return {
					...item,
					active: true
				};
			}
			return {
				...item,
				active: false
			};
		});
	}

	/**
	 * Clear out navigation items after navigation ends
	 */
	protected onRouteChangeCallback(): void {
		// this.navigationService.clearMiddleNavigation();
	}

	private subscribeToMiddleNavigationChanges(): void {
		this.navigationService
			.getMiddleNavigation()
			.pipe(
				takeUntil(this.destroy$),
				map(nav =>
					nav.filter(navItem =>
						navItem.permissionCheck
							? navItem.permissionCheck({
									stateService: this.stateService,
									userService: this.userService
								})
							: true
					)
				)
			)
			.subscribe(navigation => {
				this.middleNavigationItems = navigation;
				const url = this.navigationService.removeParams(this.router.url);
				this.updateActiveRoute(url);
				this.changeDetectorRef.detectChanges();
			});
	}

	/**
	 * Subscribe to window resize
	 */
	private subscribeToWindowResize(): void {
		this.windowSizer.resizeObservable$.pipe(takeUntil(this.destroy$)).subscribe((size: WindowSize) => {
			this.windowSize = {
				...size
			};
			// if we resize window above breakpoint - we want to close dropdown since it's not visible
			if (this.windowSize.w > this.screenBreakPoint) {
				this.secondaryNavigationDropdown.isOpen = false;
			}
			this.changeDetectorRef.detectChanges();
		});
	}
}
