import { FormlyExtension, FormlyFieldConfig } from '@ngx-formly/core';
import { FieldType } from 'lu-formly';

// Radio and MultiCheckbox have issues with disabled properties on options for some reason when using ivy
// More on this one https://github.com/ngx-formly/ngx-formly/issues/2532#issuecomment-832734418
export class RadioAndMultiCheckBoxDisableFix implements FormlyExtension {
	postPopulate(fieldConfig: FormlyFieldConfig): void {
		const isRadioAndCheckbox = [FieldType.RADIO, FieldType.MULTICHECKBOX].includes(fieldConfig.type as FieldType);
		if (!fieldConfig.templateOptions || !isRadioAndCheckbox) {
			return;
		}

		fieldConfig.templateOptions = {
			...fieldConfig.templateOptions,
			disabled: fieldConfig.templateOptions.disabled ? fieldConfig.templateOptions.disabled : undefined
		};
	}
}
