import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LuModalConfig } from 'lu-modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { MetaService } from 'src/app/angie-shared/services';
import { CoreRoutes, DataService, WindowRefService } from 'src/app/core';
import { LanguageService } from 'src/app/core/services/language.service';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../../base/base.component';
import { AvailableLanguage } from './language-pick.models';

/**
 * Language Pick Modal
 *
 * list out and choose one out of all available languages where user can choose all of the available languages for specific portal
 */
@Component({
	selector: 'angie-language-pick-modal',
	templateUrl: './language-pick-modal.component.html',
	styleUrls: ['./language-pick-modal.component.scss']
})
export class LanguagePickModalComponent extends BaseComponent implements OnInit {
	/**
	 * List of available languages which we'll render inside modal
	 */
	availableLanguages: AvailableLanguage[];
	/**
	 * CDN Path for fetching languages images
	 */
	cdnPath = environment.cdnBase;
	/**
	 * Modal options
	 */
	modalOptions: LuModalConfig = {
		title: this.translate.instant('lup.header.set_language_title'),
		close: () => {
			this.modalRef.hide();
		},
		modalClass: 'modal-cnt--sm'
	};

	/**
	 * Constructor
	 */
	constructor(
		private readonly languageService: LanguageService,
		private readonly modalRef: BsModalRef,
		private readonly metaService: MetaService,
		private readonly dataService: DataService,
		private readonly translate: TranslateService,
		private readonly changeDetectorRef: ChangeDetectorRef,
		private toastrService: ToastrService,
		private windowRef: WindowRefService
	) {
		super();
	}

	ngOnInit(): void {
		this.languageService.languageUpdated$.pipe(takeUntil(this.destroy$)).subscribe((languageUpdated: boolean) => {
			if (languageUpdated) {
				this.windowRef.getWindow().location.reload();
			}
		});
	}

	/**
	 * Set Language
	 *
	 * once we choose language from list - we send data to BE and update language in UI
	 */
	setLanguage(code: string, id: number, name: string): void {
		this.languageService.language.code = code;
		this.languageService.language.name = name;
		this.updateLanguage(id)
			.pipe(
				switchMap(() => this.languageService.getLanguage()),
				takeUntil(this.destroy$)
			)
			.subscribe(([languageData, angularLocale]) => {
				this.languageService.setLanguage(languageData, angularLocale);
				this.metaService.setData();
				this.modalRef.hide();
				this.toastrService.success(this.translate.instant('lup.my_profile_new.success.message.language'));
				this.languageService.languageUpdated$.next(true);
				this.changeDetectorRef.detectChanges();
			});
	}

	/**
	 * Update language in BE before we fetch language through language Service
	 */
	private updateLanguage(lang: number): Observable<any> {
		// TODO add toaster
		const dsConfig = this.dataService.getDefaultConfig(CoreRoutes.LANGUAGES);
		return this.dataService.put(dsConfig, { lang });
	}
}
