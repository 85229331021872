import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

/**
 * Helper service regarding a11y
 */
@Injectable({
	providedIn: 'root'
})
export class A11yHelperService {
	constructor(@Inject(DOCUMENT) private readonly document: Document) {}

	/**
	 * Check if click event was triggered by keyboard
	 */
	checkIfClickedByKeyboard(event): boolean {
		return (event.detail === 0 && !(this.document as any).documentMode) || event.buttons === 1;
	}
}
