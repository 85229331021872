import { Injectable } from '@angular/core';
import { LuUserPermissionsModel, LuUserRoleCode, StateService } from '../../core';
import { SessionModel } from '../../modules/instructor-led-trainings/models/session.model';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	roleCode: LuUserRoleCode = this.stateService.luUser?.role_code;
	permissions: LuUserPermissionsModel = this.stateService.luUser?.permissions;
	pm_id: number = this.stateService.luUser?.pm_id;

	constructor(private readonly stateService: StateService) {}

	public isAdmin(): boolean {
		return this.roleCode === LuUserRoleCode.ADMIN;
	}

	public isInstructor(): boolean {
		return this.roleCode === LuUserRoleCode.INSTRUCTOR;
	}

	public isManager(): boolean {
		return this.roleCode === LuUserRoleCode.MANAGER;
	}

	public isManagerWithInstructorPermissions(): boolean {
		return this.isManager() && this.permissions.can_act_as_instructor;
	}

	public isLearner(): boolean {
		return this.roleCode === LuUserRoleCode.MEMBER;
	}

	public isSessionInstructor(session: SessionModel): boolean {
		return session.instructors.map(instructor => instructor.pm_id).includes(this.pm_id);
	}

	public canManageTraining(): boolean {
		return this.permissions.can_manage_trainings;
	}

	public canManageSessions(): boolean {
		return this.permissions.can_manage_sessions;
	}

	public canActAsInstructor(): boolean {
		return this.permissions.can_act_as_instructor;
	}

	public tutorCanCreateCourses(): boolean {
		return this.permissions.tutor_can_create_courses;
	}

	public currentUserEditingHimself(user_id: number): boolean {
		return this.stateService.luUser.id === user_id;
	}

	// Where `portal_membership_type_id` is type id of a user how is being edited
	public canEditAdminFields(portal_membership_type_id: number): boolean {
		// admin can edit fields, manager can edit only if edited user is not admin
		return this.isAdmin() || portal_membership_type_id !== LuUserRoleCode.ADMIN;
	}

	public canEditLoginFields(): boolean {
		const { readonly_login_field } = this.stateService.portalMeta;
		// admin can edit fields, other can edit only if readonly login field is disabled
		return this.isAdmin() || !readonly_login_field;
	}

	public tutorCanEditCourses(): boolean {
		return this.permissions.tutor_can_edit_their_courses;
	}

	public canCreateCourses(): boolean {
		return this.permissions.can_create_courses;
	}

	public canEditCourses(): boolean {
		return this.permissions.can_edit_this_course;
	}

	public canUnEnrollLearnerFromCourse(): boolean {
		return this.permissions.can_unenroll_learner_from_course;
	}

	public canMarkComplete(): boolean {
		return this.permissions.can_mark_complete;
	}

	public canEnrollLearnerOnCourse(): boolean {
		return this.permissions.can_enroll;
	}

	public canDeleteUser(): boolean {
		return this.permissions.can_delete_users;
	}

	public canAddUsersToSession(): boolean {
		return this.permissions.can_add_users_to_session;
	}

	public AdminCanAccessPortalSettings(): boolean {
		return this.permissions.admin_can_access_portal_settings;
	}

	public canImpersonateUser(): boolean {
		return this.stateService.portalMeta.proxy_user_enabled;
	}

	public canAccessLearningJourneys(): boolean {
		return this.permissions.can_manage_learning_journey;
	}
}
