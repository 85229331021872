<nav>
	<ul data-qa-id="navbar_link" class="main-nav-learner">
		<li *ngFor="let navItem of learnerNavigationItems">
			<a class="main-nav-learner__item" tabindex="0" [ngClass]="{'active' : activeNavItem?.url === navItem.url }" (click)="redirect(navItem)" >
				<lu-icon class="icon-large main-nav-learner__ico" customClass="main-nav-learner__ico" [icon]="navItem.iconClass"></lu-icon>
				<span class="main-nav-learner__txt">{{ navItem.label | translate }}</span>
			</a>
		</li>
	</ul>
</nav>
