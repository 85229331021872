import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { BaseResponse } from 'lu-services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs/operators';
import { AngieSmartSearchConfig } from 'src/app/angie-shared/components/angie-smart-search/models/angie-smart-search.models';
import { SupportAccessModalComponent } from 'src/app/angie-shared/components/modals/support-access-modal/support-access-modal.component';
import { SupportAccessRoutes } from 'src/app/angie-shared/routes';
import { UserService } from 'src/app/angie-shared/services/user.service';
import {
	CoreRoutes,
	DataService,
	LuUser,
	LuUserRoleCode,
	PortalImage,
	PortalImageType,
	StateService,
	WindowRefService
} from 'src/app/core';
import { DsConfig, PortalMeta } from 'src/app/core/core.models';
import { DocumentRefService } from 'src/app/core/services/document-ref.service';
import { BaseComponent } from '../../../angie-shared/components/base/base.component';
import { SCREEN_MOBILE_BREAK_POINT } from '../../../globals';
import { AngieAppRoutes } from '../../../angie-app.routes';

/**
 * Default Header inside Angie application
 */
@Component({
	selector: 'angie-default-header',
	templateUrl: './default-header.component.html',
	styleUrls: ['./default-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultHeaderComponent extends BaseComponent implements OnInit {
	/**
	 * LuUser object that we read from state service
	 */
	userMenu: LuUser;
	/**
	 * Smart Search Options
	 */
	smartSearchOptions: AngieSmartSearchConfig = {
		apiUrl: CoreRoutes.SMART_SEARCH,
		minimumCharsRequired: 3
	};
	/**
	 * Portal Meta that we read from state service
	 */
	portalMeta: PortalMeta;
	/**
	 * Header Logo
	 */
	headerImage: PortalImage;

	user: LuUser = this.stateService.luUser;
	isLearner = this.user.role_code === LuUserRoleCode.MEMBER;
	isInLearnerView = this.stateService.isInLearnerView();

	isAdmin = this.userService.isAdmin();
	isManager = this.userService.isManager();
	isInstructor = this.userService.isInstructor();
	bulkActionsEnabled = this.stateService?.portalMeta?.bulk_actions_enabled;
	isTaskTracerEnabled: boolean;

	AngieAppRoutes = AngieAppRoutes;

	@Output() overlayState = new EventEmitter<boolean>();

	/**
	 * Constructor
	 */
	constructor(
		public userService: UserService,
		private readonly stateService: StateService,
		private readonly documentRef: DocumentRefService,
		private readonly dataService: DataService,
		private readonly bsModalService: BsModalService,
		private readonly windowRefService: WindowRefService
	) {
		super();
	}

	/**
	 * On DefaultHeaderComponent init
	 */
	ngOnInit(): void {
		const { portalMeta, luUser } = this.stateService;
		this.portalMeta = portalMeta;
		this.userMenu = luUser;
		this.setHeaderImage(this.windowRefService.getWindow().innerWidth);
		/**
		 * Expose portal action tracker to managers and instructors when bulk action FF is enabled on portal
		 */
		this.isTaskTracerEnabled =
			(this.isManager || this.isInstructor) && !this.isInLearnerView && this.bulkActionsEnabled;
	}

	/**
	 * Open smart search method
	 * This method sets isOpen to true and adds body classes that are needed
	 */
	openSmartSearch(): void {
		this.smartSearchOptions.isOpened = true;
		this.addBodyClasses();
		setTimeout(function () {
			(document.querySelector('[focussearch="focused"]') as HTMLElement).focus();
		}, 0);
		this.overlayState.emit(this.smartSearchOptions.isOpened);
	}

	/**
	 * When smart search state (is opened or not) has been changed
	 * We toggle state and add class to body
	 */
	onSmartSearchStateChange(state: boolean): void {
		this.smartSearchOptions.isOpened = state;
		this.addBodyClasses(state);
		this.overlayState.emit(this.smartSearchOptions.isOpened);
	}

	/**
	 * When admin tries to access support desk we check backend if he's permitted or not
	 * Based on that, we either open in new tab if allowed, or trigger modal - SupportAccessModalComponent
	 */
	accessSupportDesk(): void {
		const { email } = this.stateService.luUser;
		const dsConfig: DsConfig = this.dataService.getDefaultConfig(SupportAccessRoutes.CHECK_SUPPORT_ACCESS);
		this.dataService
			.get(dsConfig)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (response: BaseResponse<boolean>) => {
					const { data } = response;
					if (data) {
						this.windowRefService.getWindow().open('/support', '_blank');
						return;
					}
					this.bsModalService.show(SupportAccessModalComponent, {
						initialState: {
							email
						}
					});
				}
			});
	}

	/**
	 * On Window resize - set header Image to logo/squared_logo so we get alt_tag
	 */
	@HostListener('window:resize', ['$event']) onWindowResize(event: Event): void {
		const windowWidth = (event.target as Window).innerWidth;
		this.setHeaderImage(windowWidth);
	}

	/**
	 * Change header image based on browser width so we update alt tag
	 */
	private setHeaderImage(windowWidth: number): void {
		const { portal_images } = this.stateService.portalMeta;
		const imageType = windowWidth > SCREEN_MOBILE_BREAK_POINT ? PortalImageType.LOGO : PortalImageType.LOGO_SQUARED;
		this.headerImage = portal_images.find(image => image.image_type === imageType);
	}

	/**
	 * Add body class toggle based on smart search state (is opened/closed)
	 */
	private addBodyClasses(state: boolean = true): void {
		if (state) {
			this.documentRef.addBodyClass('overlay-visible');
			this.documentRef.addBodyClass('blur-body');
			this.documentRef.addBodyClass('hide-body-scroll');
		} else {
			this.documentRef.removeBodyClass('overlay-visible');
			this.documentRef.removeBodyClass('blur-body');
			this.documentRef.removeBodyClass('hide-body-scroll');
		}
	}
}
