import { trim } from 'lodash-es';

/**
 * Avatar Initials - pass string in format of "Foo Bar" -> "FB"
 */
export const avatarInitials = (value: string): string => {
	if (value === 'N/A' || !trim(value)) return 'N'; // if we return display_name as N/A just use "N" as initials - same as main_app
	const firstName = value.split(' ')[0];
	const lastName = value.split(' ')[1];
	if (!lastName) {
		// N/A for firstName so no LastName -> user logged in through SQSSO
		return (firstName.charAt(0) + firstName.charAt(1)).toUpperCase();
	}
	return (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
};

/**
 * First Two Letters of specific string - "FooBar" -> "FO"
 */
export const firstTwoLetters = (value: string): string => {
	const firstLetter = value.charAt(0).toUpperCase();
	const secondLetter = value.charAt(1).toUpperCase();
	return firstLetter + secondLetter;
};
