import { angieRoutePrefix } from 'src/app/globals';
export enum DashboardRoutesConfig {
	MANAGER_DASHBOARD = 'manager-dashboard',
	LIST_LEARNERS = 'list-learners',
	ENROLLMENT_STATUS = ':enrollment_status'
}
/**
 * Dashboard API routes
 */
export class DashboardRoutes {
	/**
	 * Load Dashboard
	 */
	public static SHOW_DASHBOARD = angieRoutePrefix.concat('/dashboard.json');
	/**
	 * Update Dashboard on widget(dashboard) changes
	 */
	public static UPDATE_DASHBOARD = angieRoutePrefix.concat('/dashboard.json');
	/**
	 * Manager Dashboard page
	 */
	public static MANAGER_DASHBOARD = DashboardRoutesConfig.MANAGER_DASHBOARD;
	/**
	 * Learners page
	 */
	public static LEARNERS = `${DashboardRoutesConfig.LIST_LEARNERS}/${DashboardRoutesConfig.ENROLLMENT_STATUS}`;
}
