import { angieRoutePrefix } from 'src/app/globals';

export enum LearnerResourcesRoutesConfig {
	// Root url
	LEARNER_RESOURCES_BASE_URL = 'learner_resource_list',
	// DATA with all resources
	LEARNER_RESOURCES_DATA = '/learner_resources/list.json',
	LEARNER_RESOURCES_CATEGORIES_DATA = '/learner_resources/categories.json'
}

/**
 * Learner resources route
 * Centralised location for routing paths definitions
 */
export class LearnerResourcesRoutes {
	// URL segment names - used as is, for routing modules
	public static readonly LEARNER_RESOURCES_BASE_URL = LearnerResourcesRoutesConfig.LEARNER_RESOURCES_BASE_URL;

	// Get Resources
	public static GET_RESOURCES_ROUTE = angieRoutePrefix.concat(LearnerResourcesRoutesConfig.LEARNER_RESOURCES_DATA);
	public static LEARNER_RESOURCES_CATEGORIES_DATA = angieRoutePrefix.concat(
		LearnerResourcesRoutesConfig.LEARNER_RESOURCES_CATEGORIES_DATA
	);

	// Send RESOURCE.ID for earning award badge - route
	public static SEND_RESOURCES_ID = (resourcesId: number): string =>
		angieRoutePrefix.concat(`/learner_resources/${resourcesId}/award_badges.json`);
}
