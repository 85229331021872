import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AngieAppRoutes } from 'src/app/angie-app.routes';

@Component({
	selector: 'angie-logo',
	templateUrl: './logo.component.html',
	styleUrls: ['./logo.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoComponent {
	@Input() linkType: 'href' | 'routerLink' = 'href';
	@Input() link = AngieAppRoutes.DASHBOARD;
	@Input() position: 'left' | 'center' | 'right' = 'left';
	@Input() size: 'default' | 'md' | 'lg' = 'default';
	@Input() altTag: string;
	@Input() brandLearner: boolean;
}
