import { NgModule } from '@angular/core';
import { NullStateComponent } from './null-state.component';
import { CommonModule } from '@angular/common';
import { LuIconModule } from 'lu-icon';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';

/**
 * All of the required declarations that we use inside our null state module
 */
const declarations = [NullStateComponent];

/**
 * All of the required imports that we use inside our null state module
 */
const imports = [CommonModule, LuIconModule, FormlyModule, ReactiveFormsModule];

/**
 * Null State Module - feature
 *
 * In charge for handling null states across the app
 */
@NgModule({
	imports,
	declarations,
	exports: [...declarations]
})
export class NullStateModule {}
