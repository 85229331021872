import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { StateService } from 'src/app/core';

/**
 * MetaData - defines page title and additional meta manipulations if needed
 */
export interface MetaData {
	/**
	 * Page Title
	 */
	title: string;
}

@Injectable()
export class MetaService {
	/**
	 * Locally keep one property of metaData so we have it stored here as caching mechanism
	 */
	metaData: MetaData;
	/**
	 * When needed to listen for metadata changes
	 */
	metaData$: BehaviorSubject<MetaData> = new BehaviorSubject<MetaData>(null);

	/**
	 * Constructor
	 */
	constructor(
		private title: Title,
		private stateService: StateService
	) {}

	/**
	 * Set Meta Data
	 *
	 * if there is title - translate it and set is through titleService
	 *
	 * if there is no title - just use title from portal meta
	 */
	setData(metaData: MetaData = this.metaData): void {
		this.metaData = metaData;
		if (!!this.metaData && !!this.metaData.title) {
			this.title.setTitle(
				`${metaData.title} | ${this.stateService?.portalMeta?.title || this.stateService.portalTitle}`
			);
		} else {
			this.title.setTitle(this.stateService.portalMeta.title);
		}
		this.metaData$.next(this.metaData);
	}
}
