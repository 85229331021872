import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { LuUser, LuUserRoleCode, StateService } from 'src/app/core';
import { BaseComponent } from '../../../base/base.component';
import { AngieSmartSearchService } from '../../angie-smart-search.service';
import { LuUserTile, TileConfig } from '../../models/tiles.models';
import { translatePortalMembershipType } from '../../utils/translations.const';

/**
 * User Tile Component - renders results fetched from global smart search for users searches
 */
@Component({
	templateUrl: './user-tile.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserTileComponent extends BaseComponent implements TileConfig<LuUserTile>, OnInit {
	/**
	 * Data that is passed in for each tile
	 */
	data: LuUserTile;
	/**
	 * Is edit mode triggered - flag
	 */
	editMode: boolean;
	/**
	 * Translate portal membership type
	 */
	translatePortalMembershipType = translatePortalMembershipType;
	/**
	 * Current user - read from state
	 */
	user: LuUser = this.stateService.luUser;
	/**
	 * Lu User Role Code enum - used in UI conditionals
	 */
	LuUserRoleCode = LuUserRoleCode;

	/**
	 * Constructor
	 */
	constructor(
		private readonly stateService: StateService,
		private readonly oldSmartSearchService: AngieSmartSearchService
	) {
		super();
	}

	/**
	 * OnInit LifeCycle Hook
	 */
	ngOnInit(): void {
		this.oldSmartSearchService.tileEditMode$.pipe(takeUntil(this.destroy$)).subscribe((id: number) => {
			this.editMode = this.data.id === id;
		});
	}

	/**
	 * Toggle edit mode
	 *
	 * Emit to service only active id, all the others are going to be closed.
	 */
	toggleEditMode(): void {
		this.editMode = !this.editMode;
		this.oldSmartSearchService.tileEditMode$.next(this.editMode ? this.data.id : null);
	}
}
