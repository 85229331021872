import { PortalLicensesRoutesConfig } from './portal-licenses-routes.config';

export class PortalLicensesRoutes {
	public static PORTALS_LICENSES = PortalLicensesRoutesConfig.PORTAL_LICENSES_BASE_URL;
	public static PORTALS = PortalLicensesRoutesConfig.PORTALS_URL_SEGMENT;
	public static LICENSES = PortalLicensesRoutesConfig.LICENSES_URL_SEGMENT;
	public static LICENSES_HOME_ROUTE = `${PortalLicensesRoutes.PORTALS_LICENSES}/${PortalLicensesRoutes.LICENSES}`;
	public static LIST_LICENSES = PortalLicensesRoutesConfig.LICENSES_LIST_SEGMENT;
	public static NEW_LICENCE = PortalLicensesRoutesConfig.LICENSES_NEW_SEGMENT;
	public static EDIT_LICENSE = PortalLicensesRoutesConfig.LICENSES_EDIT_SEGMENT;
	public static LICENSES_BASE_URL = `/${PortalLicensesRoutesConfig.LICENSES_URL_SEGMENT}`;
	public static getEditLicenseRoute(licenseId: number): string {
		return `${PortalLicensesRoutes.LICENSES_HOME_ROUTE}/${licenseId}/${PortalLicensesRoutes.EDIT_LICENSE}`;
	}
}
