import { Component } from '@angular/core';
import { LayoutCssClassesMembers, LayoutStructureMembers } from '../default-layout/models/layout-structures.models';
import { MetaData, MetaService } from 'src/app/angie-shared/services';
import { Observable, delay } from 'rxjs';

/**
 * Public Layout Component
 *
 * This is a lighter version of default-layout, it can be used for public pages such as public catalog
 */
@Component({
	selector: 'angie-public-layout',
	templateUrl: './public-layout.component.html'
})
export class PublicLayoutComponent {
	a11Focus: boolean;
	layoutCss = LayoutCssClassesMembers;
	layoutStructure = LayoutStructureMembers;
	metaData$: Observable<MetaData> = this.metaService.metaData$.asObservable().pipe(delay(0));

	constructor(private metaService: MetaService) {}
}
