import { Component, Input } from '@angular/core';
import { DrawerService } from '../../../core/services/drawer.service';
import { HeaderConfig } from './angie-drawer-header.model';

@Component({
	selector: 'angie-drawer-header',
	templateUrl: 'angie-drawer-header.component.html',
	styleUrls: ['angie-drawer-header.component.scss']
})
export class AngieDrawerHeaderComponent {
	@Input() config: HeaderConfig;
	@Input() alwaysOpen: boolean = false;
	constructor(private drawerService: DrawerService) {}

	closeDrawer(): void {
		this.drawerService.closeAndClearDrawer();
	}
}
