import { Injectable } from '@angular/core';
import { SpinnerService as SharedSpinnerService } from 'lu-services';

/**
 * Spinner Service - inherits SharedSpinnerService (lu-services)
 *
 * it is loaded initially and in charge of our primary spinner (show/hide control)
 */
@Injectable()
export class SpinnerService extends SharedSpinnerService {
	/**
	 * Constructor
	 */
	constructor() {
		super();
	}

	/**
	 * Fake loading for cases where we don't trigger any real BE requests
	 */
	fakeLoading(): void {
		this.show();
		setTimeout(() => {
			this.hide();
		}, 400);
	}
}
