import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
	setData(key: string, data: any): void {
		localStorage.setItem(key, data);
	}

	getData(key: string): string {
		return localStorage.getItem(key);
	}

	removeData(key: string): void {
		localStorage.removeItem(key);
	}
}
