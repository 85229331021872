import { Injectable } from '@angular/core';
import { MatDrawer, MatDrawerContainer, MatDrawerToggleResult } from '@angular/material/sidenav';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { DrawerBaseComponent } from '../../layouts/components/drawer/components/drawer-base/drawer-base.component';
import {
	DrawerConfig,
	DrawerContent,
	DrawerMode,
	DrawerPosition,
	DrawerType
} from '../../layouts/components/drawer/drawer.model';

@Injectable()
export class DrawerService {
	private _drawerRef: MatDrawer;
	private _drawerContainerRef: MatDrawerContainer;
	private _drawerConfig$: BehaviorSubject<DrawerConfig> = new BehaviorSubject<DrawerConfig>(null);
	private _drawerData: any;
	private _drawerContentEmitter$ = new BehaviorSubject<any>(null);
	private _drawerContent: DrawerContent;
	private _ready$ = new BehaviorSubject<boolean>(false);

	get drawerRef(): MatDrawer {
		return this._drawerRef;
	}

	set drawerRef(drawerRef: MatDrawer) {
		this._drawerRef = drawerRef;
		this._ready$.next(true);
	}
	setDrawerData<T>(data: T): void {
		this._drawerData = data;
	}

	getDrawerData<T>(): T {
		return this._drawerData;
	}

	onReady(): Observable<boolean> {
		return this._ready$.pipe(filter(Boolean));
	}

	get drawerEmitter$(): BehaviorSubject<any> {
		return this._drawerContentEmitter$;
	}

	get drawerContainerRef(): MatDrawerContainer {
		return this._drawerContainerRef;
	}

	set drawerContainerRef(drawerContainerRef: MatDrawerContainer) {
		this._drawerContainerRef = drawerContainerRef;
	}

	get drawerConfig$(): BehaviorSubject<DrawerConfig> {
		return this._drawerConfig$;
	}

	set drawerContent(instance: DrawerContent) {
		this._drawerContent = instance;
	}

	get drawerContent(): DrawerContent {
		return this._drawerContent;
	}

	setDrawerConfig(drawerConfig: DrawerConfig): void {
		this._drawerConfig$.next(drawerConfig);
	}

	setType(type: DrawerType): void {
		this.setDrawerConfig({ ...this._drawerConfig$.getValue(), type });
	}

	setMode(mode: DrawerMode): void {
		this.setDrawerConfig({ ...this._drawerConfig$.getValue(), mode });
	}

	setPosition(position: DrawerPosition): void {
		this.setDrawerConfig({ ...this._drawerConfig$.getValue(), position });
	}

	openDrawer<T = DrawerBaseComponent>(): DrawerContent<T> {
		if (!this._drawerConfig$.value) return;
		this._drawerRef.open();
		return this.drawerContent;
	}

	closeDrawer(): Promise<MatDrawerToggleResult> {
		return this._drawerRef.close();
	}

	closeAndClearDrawer(): Promise<void | 'open' | 'close'> {
		return this._drawerRef.close().then(() => {
			this._drawerConfig$.next(null);
			this._drawerData = null;
			this._drawerContentEmitter$.next(null);
			this._drawerContent = null;
		});
	}
}
