import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { isEqual } from 'lodash-es';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/angie-shared/components/base/base.component';
import { LayoutStructureService } from 'src/app/layouts/services/layout-structure.service';
import { DocumentRefService } from 'src/app/core';
import { ILayoutCssClasses, LayoutConfig, LayoutCssClassesMembers } from '../models/layout-structures.models';

@Directive({
	selector: '[angieLayoutCssTrack]'
})
export class LayoutCssTrackDirective extends BaseComponent implements OnInit {
	private propToTrack: LayoutCssClassesMembers;
	private currentClasses: string[] = [];
	// this can be either host element, or body (as a special case)
	private targetElementRef: HTMLElement;

	constructor(
		private readonly hostElement: ElementRef,
		private readonly renderer: Renderer2,
		private readonly layoutStructureService: LayoutStructureService,
		private readonly documentRef: DocumentRefService
	) {
		super();
	}

	@Input() set angieLayoutCssTrack(propToTrack: LayoutCssClassesMembers) {
		this.propToTrack = propToTrack;
		this.setTargetElementRef();
	}

	ngOnInit(): void {
		this.subscribeToLayoutChanges();
	}

	private subscribeToLayoutChanges(): void {
		this.layoutStructureService.currentLayout$.pipe(takeUntil(this.destroy$)).subscribe((layout: LayoutConfig) => {
			this.handleElementCSSChanges(layout.cssClasses);
		});
	}

	private setTargetElementRef(): void {
		if (this.propToTrack === LayoutCssClassesMembers.body) {
			this.targetElementRef = this.documentRef.getBodyRef();
		} else {
			this.targetElementRef = this.hostElement.nativeElement;
		}
	}

	private handleElementCSSChanges(cssConfig: ILayoutCssClasses): void {
		const cssClasses = cssConfig[this.propToTrack];
		// This is the minimal optimisation to prevent unnecesary DOM
		// manipulation. Proper way to handle this would be to calculate
		// diffs between arrays and then only apply classes that have been
		// moved/removed. Temp solution due to time constraints.
		if (!this.classesHaveChanged(cssClasses)) {
			return;
		}

		this.clearCssClasses();
		if (cssClasses) {
			this.addCssClasses(cssClasses);
		}
	}

	private clearCssClasses(): void {
		this.currentClasses.forEach(cls => {
			this.renderer.removeClass(this.targetElementRef, cls);
		});

		this.currentClasses = [];
	}

	private addCssClasses(classes: string[]): void {
		classes.forEach(cls => {
			this.renderer.addClass(this.targetElementRef, cls);
		});

		this.currentClasses = classes;
	}

	private classesHaveChanged(newClasses = []): boolean {
		return !isEqual(newClasses, this.currentClasses);
	}
}
