import { LuUserRoleCode } from 'src/app/core';

/**
 * Translate portal membership type for global search
 */
export const translatePortalMembershipType = (typeId: number): string => {
	switch (typeId) {
		case LuUserRoleCode.MEMBER:
			return 'group_memberships.learner';
		case LuUserRoleCode.ADMIN:
			return 'group_memberships.admin';
		case LuUserRoleCode.INSTRUCTOR:
			return 'group_memberships.tutor';
		case LuUserRoleCode.MANAGER:
			return 'group_memberships.manager';
		default:
			return '';
	}
};
