import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { InputType, LuFormlyInputBuilder } from 'lu-formly';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { debounceTime, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DataService, DsConfig, StateService } from 'src/app/core';
import { ListResponse, LuUserRoleCode } from 'src/app/core/core.models';
import { KEY_ESCAPE, TYPE_AHEAD_DEBOUNCE_TIME } from 'src/app/globals';
import { AngieAppRoutes } from '../../../angie-app.routes';
import { BaseComponent } from '../base/base.component';
import {
	AngieSmartSearchConfig,
	AngieSmartSearchGridTile,
	NotFoundKeys,
	PlaceHolderKey,
	SearchOptions
} from './models/angie-smart-search.models';
import { filterSearchTypes } from './search-types/search-types-permissions.const';
import { searchTypes } from './search-types/search-types.const';

/**
 * Angie Smart Search Component - in charge for having global search inside our app layout
 */

@Component({
	selector: 'angie-smart-search',
	templateUrl: './angie-smart-search.component.html',
	styleUrls: ['./angie-smart-search.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AngieSmartSearchComponent extends BaseComponent implements OnInit {
	/**
	 * Basic Options regarding smart-search
	 */
	@Input() options: AngieSmartSearchConfig;
	/**
	 * State Change - Open/Closed
	 */
	@Output() stateChange: EventEmitter<boolean> = new EventEmitter<boolean>();
	/**
	 * Track search inside input and do API calls when condition is fulfilled
	 */
	search$: BehaviorSubject<string> = new BehaviorSubject<string>('');
	/**
	 * isLoading flag for UI conditionals when doing search
	 */
	isLoading: boolean;
	/**
	 * searchBy - map searches based on global search choice (courses/users/groups...)
	 */
	searchBy: SearchOptions = SearchOptions.COURSES;
	/**
	 * Search Options enum - conditionals in UI
	 */
	SearchOptions = SearchOptions;
	/**
	 * Placeholder keys based on chosen search option
	 */
	PlaceHolderKey = PlaceHolderKey;
	/**
	 * If no results, keys are used based on chosen search option
	 */
	NotFoundKeys = NotFoundKeys;
	/**
	 * Results when we fetch data from API - we'll keep these inside this list
	 */
	results: ListResponse<any>;
	/**
	 * Show search types based on conditionals (role and portal_meta)
	 */
	searchTypes = filterSearchTypes(searchTypes, this.stateService.portalMeta, this.stateService.luUser.role_code);
	/**
	 * Smart search Grid Tiles - all of the search options matched
	 */
	AngieSmartSearchGridTile = AngieSmartSearchGridTile;
	/**
	 * DsConfig params
	 */
	dsConfigParams: any;
	/**
	 * Aria hidden toggle
	 */
	ariaHiddenToggle = true;
	/**
	 * Aria live toggle "off"
	 */
	ariaLiveToggle = 'off';
	/**
	 * We need to cancel timeout if already scheduled so we track it here
	 */
	a11yAssertiveTimeout;

	/**
	 * Formly search
	 */
	searchForm = new UntypedFormGroup({});
	searchModel = { headerSearch: '' };
	searchField: FormlyFieldConfig[];

	/**
	 * Constructor
	 */
	constructor(
		private readonly dataService: DataService,
		private readonly stateService: StateService,
		private readonly changeDetectorRef: ChangeDetectorRef,
		private readonly router: Router,
		private readonly translateService: TranslateService
	) {
		super();
	}

	/**
	 * OnInit LifeCycle Hook
	 */
	ngOnInit(): void {
		this.buildForm();
		this._setDefaultSearchOption();
		this.observeRouteChanges();
		this.dsConfigParams = this._getDefaultDsConfigParams();
		if (!this.options.minimumCharsRequired) {
			this.options.minimumCharsRequired = 3;
		}
		this.initInputChange();
	}

	/**
	 * Handle keydown events inside component
	 * - on ESC - close dialog
	 */
	@HostListener('keydown', ['$event']) handleKeyupEvent(event: KeyboardEvent): void {
		switch (event.key) {
			case KEY_ESCAPE:
				this.closeDialog();
				break;
		}
	}

	/**
	 * Get placeholder key
	 */
	placeholderKey(): string {
		if (this.stateService.luUser.role_code !== LuUserRoleCode.MEMBER && this.searchBy === SearchOptions.COURSES) {
			return 'lup.licenses.search_for_courses';
		}

		return PlaceHolderKey[this.searchBy];
	}

	buildForm(): void {
		const searchField = new LuFormlyInputBuilder(
			'headerSearch',
			this.translateService.instant(this.placeholderKey()),
			this.translateService.instant(this.placeholderKey()),
			null
		)
			.setInputType(InputType.SEARCH)
			.hideWrapperLabel(true)
			.setAttributes({ class: 'smart-search search', autocomplete: 'off', focussearch: 'focused' })
			.build();

		searchField.templateOptions.keyup = e => {
			this.search$.next(e.model.headerSearch);
		};
		this.searchField = [searchField];
	}

	/**
	 * On Input change send API requests
	 */
	initInputChange(): void {
		this.search$
			.pipe(
				takeUntil(this.destroy$),
				tap((search: string) => {
					if (!this.options.isOpened && !!search.length) {
						this.options.isOpened = true;
						this.stateChange.next(true);
					}
					if (search.length < this.options.minimumCharsRequired && this.results) {
						this.results.data = [];
					}
				}),
				debounceTime(TYPE_AHEAD_DEBOUNCE_TIME),
				switchMap((search: string) => {
					if (search.length >= this.options.minimumCharsRequired) {
						this.dsConfigParams.search = search;
						this.dsConfigParams.page = 1;
						return this.dataService.getList(this.config());
					}
					if (this.results) {
						this.results.data = [];
					}
					if (search.length) {
						this.triggerA11YAssertive();
					}
					return EMPTY;
				})
			)
			.subscribe((response: any) => {
				this.isLoading = false;
				this.results = response;
				this.triggerA11YAssertive();
				this.changeDetectorRef.detectChanges();
			});
	}

	/**
	 * Set search tile type
	 */
	searchTileType(): any {
		return AngieSmartSearchGridTile[this.searchBy];
	}

	/**
	 * Change Search type options
	 */
	setSearchByOption(value: SearchOptions): void {
		this.searchBy = value;
		this.searchField[0].templateOptions.placeholder = this.translateService.instant(this.placeholderKey());
		this.options.isOpened = true;
		this.stateChange.next(true);
		if (this.results && this.results.data) {
			this.results.data = [];
		}
		this.search$.next(this.searchModel.headerSearch);
		this.dsConfigParams.search_by = value;
	}

	/**
	 * Get search config
	 */
	private config(loading: boolean = true): DsConfig {
		const dsConfig: DsConfig = this.dataService.getDefaultConfig(this.options.apiUrl, this.dsConfigParams);
		dsConfig.primarySpinnerConfig.show = false;
		this.isLoading = loading;
		return dsConfig;
	}

	/**
	 * Infinite scroll - load more
	 */
	loadMore(): void {
		// has next needs to be added here once BE is fixed
		this.dsConfigParams.page++;
		this.dataService
			.getList(this.config(false))
			.pipe(takeUntil(this.destroy$))
			.subscribe((response: any) => {
				this.results.has_next = response.has_next;
				this.results.data = [...this.results.data, ...response.data];
				this.changeDetectorRef.detectChanges();
			});
	}

	/**
	 * Close Dialog on ESC
	 */
	closeDialog(): void {
		this.options.isOpened = false;
		this.stateChange.next(false);
	}

	/**
	 * Get Default DsConfig params
	 */
	private _getDefaultDsConfigParams(): any {
		return {
			page: 1,
			search: '',
			search_by: this.searchBy,
			is_student_search: this.stateService.luUser.role_code === LuUserRoleCode.MEMBER
		};
	}

	/**
	 * Set Default Search options
	 */
	private _setDefaultSearchOption(): void {
		const url = this.router.url;
		if (url.indexOf(`/${AngieAppRoutes.USERS.USERS}`) === 0) {
			this.searchBy = SearchOptions.USERS;
		} else if (url.indexOf(`/${AngieAppRoutes.GROUPS}`) === 0) {
			this.searchBy = SearchOptions.GROUPS;
		} else {
			this.searchBy = SearchOptions.COURSES;
		}
		this.searchField[0].templateOptions.placeholder = this.translateService.instant(this.placeholderKey());
		this.changeDetectorRef.markForCheck();
	}

	/**
	 * Listen for route changes and triggers setDefaultSearchOption
	 */
	private observeRouteChanges(): void {
		this.router.events
			.pipe(
				takeUntil(this.destroy$),
				filter(event => event instanceof NavigationEnd),
				tap(() => this._setDefaultSearchOption())
			)
			.subscribe();
	}

	/**
	 * Trigger a11y assertive aria-label
	 */
	private triggerA11YAssertive(): void {
		clearTimeout(this.a11yAssertiveTimeout);
		this.a11yAssertiveTimeout = setTimeout(() => {
			this.ariaHiddenToggle = false;
			this.ariaLiveToggle = 'assertive';
			this.changeDetectorRef.detectChanges();
		}, 2000);
	}

	/**
	 * Get item's display prop that we need to attach to aria-label
	 */
	tileProperty(searchBy: SearchOptions, item: any): string {
		switch (searchBy) {
			case SearchOptions.GROUPS:
			case SearchOptions.RESOURCES:
				return item.title;
			case SearchOptions.USERS:
				return item.display_name;
			default:
				return item.name;
		}
	}
}
