import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/angie-shared/components/base/base.component';
import { LayoutStructureService } from 'src/app/layouts/services/layout-structure.service';
import { ILayoutStructure, LayoutConfig, LayoutStructureMembers } from '../models/layout-structures.models';

@Directive({
	selector: '[angieLayoutToggle]'
})
export class LayoutToggleDirective extends BaseComponent implements OnInit {
	private propToTrack: LayoutStructureMembers;
	private containerRef;

	constructor(
		private readonly templateRef: TemplateRef<any>,
		private readonly viewContainer: ViewContainerRef,
		private readonly layoutStructureService: LayoutStructureService
	) {
		super();
	}

	@Input() set angieLayoutToggle(propToTrack: LayoutStructureMembers) {
		this.propToTrack = propToTrack;
	}

	ngOnInit(): void {
		this.subscribeToLayoutChanges();
	}

	private subscribeToLayoutChanges(): void {
		this.layoutStructureService.currentLayout$.pipe(takeUntil(this.destroy$)).subscribe((layout: LayoutConfig) => {
			this.handleLayoutChanges(layout.structure);
		});
	}

	private handleLayoutChanges(layoutStructure: ILayoutStructure): void {
		if (layoutStructure[this.propToTrack]) {
			if (!this.containerRef) {
				this.containerRef = this.viewContainer.createEmbeddedView(this.templateRef);
			}
		} else {
			this.viewContainer.clear();
			this.containerRef = null;
		}
	}
}
