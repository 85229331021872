import { Component } from '@angular/core';
import { ProxyUserModalComponent } from '../../../../angie-shared/components/modals/proxy-user-modal/proxy-user-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StateService, WindowRefService } from '../../../../core';
import { ProxyBannerService } from '../../../services/proxy-banner.service';
import { AngieAppRoutes } from '../../../../angie-app.routes';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../../angie-shared/components/base/base.component';

@Component({
	selector: 'angie-proxy-banner',
	templateUrl: './proxy-banner.component.html',
	styleUrls: ['./proxy-banner.component.scss']
})
export class ProxyBannerComponent extends BaseComponent {
	proxyUserModal: BsModalRef;

	constructor(
		private stateService: StateService,
		private translateService: TranslateService,
		private bsModalService: BsModalService,
		private windowRefService: WindowRefService,
		private proxyBannerService: ProxyBannerService
	) {
		super();
	}

	handleBannerLinkClick(): void {
		this.stateService.portalMeta?.proxy_user_notification_enabled ? this.openProxyModal() : this.handleRecordAction();
	}

	openProxyModal(): void {
		const config = {
			initialState: {
				modalOptions: {
					title: this.translateService.instant('lup.proxy_user.impersonation_resolution_message.modal_title'),
					primaryActions: [
						{
							label: this.translateService.instant('lup.proxy_user.impersonation_resolution_message.skip'),
							class: 'btn btn--default',
							onClick: () => this.handleRecordAction()
						},
						{
							label: this.translateService.instant('lup.proxy_user.impersonation_resolution_message.record'),
							class: 'btn btn--theme',
							onClick: () => this.handleRecordAction(this.proxyUserModal.content?.formModel?.proxy_message)
						}
					]
				}
			},
			modalClass: 'modal-cnt',
			backdrop: 'static' as 'static'
		};

		this.proxyUserModal = this.bsModalService.show(ProxyUserModalComponent, config);
	}

	private handleRecordAction(proxyMessage = ''): void {
		this.proxyBannerService
			.recordAction(proxyMessage)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.goToSignIn();
				this.proxyUserModal.hide();
			});
	}

	goToSignIn(): void {
		this.windowRefService.goTo(`${AngieAppRoutes.AUTH}/sign_in`);
	}
}
