import { Component, OnInit, ViewContainerRef, ViewChild, ComponentFactoryResolver, Input } from '@angular/core';
import { AngieSmartSearchGridTile, angieSmartSearchGridTileComponents } from './models/angie-smart-search.models';
import { TileConfig } from './models/tiles.models';

/**
 * Smart Search Tile Wrapper
 *
 * In charge for dynamic rendering of tile component based on SearchOptions
 */

@Component({
	selector: 'angie-smart-search-tile-wrapper',
	templateUrl: './angie-smart-search-tile-wrapper.component.html'
})
export class AngieSmartSearchTileWrapperComponent implements OnInit {
	/**
	 * Smart Search Tile which we're going to render
	 */
	@Input() view: AngieSmartSearchGridTile;
	/**
	 * Specific row data which is going to get passed to tile
	 */
	@Input() data: any;
	/**
	 * ng-template reference as placeholder for dynamic rendering
	 */
	@ViewChild('rowComponent', { read: ViewContainerRef, static: true }) containerRef: ViewContainerRef;

	/**
	 * Constructor
	 */
	constructor(private readonly componentFactoryResolver: ComponentFactoryResolver) {}

	/**
	 * OnInit LifeCycle Hook
	 *
	 * dynamic rendering of tile components
	 */
	ngOnInit(): void {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
			angieSmartSearchGridTileComponents[this.view]
		);
		const row = this.containerRef.createComponent(componentFactory);
		// as any for now until i figure out to add types to it
		(row.instance as TileConfig<any>).data = this.data;
	}
}
