<div class="drawer-header" [ngClass]="config?.className">
	<div class="o-flex o-flex--vcenter">
		<a *ngIf="config?.link"
		   class="drawer-header__link-divider o-flex o-flex--vcenter mr-12 pr-16"
		   [routerLink]="config.link.routerLink"
		   (click)="config.link.callback && config.link.callback()">
			<lu-icon [icon]="config.link.icon || 'lu-chevron-carret-arrow-left-large'" class="mr-08" [ngClass]="config.link.iconClassName"></lu-icon>{{config.link?.text}}
		</a>
		<lu-icon *ngIf="config?.icon" [icon]="config.icon" class="mr-12" [ngClass]="config.iconClassName"></lu-icon>
		<p class="h-s">{{config?.title}}</p>
	</div>
	<button *ngIf="!alwaysOpen" (click)="closeDrawer()" class="btn btn--icon btn--ghost">
		<lu-icon icon="lu-close"></lu-icon>
	</button>
</div>
