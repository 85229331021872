export enum TaskTrackingRoutesConfig {
	//Root url
	TASK_TRACKING_BASE_URL = 'task-tracking'
}

/**
 * Task tracking routes
 * Centralised location for routing paths definitions
 */
export class TaskTrackingRoutes {
	// URL segment names - used as is, for routing modules
	public static readonly TASK_TRACKING_BASE_URL = TaskTrackingRoutesConfig.TASK_TRACKING_BASE_URL;

	// Used in actual components and html for routing
	public static readonly TASK_TRACKING__BASE_URL_HOME_ROUTE = '/' + TaskTrackingRoutes.TASK_TRACKING_BASE_URL;
}
