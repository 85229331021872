<mat-drawer-container #drawerContainer>
	<mat-drawer #drawer angieDrawerLayoutCssTrack luScrollWatch mode="over" class="drawer" 
	[ngClass]="{'drawer--default': config?.size === DrawerSize.DEFAULT, 'drawer--large': config?.size === DrawerSize.LARGE, 'drawer--shadow': config?.type !== DrawerType.ALWAYS_OPEN, 'drawer--border': config?.type === DrawerType.ALWAYS_OPEN}" 
	position="end" disableClose>
		<angie-drawer-header *ngIf="!config?.hideDefaultHeader" [config]="config?.header" [alwaysOpen]="config?.type === DrawerType.ALWAYS_OPEN"></angie-drawer-header>
		<ng-template #drawerContent></ng-template>
		<div *ngIf="config?.actionBar" class="drawer__footer">
			<angie-action-bar [config]="config.actionBar"></angie-action-bar>
		</div>
	</mat-drawer>
	<ng-content></ng-content>
</mat-drawer-container>