import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { StateService } from 'src/app/core';
import { CoursePublishedStatus, LuUser, LuUserRoleCode } from 'src/app/core/core.models';
import { BaseComponent } from '../../../base/base.component';
import { AngieSmartSearchService } from '../../angie-smart-search.service';
import { LuCoursesTile, TileConfig } from '../../models/tiles.models';

/**
 * Courses Tile Component - renders results fetched from global smart search for courses searches
 */
@Component({
	templateUrl: './courses-tile.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoursesTileComponent extends BaseComponent implements TileConfig<LuCoursesTile>, OnInit {
	/**
	 * Data required for this specific row
	 */
	data: LuCoursesTile;
	/**
	 * Edit mode check
	 */
	editMode: boolean;
	/**
	 * Current logged in user
	 */
	user: LuUser = this.stateService.luUser;
	/**
	 * User role enum so we can do some conditionals in UI
	 */
	LuUserRoleCode = LuUserRoleCode;
	/**
	 * Course Published Status enum so we can do some conditionals in UI
	 */
	CoursePublishedStatus = CoursePublishedStatus;

	/**
	 * Constructor
	 */
	constructor(
		private readonly stateService: StateService,
		private readonly oldSmartSearchService: AngieSmartSearchService
	) {
		super();
	}

	/**
	 * OnInit LifeCycle Hook
	 *
	 * subscribes to service tile Edit mode and checks if edit mode for this specific tile should be enabled
	 */
	ngOnInit(): void {
		this.oldSmartSearchService.tileEditMode$.pipe(takeUntil(this.destroy$)).subscribe((id: number) => {
			this.editMode = this.data.id === id;
		});
	}

	/**
	 * On edit mode toggle
	 */
	toggleEditMode(): void {
		this.editMode = !this.editMode;
		this.oldSmartSearchService.tileEditMode$.next(this.editMode ? this.data.id : null);
	}
}
