import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

/**
 * MultiCheckbox Component for formly
 *
 * We keep it on our side temp until ngx-formly switches to ivy
 *
 * https://github.com/ngx-formly/ngx-formly/issues/2532#issuecomment-832734418
 */
@Component({
	templateUrl: './multicheckbox.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MulticheckboxComponent extends FieldType implements OnInit {
	defaultOptions = {
		templateOptions: {
			options: [],
			formCheck: 'default' // 'default' | 'inline' | 'switch' | 'inline-switch'
		}
	};

	constructor(private cd: ChangeDetectorRef) {
		super();
	}

	ngOnInit(): void {
		// Listen for angular form changes (eg. grid filters reset)
		// and running CD to match real form state
		this.field.formControl.valueChanges.subscribe(data => {
			if (data == null) {
				this.cd.markForCheck();
			}
		});
	}

	onChange(value: any, checked: boolean): void {
		if (this.to.type === 'array') {
			this.formControl.patchValue(
				checked
					? [...(this.formControl.value || []), value]
					: [...(this.formControl.value || [])].filter(o => o !== value)
			);
		} else {
			this.formControl.markAsDirty();
			this.formControl.patchValue({ ...this.formControl.value, [value]: checked });
		}
	}

	isChecked(option: any): boolean {
		const value = this.formControl.value;

		return value && (this.to.type === 'array' ? value.indexOf(option.value) !== -1 : value[option.value]);
	}
}
