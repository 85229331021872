import { AfterViewInit, Component, Injector, NgModuleRef, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatDrawerContainer } from '@angular/material/sidenav';
import { NavigationStart, Router } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../angie-shared/components/base/base.component';

import { DrawerService } from '../../../core/services/drawer.service';
import { setDefaultDrawerValues } from './drawer.helper';
import { DrawerConfig, DrawerContent, DrawerSize, DrawerType } from './drawer.model';

@Component({
	selector: 'angie-drawer',
	templateUrl: './drawer.component.html',
	styleUrls: ['drawer.component.scss']
})
export class DrawerComponent extends BaseComponent implements AfterViewInit {
	@ViewChild('drawer', { static: true }) drawer!: MatDrawer;
	@ViewChild('drawerContainer', { static: true }) drawerContainer!: MatDrawerContainer;
	@ViewChild('drawerContent', { read: ViewContainerRef }) drawerContent: ViewContainerRef;

	config: DrawerConfig;
	DrawerSize = DrawerSize;
	DrawerType = DrawerType;

	constructor(
		private drawerService: DrawerService,
		private router: Router,
		private injector: Injector,
		private ngModuleRef: NgModuleRef<any>
	) {
		super();
	}

	ngAfterViewInit(): void {
		this.drawerService.drawerRef = this.drawer;
		this.drawerService.drawerContainerRef = this.drawerContainer;
		this.listenConfigChanges();
		this.listenRouteChanges();
	}

	listenConfigChanges(): void {
		this.drawerService.drawerConfig$
			.pipe(
				takeUntil(this.destroy$),
				filter(Boolean),
				map((config: DrawerConfig) => setDefaultDrawerValues(config))
			)
			.subscribe(config => {
				this.config = config;
				if (config.content)
					this.buildCustomComponent<typeof config.content>(config.content, config.data, config.ngModuleRef);
			});
	}

	listenRouteChanges(): void {
		this.router.events
			.pipe(
				takeUntil(this.destroy$),
				filter(e => e instanceof NavigationStart && this.drawerService.drawerRef?.opened)
			)
			.subscribe(() => {
				this.drawerService.closeAndClearDrawer();
			});
	}

	/**
	 * Function which builds custom component for drawer content
	 *
	 * @param contentComponent ref to the component
	 * @param data data to bind to the new component
	 * @param [ngModuleRef] optional ref to the module in case some of dependencies are not available in the root module
	 */
	buildCustomComponent<T>(
		contentComponent: Type<DrawerContent<T>>,
		data: any,
		ngModuleRef: NgModuleRef<any> = this.ngModuleRef
	): void {
		this.drawerContent.clear();
		const componentRef = this.drawerContent.createComponent(contentComponent, {
			injector: this.injector,
			ngModuleRef: ngModuleRef
		});
		// Saving component ref in the service to be accessible from anywhere
		const componentInstance = componentRef.instance;
		componentInstance.data = data;
		componentInstance.emitter$.pipe(takeUntil(this.destroy$)).subscribe(val => {
			this.drawerService.drawerEmitter$.next(val);
		});
		this.setAdditionalConfig();

		componentRef.changeDetectorRef.detectChanges();
		this.drawerService.drawerContent = componentInstance;
	}

	private setAdditionalConfig(): void {
		this.drawerService.drawerContainerRef.hasBackdrop = this.config.type !== DrawerType.ALWAYS_OPEN;
		this.drawerService.drawerRef.mode = this.config.mode;
		this.drawerService.drawerRef.position = this.config.position;
	}
}
