import { ModuleWithProviders, NgModule } from '@angular/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MODAL_CONFIG_DEFAULT_OVERRIDE, ModalModule } from 'ngx-bootstrap/modal';
import { TooltipConfig, TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { ModalConfig } from './modal.config';

// these are core, needed for layout usage so we load them on every app loader
// Dropdown in Header / Modal for Language Change / Toaster and Tooltip

function luTooltipConfig(): TooltipConfig {
	return Object.assign(new TooltipConfig(), {
		placement: 'bottom',
		triggers: 'hover'
	});
}
@NgModule({
	imports: [
		BsDropdownModule.forRoot(),
		ModalModule.forRoot(),
		ToastNoAnimationModule.forRoot({
			enableHtml: true
		}),
		TooltipModule.forRoot()
	],
	exports: [BsDropdownModule, ModalModule, ToastNoAnimationModule, TooltipModule]
})
export class NgxBootstrapModule {
	static forRoot(): ModuleWithProviders<NgxBootstrapModule> {
		return {
			ngModule: NgxBootstrapModule,
			providers: [
				{
					provide: TooltipConfig,
					useFactory: luTooltipConfig
				},
				{
					provide: MODAL_CONFIG_DEFAULT_OVERRIDE,
					useValue: ModalConfig
				}
			]
		};
	}
}
