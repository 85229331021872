import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { StateService } from '../../../core';
import { AngieAppRoutes } from '../../../angie-app.routes';
import { UserService } from 'src/app/angie-shared/services/user.service';

export const certRedesignGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const stateService = inject(StateService);
	const router = inject(Router);

	const isCertRedesignEnabled = stateService.portalMeta.certificate_editor_redesign;
	if (!isCertRedesignEnabled) {
		router.navigate([AngieAppRoutes.FORBIDDEN]);
	}

	return isCertRedesignEnabled;
};

export const certEditorAccessGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const userService = inject(UserService);
	const router = inject(Router);

	const canAccess = userService.isAdmin();
	if (!canAccess) {
		router.navigate([AngieAppRoutes.FORBIDDEN]);
	}

	return canAccess;
};
