import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { MulticheckboxComponent } from './multicheckbox.component';

@NgModule({
	declarations: [MulticheckboxComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,

		FormlySelectModule,
		FormlyModule.forChild({
			types: [
				{
					name: 'multicheckbox',
					component: MulticheckboxComponent,
					wrappers: ['form-field']
				}
			]
		})
	]
})
export class FormlyMultiCheckboxModule {}
