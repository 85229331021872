import { Directive, OnDestroy } from '@angular/core';
import { LuIconType } from 'lu-icon';
import { Subject } from 'rxjs';
import { AngieAppRoutes } from 'src/app/angie-app.routes';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class BaseComponent implements OnDestroy {
	/**
	 * Lu Icon Type enum value (use to pass value to HTML for lu-icons)
	 */
	public readonly LuIconType = LuIconType;
	public readonly AngieAppRoutes = AngieAppRoutes;
	destroy$: Subject<void> = new Subject<void>();

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
