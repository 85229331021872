import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StateService } from 'src/app/core';
import { BaseComponent } from '../../../angie-shared/components/base/base.component';

/**
 * Sub Header
 *
 * in charge for handling subnavigation inside our default layout
 *
 * It is contained of primary, middle and action navigation items
 */

@Component({
	selector: 'angie-sub-header',
	templateUrl: './sub-header.component.html',
	styleUrls: ['./sub-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubHeaderComponent extends BaseComponent {
	/**
	 * Constructor
	 */
	constructor(private readonly stateService: StateService) {
		super();
	}
}
