import { ConfigOption } from '@ngx-formly/core';
import { FormlyFieldConfig } from '@ngx-formly/core/lib/models/fieldconfig';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';

/**
 * Extends formly base service through injection token
 */
export function registerTranslateExtension(translate: TranslateService): ConfigOption {
	return {
		validationMessages: [
			{
				name: 'required',
				message(): Observable<string> {
					return translate.stream('jqueryvalidation.this_field_is_required');
				}
			},
			{
				name: 'email',
				message(): Observable<string> {
					return translate.stream('jqueryvalidation.email');
				}
			},
			{
				name: 'invalidDate',
				message(): Observable<string> {
					return translate.stream('jqueryvalidation.date');
				}
			},
			{
				name: 'minLength',
				message(error: any, field: FormlyFieldConfig): Observable<string> {
					return translate.stream('jqueryvalidation.minlength', { 0: field.templateOptions.minLength });
				}
			},
			{
				name: 'maxLength',
				message(error: any, field: FormlyFieldConfig): Observable<string> {
					return translate.stream('jqueryvalidation.maxlength', { 0: field.templateOptions.maxLength });
				}
			},
			{
				name: 'min',
				message(error: any, field: FormlyFieldConfig): Observable<string> {
					return translate.stream('jqueryvalidation.min', { 0: field.templateOptions.min });
				}
			},
			{
				name: 'max',
				message(error: any, field: FormlyFieldConfig): Observable<string> {
					return translate.stream('jqueryvalidation.max', { 0: field.templateOptions.max });
				}
			},
			{
				name: 'invalidRange',
				message(): Observable<string> {
					return translate.stream('learningpaths.invalid_due_date_format');
				}
			},
			{
				name: 'emojiNotAllowed',
				message(): Observable<string> {
					return translate.stream('validators.emoji');
				}
			}
		]
	};
}
