import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LuModalModule } from 'lu-modal';
import { NullStateModule } from '../../null-state/null-state.module';
import { ResourcesDescriptionModalComponent } from './resources-description-modal.component';

/**
 * All of the required declarations that we use inside our resources description modal module
 */
const declarations = [ResourcesDescriptionModalComponent];

/**
 * All of the required imports that we use inside our resources description modal module
 */
const imports = [CommonModule, LuModalModule, NullStateModule];

/**
 * Resource Description Modal Module - feature module
 *
 * Once user uses global search for resources, it can preview resource - we open this modal
 */
@NgModule({
	declarations,
	imports
})
export class ResourcesDescriptionModalModule {}
