import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ScrollWatchModule } from 'lu-scroll-watch-directive';
import { scrollService as ScrollWatchScrollServiceToken } from 'lu-scroll-watch-directive';
import { AngieDrawerHeaderModule } from '../../../angie-shared/components/angie-drawer-header/angie-drawer-header.module';
import { AngieActionBarModule } from '../../../angie-shared/components/angie-form-section/angie-action-bar/angie-action-bar.module';
import { FormlySharedModule } from '../../../angie-shared/modules/formly-shared.module';
import { ScrollService } from '../../../core';
import { DrawerService } from '../../../core/services/drawer.service';
import { DrawerLayoutCssDirective } from '../../default-layout/directives/drawer-css.directive';
import { DrawerBaseComponent } from './components/drawer-base/drawer-base.component';
import { DrawerComponent } from './drawer.component';

const imports = [
	MatSidenavModule,
	AngieActionBarModule,
	ScrollWatchModule.forRoot({
		scrollService: {
			provide: ScrollWatchScrollServiceToken,
			useExisting: ScrollService
		}
	}),
	CommonModule,
	FormlySharedModule,
	AngieDrawerHeaderModule
];
const exports = [DrawerComponent];
const declarations = [DrawerComponent, DrawerLayoutCssDirective, DrawerBaseComponent];
const providers = [DrawerService];
@NgModule({
	imports,
	exports,
	declarations,
	providers
})
export class DrawerModule {}
