import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../base/base.component';
import { AngieSmartSearchService } from '../../angie-smart-search.service';
import { LuGroupsTile, TileConfig } from '../../models/tiles.models';

/**
 * Groups Tile Component - renders results fetched from global smart search for groups searches
 */

@Component({
	templateUrl: './groups-tile.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupsTileComponent extends BaseComponent implements TileConfig<LuGroupsTile>, OnInit {
	/**
	 * Data that is passed in for each tile
	 */
	data: LuGroupsTile;
	/**
	 * Edit Mode flag
	 */
	editMode: boolean;

	/**
	 * Constructor
	 */
	constructor(private readonly oldSmartSearchService: AngieSmartSearchService) {
		super();
	}

	/**
	 * OnInit LifeCycle Hook
	 */
	ngOnInit(): void {
		this.oldSmartSearchService.tileEditMode$.pipe(takeUntil(this.destroy$)).subscribe((id: number) => {
			this.editMode = this.data.id === id;
		});
	}

	/**
	 * Toggle edit mode
	 *
	 * Emit to service only active id, all the others are going to be closed.
	 */
	toggleEditMode(): void {
		this.editMode = !this.editMode;
		this.oldSmartSearchService.tileEditMode$.next(this.editMode ? this.data.id : null);
	}
}
