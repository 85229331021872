<div class="dropdown dropdown-lg" dropdown (onShown)="loadInbox()" #dropdown="bs-dropdown" [cdkTrapFocus]="dropdown.isOpen" angieCloseOnEscapeKey>
	<button
		type="button"
		class="header-btn def-focus mmbtn"
		[tooltip]="'lup.tips.header.messages' | translate"
		[attr.aria-label]="'lup.tips.header.messages' | translate"
		[attr.aria-expanded]="dropdown.isOpen"
		[attr.aria-haspopup]="dropdown.isOpen"
		[tooltipAnimation]="false"
		dropdownToggle>
		<lu-icon class="icon-large" customClass="header-color-icon" icon="lu-messages"></lu-icon>
		<div class="notification border50" *ngIf="unreadMessages > 0">{{ unreadMessages > 99 ? '99+' : unreadMessages }}</div>
	</button>
	<ul role="menu" class="dropdown-menu dropdown-menu-right header-dropdown header-dropdown--msg-switch ss-ddm ss-ddm--messages" *dropdownMenu (click)="$event.stopPropagation()">
		<div class="ss-ddm__loader" *ngIf="loading">
			<header class="ss-ddm__header">
				<div class="ss-ddm__hdg">
					<div class="loader-placeholder loader-text is-18"></div>
				</div>
				<div class="ss-ddm__act">
					<div class="loader-placeholder loader-text is-32 pull-right"></div>
				</div>
			</header>
			<ul class="list-unstyled smart-list no-top">
				<li class="ss-ddm__item ss-ddm--msg-switch" *ngFor="let i of [1, 2, 3, 4, 5]">
					<div class="o-flex0">
						<div class="loader-placeholder avatar"></div>
					</div>
					<div class="o-flex1">
						<div class="loader-placeholder loader-text is-12"></div>
					</div>
				</li>
			</ul>
			<footer class="ss-ddm__ft">
				<span class="loader-placeholder loader-text is-inline is-12"></span>
			</footer>
		</div>
		<ng-container *ngIf="messages && !loading">
			<header class="ddm__header">
				<div class="ss-ddm__header">
					<div class="ss-ddm__hdg o-flex o-flex--space-between">
						<h3>{{ 'lup.breadcrumbs.messages.index' | translate }}</h3>
						<div class="responsive-dropdown-close hdn-from-sm">
							<button (click)="dropdown.hide()" class="btn btn--ico btn--ghost" [title]="'close' | translate">
								<lu-icon [type]="LuIconType.SOLID" icon="times"></lu-icon>
							</button>
						</div>
					</div>
					<div class="ss-ddm__act">
						<a class="btn btn--theme btn-xs-block pull-right mt-xs-12 def-focus" href="/messages/new">
							{{ 'lup.messages.create_new' | translate }}
						</a>
					</div>
				</div>
			</header>
			<div class="blank-state md" *ngIf="!messages.length">
				<img class="blank-state-img" [src]="cdnPath + '/assets/images/blank-state/no-messages.svg'" [alt]="'lup.messages.you_have_no_messages' | translate">
				<h4 class="blank-state-title">{{ 'lup.messages.you_have_no_messages' | translate }}</h4>
			</div>
			<ul class="list-unstyled smart-list " role="listbox" *ngIf="messages.length" [attr.aria-label]="'a11y.message_list' | translate">
				<li class="ss-ddm__info def-focus read" [ngClass]="{'read': !!message.is_read, 'unread': !message.is_read}" *ngFor="let message of messages" role="option">
					<a [attr.href]="'/messages/inbox/' + message.id" class="ss-ddm__item">
						<div class="o-flex0" aria-hidden="true">
							<div class="ss-ddm__status link-bg"></div>
							<div class="gravatar" *ngIf="!!message.profile_image">
								<img [src]="message.profile_image.small_url" class="img-responsive" [alt]="message.profile_image.alt_tag">
							</div>
							<div class="avatar" *ngIf="!message.profile_image">{{ avatarInitials(message.display_name) }}</div>
						</div>
						<div class="ss-ddm__cnt">
							<p class="ss-ddm__name" [attr.aria-label]="(message.is_read ? 'lup.a11y.read_message_from' : 'lup.a11y.unread_message_from') + ' ' + message.first_name">{{ message.first_name }}</p>
							<p class="ss-ddm__bd" [attr.aria-label]="('lup.a11y.message_text' | translate) + ' ' + message.body + ' ' + ('lup.a11y.more_detail_space_and_enter' | translate)" [innerHTML]="message.body"></p>
						</div>
					</a>
				</li>
			</ul>
			<footer class="ss-ddm__ft" *ngIf="messages.length">
				<a href="/messages/inbox">
					{{ 'lup.messages.show_all_messages' | translate }}
				</a>
			</footer>
		</ng-container>
	</ul>
</div>
