import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseComponent } from '../../../base/base.component';
import { TileConfig, LuLearningPathTile } from '../../models/tiles.models';

/**
 * Learning Path Tile Component - renders results fetched from global smart search for learning path searches
 */

@Component({
	templateUrl: './learning-path-tile.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LearningPathTileComponent extends BaseComponent implements TileConfig<LuLearningPathTile> {
	/**
	 * Data that is passed in for each tile
	 */
	data: LuLearningPathTile;
}
