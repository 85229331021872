<div class="null-state" [ngClass]="options.wrapperClass">
	<lu-icon class="null-state__icon" *ngIf="options.icon" [icon]="options.icon"></lu-icon>
	<img [src]="options.image" class="null-state__img" [ngClass]="options.imageClass" *ngIf="options.image"
		[alt]="options.imageAlt">
		<h2 class="null-state__tl" *ngIf="options.title" [ngClass]="options.titleClass">{{ options.title }}</h2>
		<div class="null-state__text-wrapper">
			<div>
				<p class="null-state__text" *ngIf="options.text" [innerHTML]="options.text"></p>
			</div>
		</div>
	<button class="btn null-state__cta" *ngIf="options.ctaBtn" [ngClass]="options.ctaBtn.class || 'btn--theme'"
		[disabled]='options.ctaBtn.disabled' (click)="options.ctaBtn.onClick()">
		<lu-icon *ngIf="options.ctaBtn.icon" [icon]="options.ctaBtn.icon" class="btn__ico"></lu-icon>
		<span class="btn__txt">{{ options.ctaBtn.label }}</span>
	</button>
</div>
