<div class="dropdown" dropdown #dropdown="bs-dropdown" [cdkTrapFocus]="dropdown.isOpen" angieCloseOnEscapeKey>
	<button type="button"
		[tooltip]="'lup.tips.header.user_settings' | translate"
		[tooltipAnimation]="false"
		class="header-btn header-btn--usr-settings def-focus js-focus" [ngClass]="{'header-btn--hidden-mobile': isLearner || isInLearnerView}"
		[attr.aria-label]="userMenu.display_name"
		dropdownToggle>
		<span class="header-color-icon">
			<ng-container *ngIf="userMenu">
				<div class="gravatar gravatar--bdr gravatar--no-margin" *ngIf="portalMeta.allow_profile_images && userMenu.avatar">
					<img [src]="userMenu.avatar.small_url" class="img-responsive" [alt]="userMenu.avatar.alt_tag || ('lup.a11y.avatar_alt_text' | translate: { user_name: userMenu.display_name })" />
				</div>
				<ng-container *ngIf="(!portalMeta.allow_profile_images || !userMenu.avatar) as noImage">
					<span class="avatar avatar--usr" *ngIf="noImage && userMenu.display_name">
						{{ initials(userMenu.display_name) }}
					</span>
					<span class="avatar avatar--usr" *ngIf="noImage && !userMenu.display_name && userMenu.username">
						{{ firstTwoLetters(userMenu.username) }}
					</span>
					<span class="avatar avatar--usr" *ngIf="noImage && !userMenu.display_name && !userMenu.username && userMenu.email">
						{{ firstTwoLetters(userMenu.email) }}
					</span>
				</ng-container>
			</ng-container>
		</span>
	</button>
	<div class="dropdown-menu dropdown-menu-right header-dropdown ss-ddm ss-ddm--usr" *dropdownMenu (click)="$event.stopPropagation()">
		<a role="user-menu-area" class="profile-menu-area" href="/my-profile">
			<div class="ss-ddm__header def-focus">
				<ng-container *ngIf="userMenu">
					<div class="gravatar gravatar--sm" *ngIf="portalMeta.allow_profile_images && userMenu.avatar">
						<img [src]="userMenu.avatar.large_url" [alt]="userMenu.avatar.alt_tag || ('lup.a11y.avatar_alt_text' | translate: { user_name: userMenu.display_name })"/>
					</div>
					<ng-container *ngIf="(!portalMeta.allow_profile_images || !userMenu.avatar) as noImage">
					<span class="avatar avatar--sm" *ngIf="noImage && userMenu.display_name">
						{{ initials(userMenu.display_name) }}
					</span>
						<span class="avatar avatar--sm" *ngIf="noImage && !userMenu.display_name && userMenu.username">
						{{ firstTwoLetters(userMenu.username) }}
					</span>
						<span class="avatar avatar--sm" *ngIf="noImage && !userMenu.display_name && !userMenu.username && userMenu.email">
						{{ firstTwoLetters(userMenu.email) }}
					</span>
					</ng-container>
				</ng-container>
				<div *ngIf="userMenu">
					<div class="h-s mb-4">{{ userMenu.display_name }}</div>
					<p class="tag">{{ userMenu.email || userMenu.username }}</p>
				</div>
			</div>
		</a>
		<nav [attr.aria-label]="'lup.tips.header.user_settings' | translate">
			<ul role="menu" class="ss-ddm__list">
				<li *ngIf="!isLearner && portalMeta.learner_dashboard_enabled"
						[attr.aria-label]="switchViewLabel">
					<div class="user-menu__section">
						<button type="button" (click)="switchViewMode()"
										class="btn btn--theme btn__ico user-menu__button user-menu__button--full-width">
							<lu-icon class="btn__icon icon-large" [type]="LuIconType.SOLID" icon="lu-switch"></lu-icon>
							<span class="btn__txt pl-16">{{ switchViewLabel }}</span>
						</button>
					</div>
				</li>
				<ng-container *ngFor="let item of userMenuNavigation">
					<ng-container *ngIf="item.show">
						<li>
							<a role="menuitem" class="ss-ddm__list-item" [href]="item.url" [attr.target]="item.target" *ngIf="!item.onClick">
								<lu-icon class="icon-large user-menu__icon" [icon]="item.icon"></lu-icon>{{ item.label | translate }}
							</a>
							<button role="menuitem" class="ss-ddm__list-item def-focus" *ngIf="!!item.onClick" (click)="item.onClick()">
								<lu-icon class="icon-large user-menu__icon" [icon]="item.icon"></lu-icon>{{ item.label | translate }}
							</button>
						</li>
					</ng-container>
				</ng-container>
				<ng-container  *ngIf="!portalMeta.loaded_in_salesforce">
					<li class="divider user-menu__divider" aria-hidden="true"></li>
					<li>
						<a role="menuitem" class="ss-ddm__list-item" href="/users/sign_out" (click)="signOutClicked()">
							<lu-icon class="icon-large user-menu__icon" icon="lu-logout"></lu-icon> {{ 'new_store.logout' | translate }}
						</a>
					</li>
				</ng-container>
			</ul>
		</nav>
	</div>
</div>

<!-- Learner user menu, mobile screens -->
<ng-container *ngIf="isLearner || isInLearnerView">
	<div class="dropdown" dropdown #dropdownMobile="bs-dropdown" [cdkTrapFocus]="dropdownMobile.isOpen" angieCloseOnEscapeKey>
		<button class="header-btn header-btn--mobile" dropdownToggle>
			<lu-icon class="icon-large header-color-icon" customClass="header-color-icon" icon="lu-hamburger-menu"></lu-icon>
		</button>
		<div class="dropdown-menu learner-menu" *dropdownMenu (click)="$event.stopPropagation()">
			<button type="button" class="learner-menu__close def-focus" (click)="dropdownMobile.hide()">
				<lu-icon icon="lu-close" class="header-color-icon" customClass="header-color-icon"></lu-icon>
			</button>
			<div class="learner-menu__user">
				<ng-container *ngIf="userMenu">
					<div class="gravatar gravatar--md" *ngIf="portalMeta.allow_profile_images && userMenu.avatar">
						<img [src]="userMenu.avatar.large_url" [alt]="userMenu.avatar.alt_tag || ('lup.a11y.avatar_alt_text' | translate: { user_name: userMenu.display_name })"/>
					</div>
					<ng-container *ngIf="(!portalMeta.allow_profile_images || !userMenu.avatar) as noImage">
						<span class="avatar avatar--md avatar-initials"  *ngIf="noImage && userMenu.display_name">
							{{ initials(userMenu.display_name) }}
						</span>
						<span class="avatar avatar--md" *ngIf="noImage && !userMenu.display_name && userMenu.username">
							{{ firstTwoLetters(userMenu.username) }}
						</span>
						<span class="avatar avatar--md" *ngIf="noImage && !userMenu.display_name && !userMenu.username && userMenu.email">
							{{ firstTwoLetters(userMenu.email) }}
						</span>
					</ng-container>
				</ng-container>
				<div *ngIf="userMenu">
					<a href="/my-profile">
						<div class="ellipsis fs-h4">{{ userMenu.display_name }}</div>
						<p class="learner-menu__email"> {{ userMenu.email || userMenu.username }}</p>
					</a>
				</div>
			</div>
			<nav *ngIf="!isLearner" [attr.aria-label]="switchViewLabel">
				<div class="user-menu__section">
					<button type="button" (click)="switchViewMode()"
									class="btn btn--theme btn__ico user-menu__button">
						<lu-icon class="btn__icon icon-large" [type]="LuIconType.SOLID" icon="lu-switch"></lu-icon>
						<span class="btn__txt pl-8">{{ switchViewLabel }}</span>
					</button>
				</div>
			</nav>
			<nav [attr.aria-label]="'lup.tips.header.user_settings' | translate">
				<ul role="menu" class="learner-menu__list">
					<ng-container *ngFor="let navItem of learnerNavigation">
						<ng-container *ngIf="navItem.show">
							<li class="divider-learner" aria-hidden="true" *ngIf="navItem.hasDivider"></li>
							<li>
								<a role="menuitem" class="learner-menu__list-item" [ngClass]="{'active': activeUrl === navItem.url }" [href]="navItem.url" [attr.target]="navItem.target" *ngIf="!navItem.onClick">
									<lu-icon customClass="main-nav-learner__ico" class="icon-large main-nav-learner__ico" [icon]="navItem.icon"></lu-icon>{{ navItem.label | translate }}
								</a>
								<button role="menuitem" class="learner-menu__list-item def-focus" *ngIf="!!navItem.onClick" (click)="navItem.onClick()">
									<lu-icon customClass="main-nav-learner__ico" class="main-nav-learner__ico" [icon]="navItem.icon"></lu-icon>{{ navItem.label | translate }}
								</button>
							</li>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="!portalMeta.loaded_in_salesforce">
						<li class="learner-divider" aria-hidden="true"></li>
						<li>
							<a role="menuitem" class="learner-menu__list-item" href="/users/sign_out" (click)="clearLocalStorage()">
								<lu-icon icon="lu-logout" customClass="main-nav-learner__ico" class="icon-large main-nav-learner__ico"></lu-icon> {{ 'new_store.logout' | translate }}
							</a>
						</li>
					</ng-container>
				</ul>
			</nav>
		</div>
	</div>
</ng-container>
