<div class="crd crd--list crd--3dot crd--no-effect" *ngIf="user.role_code !== LuUserRoleCode.MEMBER && user.role_code !== LuUserRoleCode.MANAGER; else hidden">
	<div class="crd__bd">
		<div class="o-flex2 o-flex2--ellipsis-cntr o-flex o-flex--vcenter">
			<a [href]="'/courses/' + data.id"
					class="ellipsis"
					[attr.aria-label]="('certificates.course_title' | translate) + ' ' + data.name">{{ data.name }}
			</a>
		</div>
		<div class="o-flex1 crd__act o-flex o-flex--vcenter">
			<div class="hidden-xs text-right crd__info--icon" *ngIf="!editMode">
				<span class="gs-result__act tag" [attr.aria-label]="('lup.a11y.course_version' | translate) + ' ' + data.version">
					<lu-icon [type]="LuIconType.SOLID" icon="tag gray-icon"></lu-icon> {{ data.version }}
				</span>
			</div>
			<div class="o-flex order-1 dots-ddm dots-ddm--sm">
				<button class="dots-ddm__btn link-c def-focus" (click)="toggleEditMode()" [title]="(editMode ? 'close' : 'lup.a11y.more_options') | translate" [attr.aria-pressed]="editMode" [attr.aria-haspopup]="editMode" [attr.aria-expanded]="editMode">
					<lu-icon [type]="LuIconType.SOLID" icon="ellipsis-v" *ngIf="!editMode"></lu-icon>
					<lu-icon [type]="LuIconType.SOLID" icon="times" *ngIf="editMode"></lu-icon>
				</button>
			</div>
			<div class="o-flex">
				<ul class="gs-action-list" *ngIf="editMode" [attr.aria-hidden]="editMode">
					<li>
						<a [href]="'/courses/' + data.id" tabindex="0" [attr.aria-label]="'lup.global_search.edit_course' | translate">
							<lu-icon [type]="LuIconType.SOLID" icon="pencil gray-icon"></lu-icon>{{ 'lup.global_search.edit_course' | translate }}
						</a>
					</li>
					<li>
						<a [href]="'/courses/' + data.id + '/content'" [attr.aria-label]="'lup.global_search.edit_content' | translate">
							<lu-icon [type]="LuIconType.SOLID" icon="list gray-icon"></lu-icon>{{ 'lup.global_search.edit_content' | translate }}
						</a>
					</li>
					<li>
						<a [href]="'/courses/' + data.id + '/enrollments'" [attr.aria-label]="'lup.global_search.enrollments' | translate">
							<lu-icon [type]="LuIconType.SOLID" icon="users gray-icon"></lu-icon>{{ 'lup.global_search.enrollments' | translate }}
						</a>
					</li>

					<li *ngIf="data.published_status_id === CoursePublishedStatus.PUBLISHED">
						<a [href]="'/enrollments/new?course_id=' + data.id" [attr.aria-label]="'lup.global_search.create_enrollment' | translate">
							<lu-icon [type]="LuIconType.SOLID" icon="exchange gray-icon"></lu-icon>{{ 'lup.global_search.create_enrollment' | translate }}
						</a>
					</li>

					<li *ngIf="user.role_code === LuUserRoleCode.ADMIN">
						<a [href]="'/courses/' + data.id + '/preview'" [attr.aria-label]="'lup.global_search.preview_course' | translate">
							<lu-icon [type]="LuIconType.SOLID" icon="eye gray-icon"></lu-icon>{{ 'lup.global_search.preview_course' | translate }}
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>

<ng-template #hidden>
	<div class="crd crd--list crd--3dot crd--no-effect">
		<div class="crd__bd">
			<div class="crd__cnt o-flex2">
				<a [href]="'/enrollments/' + data.id + '/details'"
						class="ellipsis"
						[attr.aria-label]="('certificates.course_title' | translate) + ' ' + data.name">{{ data.name }}
				</a>
			</div>
		</div>
		<div class="crd__act o-flex1">
			<a class="gs-result__act" [href]="'/enrollments/' + data.id + '/content'"
					[attr.aria-label]="'lup.tips.number_of_modules' | translate">
				<lu-icon [type]="LuIconType.SOLID" icon="list" class="gray-icon"></lu-icon> {{ data.course_components_count }}
			</a>
		</div>
	</div>
</ng-template>
