import { IntegrationType } from '../models/integration.model';

export enum IntegrationsRoutesConfig {
	//Root url
	INTEGRATIONS_BASE_URL = 'integrations',

	//URL segments
	OVERVIEW_TAB_URL_SEGMENT = 'overview',
	CONTENT_TAB_URL_SEGMENT = 'content',
	ACTIVITY_TAB_URL_SEGMENT = 'activity',
	// TODO: Needs to be refactored in one generic settings page
	ADDITIONAL_SETTINGS_TAB_URL_SEGMENT = 'additional-settings',
	SCORM_IMPORT_SETTINGS_TAB_URL_SEGMENT = 'import-settings',
	INVITE_CONFIG_TAB_URL_SEGMENT = 'invite-config',
	// TODO: Needs to be refactored in one generic settings page
	ADDITIONAL_CONFIG_TAB_URL_SEGMENT = 'additional-config',
	CUD_MAPPING_TAB_URL_SEGMENT = 'cud-mapping',

	//Params
	PROVIDER_PARAM = ':provider'
}

export const IntegrationUrlSegments = {
	[IntegrationType.CONTENT]: 'content-integrations',
	[IntegrationType.HR]: 'hr-integrations',
	[IntegrationType.CRM]: 'crm-integrations',
	[IntegrationType.NOTIFICATION]: 'notification-integrations'
};
