import { IntegrationsRoutesConfig, IntegrationUrlSegments } from './integrations-routes.config';
import { ContentProviderKeys, IntegrationType, ProviderCode } from '../models/integration.model';
import { angieRoutePrefix } from 'src/app/globals';

/**
 * Integrations routes
 * Centralised location for routing paths definitions
 */
export class IntegrationsRoutes {
	// URL segment names - used as is, for routing modules
	public static readonly INTEGRATIONS_BASE_URL = IntegrationsRoutesConfig.INTEGRATIONS_BASE_URL;

	// Used in actual components and html for routing
	public static readonly INTEGRATIONS_BASE_URL_HOME_ROUTE = '/settings/' + IntegrationsRoutes.INTEGRATIONS_BASE_URL;

	//ULR Segments
	public static readonly OVERVIEW_TAB_URL_SEGMENT = IntegrationsRoutesConfig.OVERVIEW_TAB_URL_SEGMENT;
	public static readonly CONTENT_TAB_URL_SEGMENT = IntegrationsRoutesConfig.CONTENT_TAB_URL_SEGMENT;
	public static readonly ACTIVITY_TAB_URL_SEGMENT = IntegrationsRoutesConfig.ACTIVITY_TAB_URL_SEGMENT;
	public static readonly ADDITIONAL_SETTINGS_TAB_URL_SEGMENT =
		IntegrationsRoutesConfig.ADDITIONAL_SETTINGS_TAB_URL_SEGMENT;
	public static readonly SCORM_IMPORT_SETTINGS_TAB_URL_SEGMENT =
		IntegrationsRoutesConfig.SCORM_IMPORT_SETTINGS_TAB_URL_SEGMENT;
	public static readonly INVITE_CONFIG_TAB_URL_SEGMENT = IntegrationsRoutesConfig.INVITE_CONFIG_TAB_URL_SEGMENT;
	public static readonly ADDITIONAL_CONFIG_TAB_URL_SEGMENT = IntegrationsRoutesConfig.ADDITIONAL_CONFIG_TAB_URL_SEGMENT;
	public static readonly CUD_MAPPING_TAB_URL_SEGMENT = IntegrationsRoutesConfig.CUD_MAPPING_TAB_URL_SEGMENT;

	//Params
	public static readonly PROVIDER_PARAM = IntegrationsRoutesConfig.PROVIDER_PARAM;

	public static getAvailableIntegrationsRoute(integrationType: IntegrationType): string {
		return IntegrationsRoutes.INTEGRATIONS_BASE_URL_HOME_ROUTE + '/' + IntegrationUrlSegments[integrationType];
	}

	public static getIntegrationRoute(integrationType: IntegrationType, providerIdentifier: ProviderCode): string {
		return this.getAvailableIntegrationsRoute(integrationType) + '/' + providerIdentifier;
	}

	public static getOverviewTabRoute(integrationType: IntegrationType, providerIdentifier: ProviderCode): string {
		return (
			IntegrationsRoutes.getIntegrationRoute(integrationType, providerIdentifier) +
			'/' +
			IntegrationsRoutes.OVERVIEW_TAB_URL_SEGMENT
		);
	}

	public static getContentTabRoute(integrationType: IntegrationType, providerIdentifier: ProviderCode): string {
		return (
			IntegrationsRoutes.getIntegrationRoute(integrationType, providerIdentifier) +
			'/' +
			IntegrationsRoutes.CONTENT_TAB_URL_SEGMENT
		);
	}

	public static getActivityTabRoute(integrationType: IntegrationType, providerIdentifier: ProviderCode): string {
		return (
			IntegrationsRoutes.getIntegrationRoute(integrationType, providerIdentifier) +
			'/' +
			IntegrationsRoutes.ACTIVITY_TAB_URL_SEGMENT
		);
	}

	public static getInviteConfigTabRoute(integrationType: IntegrationType, providerIdentifier: ProviderCode): string {
		return (
			IntegrationsRoutes.getIntegrationRoute(integrationType, providerIdentifier) +
			'/' +
			IntegrationsRoutes.INVITE_CONFIG_TAB_URL_SEGMENT
		);
	}

	public static getAdditionalConfigTabRoute(
		integrationType: IntegrationType,
		providerIdentifier: ProviderCode
	): string {
		return (
			IntegrationsRoutes.getIntegrationRoute(integrationType, providerIdentifier) +
			'/' +
			IntegrationsRoutes.ADDITIONAL_CONFIG_TAB_URL_SEGMENT
		);
	}

	public static getCudMappingTabRoute(integrationType: IntegrationType, providerIdentifier: ProviderCode): string {
		return (
			IntegrationsRoutes.getIntegrationRoute(integrationType, providerIdentifier) +
			'/' +
			IntegrationsRoutes.CUD_MAPPING_TAB_URL_SEGMENT
		);
	}

	public static getScormImportSettingsTabRoute(
		integrationType: IntegrationType,
		providerIdentifier: ProviderCode
	): string {
		return (
			IntegrationsRoutes.getIntegrationRoute(integrationType, providerIdentifier) +
			'/' +
			IntegrationsRoutes.SCORM_IMPORT_SETTINGS_TAB_URL_SEGMENT
		);
	}

	public static getAdditionalSettingsTabRoute(
		integrationType: IntegrationType,
		providerIdentifier: ProviderCode
	): string {
		return (
			IntegrationsRoutes.getIntegrationRoute(integrationType, providerIdentifier) +
			'/' +
			IntegrationsRoutes.ADDITIONAL_SETTINGS_TAB_URL_SEGMENT
		);
	}

	public static GET_COURSES = (providerIdentifier: ProviderCode): string =>
		angieRoutePrefix.concat(
			`/integrations/content/courses.json?provider_key=${ContentProviderKeys[providerIdentifier].key}`
		);

	public static IMPORT_COURSE = (providerIdentifier: ProviderCode, courseId: number): string =>
		angieRoutePrefix.concat(
			`/integrations/content/${ContentProviderKeys[providerIdentifier].key}/import_course/${courseId}.json`
		);

	public static IMPORT_POOLING = (jobKey: number): string =>
		angieRoutePrefix.concat(`/integrations/content/job_status/${jobKey}.json`);
}
