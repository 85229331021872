import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import {
	IMinimalHeaderActionConfig,
	IMinimalHeaderLinkConfig,
	IMinimalHeaderMainActionConfig
} from 'src/app/layouts/default-layout/models/minimal-header.models';

@Injectable({
	providedIn: 'root'
})
export class MinimalHeaderService {
	private linkConfig$ = new BehaviorSubject<IMinimalHeaderLinkConfig>(null);
	private actionConfig$ = new BehaviorSubject<IMinimalHeaderActionConfig>(null);
	private mainActionConfig$ = new BehaviorSubject<IMinimalHeaderMainActionConfig>(null);
	private disabled$ = new BehaviorSubject<boolean>(false);

	public getLinkConfig(): Observable<IMinimalHeaderLinkConfig> {
		return this.linkConfig$.asObservable().pipe(delay(0));
	}

	public setLinkConfig(config: IMinimalHeaderLinkConfig): void {
		this.linkConfig$.next(config);
	}

	public getActionConfig(): Observable<IMinimalHeaderActionConfig> {
		return this.actionConfig$.asObservable().pipe(delay(0));
	}

	public setActionConfig(actionConfig: IMinimalHeaderActionConfig): void {
		this.actionConfig$.next(actionConfig);
	}

	public getMainActionConfig(): Observable<IMinimalHeaderMainActionConfig> {
		return this.mainActionConfig$.asObservable().pipe(delay(0));
	}

	public setMainActionConfig(mainActionConfig: IMinimalHeaderMainActionConfig): void {
		this.mainActionConfig$.next(mainActionConfig);
	}

	public getDisabled(): Observable<boolean> {
		return this.disabled$.asObservable().pipe(delay(0));
	}

	public setDisabled(disabled: boolean): void {
		this.disabled$.next(disabled);
	}

	public clearLinkConfig(): void {
		this.linkConfig$.next(null);
	}

	public clearActionConfig(): void {
		this.actionConfig$.next(null);
	}

	public clearMainActionConfig(): void {
		this.mainActionConfig$.next(null);
	}

	public clearDisabled(): void {
		this.disabled$.next(false);
	}

	public clearConfig(): void {
		this.clearLinkConfig();
		this.clearActionConfig();
		this.clearMainActionConfig();
		this.clearDisabled();
	}
}
