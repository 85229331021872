import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LuModalModule } from 'lu-modal';
import { ProxyUserModalComponent } from './proxy-user-modal.component';
import { FormlySharedModule } from '../../../modules/formly-shared.module';
import { TranslateModule } from '@ngx-translate/core';

const declarations = [ProxyUserModalComponent];

const imports = [CommonModule, FormlySharedModule, LuModalModule, TranslateModule];

@NgModule({
	declarations,
	imports
})
export class ProxyUserModalModule {}
