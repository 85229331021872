import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { WindowRefService as SharedWindowRefService } from 'lu-services';

/**
 * Window Ref Service - inherits SharedWindowRefService (lu-services)
 *
 * it has window inside isolated scope based on platformID so we can easily extend it sometimes in the future
 *
 * Eg. jumping to angular universal etc.
 */
@Injectable()
export class WindowRefService extends SharedWindowRefService {
	/**
	 * Constructor
	 */
	constructor(@Inject(PLATFORM_ID) platformId) {
		super(platformId);
	}

	scrollToTop(): void {
		this.nativeWindow.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
	}

	scrollTo(left: number = 0, top: number = 0): void {
		this.nativeWindow.scrollTo({ left, top, behavior: 'smooth' });
	}

	goTo(route: string): void {
		this.nativeWindow.location.href = `/${route}`;
	}
}
