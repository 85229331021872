import { LearningJourneyProgressionRoutesConfig } from './learning-journey-progression-routes.config';

export class LearningJourneyProgressionRoutes {
	public static LJ_PROGRESSION_HOME_ROUTE = LearningJourneyProgressionRoutesConfig.LJ_PROGRESSION_BASE_URL;
	public static LJ_PROGRESSION_DETAILS_ROUTE =
		LearningJourneyProgressionRoutesConfig.LJ_PROGRESSION_DETAILS_ID_VERSION_USER_ENROLLMENT_SEGMENT;

	public static getLJProgressionDetailsRoute(
		workflowId: number,
		versionId: number,
		userId: number,
		enrollmentId: number
	): string {
		return `${LearningJourneyProgressionRoutesConfig.LJ_PROGRESSION_BASE_URL}/${workflowId}/${versionId}/${userId}/${enrollmentId}`;
	}
}
