import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LuModalConfig } from 'lu-modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BaseResponse, DataService, DsConfig } from '../../../../core';
import { SupportAccessRoutes } from '../../../routes';

/**
 * Support Access Modal Component
 *
 * Show modal if unverified admin tries to open support desk
 */
@Component({
	selector: 'angie-support-access-modal',
	templateUrl: './support-access-modal.component.html',
	styleUrls: ['./support-access-modal.component.scss']
})
export class SupportAccessModalComponent {
	/**
	 * Modal Options Config
	 */
	modalOptions: LuModalConfig = {
		title: this.translateService.instant('support_centre_confirmation.unavailable_header'),
		modalClass: 'modal-cnt',
		primaryActions: [
			{
				label: this.translateService.instant('support_centre_confirmation.send_confirmation_button'),
				class: 'btn--default',
				onClick: () => {
					const dsConfig: DsConfig = this.dataService.getDefaultConfig(SupportAccessRoutes.SEND_VERIFICATION_EMAIL);
					this.dataService.post(dsConfig, {}).subscribe({
						next: (response: BaseResponse<boolean>) => {
							const { data } = response;
							this.bsModalRef.hide();
							if (data) {
								const successMessage = this.translateService.instant(
									'support_centre_confirmation.confirmation_email.successfully_sent'
								);
								this.toastrService.success(successMessage);
								return;
							}
							const errorMessage = this.translateService.instant(
								'support_centre_confirmation.confirmation_email.failed_to_send'
							);
							this.toastrService.error(errorMessage);
						}
					});
				}
			},
			{
				label: this.translateService.instant('close'),
				class: 'btn--default',
				onClick: () => {
					this.bsModalRef.hide();
				}
			}
		]
	};
	/**
	 * Email - passed thorugh InitialState
	 */
	email: string;

	/**
	 * Constructor
	 */
	constructor(
		private readonly translateService: TranslateService,
		private readonly bsModalRef: BsModalRef,
		private readonly dataService: DataService,
		private readonly toastrService: ToastrService
	) {}
}
