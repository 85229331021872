import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { BaseComponent } from 'src/app/angie-shared/components/base/base.component';
import { MinimalHeaderService } from 'src/app/core/services/minimal-header.service';
import {
	IMinimalHeaderActionConfig,
	IMinimalHeaderLinkConfig,
	IMinimalHeaderMainActionConfig
} from '../../models/minimal-header.models';

@Component({
	selector: 'angie-minimal-header',
	templateUrl: './minimal-header.component.html',
	styleUrls: ['./minimal-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MinimalHeaderComponent extends BaseComponent implements OnInit {
	linkConfig$: Observable<IMinimalHeaderLinkConfig>;
	actionConfig$: Observable<IMinimalHeaderActionConfig>;
	mainActionConfig$: Observable<IMinimalHeaderMainActionConfig>;
	disabled$: Observable<boolean>;

	constructor(
		private readonly minimalHeaderService: MinimalHeaderService,
		public readonly translateService: TranslateService
	) {
		super();
	}

	ngOnInit(): void {
		this.linkConfig$ = this.minimalHeaderService.getLinkConfig();
		this.actionConfig$ = this.minimalHeaderService.getActionConfig();
		this.mainActionConfig$ = this.minimalHeaderService.getMainActionConfig();
		this.disabled$ = this.minimalHeaderService.getDisabled();
	}
}
