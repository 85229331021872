import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { FormlyModule } from '@ngx-formly/core';
import { LuCkeditorModule } from 'lu-ck-editor';
import { LuCKEditorFormlyComponent } from './lu-ckeditor-formly.component';

const declarations = [LuCKEditorFormlyComponent];

const imports = [
	CommonModule,
	ReactiveFormsModule,
	MatSliderModule,
	LuCkeditorModule,
	FormlyModule.forChild({
		types: [
			{
				name: 'lu-ckeditor',
				component: LuCKEditorFormlyComponent,
				wrappers: ['form-field']
			}
		]
	})
];

@NgModule({
	imports,
	declarations
})
export class LuCKEditorFormlyModule {}
