import { Component } from '@angular/core';

/**
 * Default Layout Component
 *
 * Contains all of the information regarding our layout and all of our components that are layout related
 */
@Component({
	selector: 'angie-empty-layout',
	templateUrl: './empty-layout.component.html'
})
export class EmptyLayoutComponent {}
