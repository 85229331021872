export enum LayoutStructureMembers {
	showDefaultTopHeader = 'showDefaultTopHeader',
	showDefaultSubHeader = 'showDefaultSubHeader',
	showDefaultFooter = 'showDefaultFooter',
	showMinimalTopHeader = 'showMinimalTopHeader',
	showMinimalActionFooter = 'showMinimalActionFooter',
	showAdminNav = 'showAdminNav'
}

export enum LayoutCssClassesMembers {
	body = 'body',
	header = 'header',
	content = 'content',
	footer = 'footer',
	adminWrap = 'adminWrap'
}

export enum LayoutConfigPresets {
	default = 'default',
	minimal = 'minimal',
	empty = 'empty'
}
export interface ILayoutStructure {
	showDefaultTopHeader?: boolean;
	showDefaultSubHeader?: boolean;
	showDefaultFooter?: boolean;
	showMinimalTopHeader?: boolean;
	showAdminNav?: boolean;
}
export interface ILayoutCssClasses {
	body?: string[];
	header?: string[];
	content?: string[];
	footer?: string[];
	adminWrap?: string[];
}
export class LayoutConfig {
	structure: ILayoutStructure = {} as ILayoutStructure;
	cssClasses: ILayoutCssClasses = {} as ILayoutCssClasses;

	constructor(structure: ILayoutStructure = {}) {
		this.structure = structure;
	}
}

export class DefaultLayoutStructure implements ILayoutStructure {
	showDefaultTopHeader = true;
	showDefaultSubHeader = true;
	showDefaultFooter = true;
	showAdminNav = true;
}

export class MinimalHeadersLayoutStructure implements ILayoutStructure {
	showMinimalTopHeader = true;
}
