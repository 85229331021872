import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseResponse, WindowRefService } from 'lu-services';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AngieAppRoutes } from 'src/app/angie-app.routes';
import { DATE_FORMAT as DEFAULT_DATE_FORMAT, NgDateFormats, RubyToNgDateFormatMap } from 'src/app/globals';
import { Footer } from 'src/app/layouts/default-layout/default.layout.models';
import { IAuthData } from 'src/app/modules/auth/models/auth.model';
import { AuthService } from 'src/app/modules/auth/service/auth.service';
import { CatalogMeta } from 'src/app/modules/catalog/models/catalog.model';
import { CatalogRoutes } from 'src/app/modules/catalog/routes/catalog.routes';
import { CatalogMetaService } from 'src/app/modules/catalog/services/catalog-meta.service';
import {
	AccountMeta,
	ChatSettings,
	CkEditor,
	FlashMessage,
	LuStateTimezone,
	LuUser,
	PopupMessage,
	PortalMeta
} from '../core.models';
import { CoreRoutes } from '../routes/core.routes';
import { Language, LanguageService } from './language.service';
/**
 * State Service - in charge for fetching initial data and keeping it.
 *
 * We're using this service as our core state service to share data across whole app
 */
@Injectable()
export class StateService {
	/**
	 * Portal title
	 */
	portalTitle: string;
	/**
	 * Lu User object that defines our currently logged in user
	 */
	luUser: LuUser;
	/**
	 * xsrfToken that we fetch after app has been initialized
	 */
	xsrfToken = '';
	/**
	 * Language
	 */
	language: Language;
	/**
	 * Footer config
	 */
	footer: Footer;
	/**
	 * unread messages for current user's inbox
	 */
	unreadMessages: number;
	/**
	 * reference to the dynamic rule
	 */
	dynamicRulesReference: string;
	/**
	 * Portal meta that we'll need in order to include conditionals for specific user thorugh app
	 */
	portalMeta: PortalMeta;
	/**
	 * Flash Message object which handles if we should show flash message and which one should that be
	 */
	flashMessage: FlashMessage;
	/**
	 * Chat Integration settings that we need in order to have chat integration inside our application
	 */
	chatSettings: ChatSettings;
	/**
	 * Default date format that we'll use for date pipe accross our app
	 */
	dateFormat: NgDateFormats = DEFAULT_DATE_FORMAT;
	/**
	 * Toastr Messages if any
	 */
	toastrMessages: PopupMessage[];
	/**
	 * Flash messages status
	 *
	 * we emit this one to subject cause we subscribe inside app component to know if we should show flash message or not
	 */
	flashMessageStatus$: BehaviorSubject<FlashMessage> = new BehaviorSubject(null);
	/**
	 * Timezone array of objects - in charge for formatting and having single source of truth regarding timezones
	 */
	timezones: LuStateTimezone[];
	/**
	 * CKEditor config used across the app wherever the CKEditor is used
	 */
	ckEditor: CkEditor;
	/**
	 * Hearder Logo Alt tag
	 */
	headerLogoAltTag: string;
	/**
	 * Constructor
	 */
	constructor(private readonly injector: Injector) {}

	/**
	 * Initial Load
	 *
	 * This method is getting called with APP_INIT injection token on app init
	 *
	 * We set all of the properties here and have in control of these through whole app.
	 */
	initialLoad(): Observable<boolean> {
		const httpClient = this.injector.get(HttpClient);
		const languageService = this.injector.get(LanguageService);
		const toastrService = this.injector.get(ToastrService);
		const authService = this.injector.get(AuthService);
		const windowRefService = this.injector.get(WindowRefService);

		/*
			When route is auth, we don't call myacc.json, we call auth-info.json.
			myacc.json has current user, we don't know current user because we are not logged in yet.
			auth-info will get what is necessery to show sign in page.
		*/
		if (AngieAppRoutes.isAuth(window.location.pathname)) {
			// We need to send auth_code in order to get email from BE.
			const authCode = this.getQueryParams('auth_code');
			const resetPasswordToken = this.getQueryParams('reset_password_token');
			const embedCourseParams = this.getCourseEmbedParams();
			const authCodeParams = authCode ? new HttpParams().append('auth_code', authCode) : null;
			const resetPasswordTokenParams = resetPasswordToken
				? new HttpParams().append('reset_password_token', resetPasswordToken)
				: null;

			const message = this.getQueryParams('flash_message');
			const type = this.getQueryParams('flash_type');
			const flashParams = type && message ? { flash_message: message, flash_type: type } : null;

			const params = authCodeParams || resetPasswordTokenParams || embedCourseParams || flashParams;

			return httpClient.get<BaseResponse<IAuthData>>(CoreRoutes.AUTH_INFO, { params }).pipe(
				switchMap(({ data }) => {
					authService.authData = data;
					this.portalTitle = data.title;
					languageService.language = data.language;
					this.timezones = [data.timezone];
					this.dateFormat = RubyToNgDateFormatMap[data.date_format];
					authService.toastrMessages = data.toastr_messages;
					return this.setLanguage(languageService);
				}),
				catchError(err => {
					// TODO: Should support translations
					toastrService.error('This feature is currently restricted. Please try again in 1 hour.');
					throw err;
				})
			);
		}

		// Catalog and public catalog meta/state setup
		if (window.location.pathname === `/${AngieAppRoutes.CATALOG}`) {
			return this.setCatalogMetadataState(httpClient, languageService);
		}

		return this.updateState();
	}

	updateState(): Observable<boolean> {
		const windowRefService = this.injector.get(WindowRefService);
		const languageService = this.injector.get(LanguageService);
		const httpClient = this.injector.get(HttpClient);

		return httpClient.get<BaseResponse<AccountMeta>>(CoreRoutes.MY_ACCOUNT).pipe(
			switchMap(({ data }) => {
				this.setState(data);
				return this.setLanguage(languageService);
			}),
			catchError(err => {
				//TODO: Change URL after release of new sign in flow.
				windowRefService.getWindow().location.href = AngieAppRoutes.SIGN_IN;
				// toastrService.error('Error');
				throw err;
			})
		);
	}

	private setState(data): void {
		const languageService = this.injector.get(LanguageService);
		const {
			account,
			language,
			footer,
			unread_messages,
			dynamic_rules_reference,
			portal_meta,
			flash_message,
			chat_settings,
			toastr_messages,
			timezones,
			ck_editor
		} = data;

		this.luUser = account;
		languageService.language = language;
		this.language = language;
		this.footer = footer;
		this.unreadMessages = unread_messages;
		this.dynamicRulesReference = dynamic_rules_reference;
		this.portalMeta = portal_meta;
		this.flashMessage = flash_message;
		this.flashMessageStatus$.next(flash_message);
		this.chatSettings = chat_settings;
		this.toastrMessages = toastr_messages;
		this.timezones = timezones;
		this.dateFormat = RubyToNgDateFormatMap[portal_meta.date_format];
		this.ckEditor = ck_editor;
	}

	private setLanguage(languageService: LanguageService): Observable<boolean> {
		return languageService.getLanguage().pipe(
			tap(([languageData, angularLocale]) => {
				languageService.setLanguage(languageData, angularLocale);
			}),
			map(() => true)
		);
	}
	private getCourseEmbedParams(): HttpParams {
		if (window.location.pathname.includes('embed/courses')) {
			return new HttpParams().append('embed_course_id', this.getLastQueryParam(window.location.pathname));
		}
		return null;
	}
	private getQueryParams(param: string): string {
		return new URLSearchParams(window.location.search).get(param);
	}

	private getLastQueryParam(url: string): string {
		return url.split('/').pop();
	}

	/**
	 * Use for Switch between Admin/Learner view
	 * */
	public isInLearnerView(): boolean {
		const learnerPages = [
			AngieAppRoutes.LEARNER_DASHBOARD,
			AngieAppRoutes.CATALOG,
			AngieAppRoutes.LEARNER_RESOURCE_LIST,
			AngieAppRoutes.MY_LEARNING,
			AngieAppRoutes.LEARNING_JOURNEY_LEARNER_VIEW.LJ_PROGRESSION_HOME_ROUTE,
			AngieAppRoutes.CONTENT_DETAILS
		];

		return (
			learnerPages.some(learnerPage => window.location.pathname.includes(learnerPage)) &&
			this.portalMeta?.learner_dashboard_enabled
		);
	}

	private setCatalogMetadataState(httpClient: HttpClient, languageService: LanguageService): Observable<boolean> {
		return httpClient.get<BaseResponse<CatalogMeta>>(CatalogRoutes.CATALOG_METADATA).pipe(
			switchMap(({ data }) => {
				this.injector.get(CatalogMetaService).catalogMeta = data;
				if (data?.is_logged_in) {
					return this.updateState();
				} else {
					this.footer = data.footer;
					this.headerLogoAltTag = data.logo_alt;
					this.portalMeta = { loaded_in_salesforce: false, title: data.title } as PortalMeta;
					languageService.language = data.language;
					return this.setLanguage(languageService);
				}
			})
		);
	}
}
