<div class="crd crd--list crd--3dot crd--no-effect">
	<div class="crd__bd">
		<div class="o-flex2 o-flex2--ellipsis-cntr o-flex o-flex--vcenter">
			<a [href]="'/users/' + data.user_id" class="ellipsis" [attr.aria-label]="('lup.a11y.user' | translate) + ' ' + data.display_name">
				{{ data.display_name }}
			</a>
		</div>

		<div class="o-flex1 o-flex" *ngIf="!editMode">
			<div class="o-flex1">
				<div *ngIf="user.role_code === LuUserRoleCode.ADMIN">
					<lu-icon [type]="LuIconType.SOLID" icon="thumbtack gray-icon"></lu-icon>
					<a [href]="'/users/' + data.user_id + '/user-settings'" [attr.aria-label]="('lup.users.filtering_options.user_type' | translate) + ' ' + (translatePortalMembershipType(data.portal_membership_type_id) | translate)">
						{{ translatePortalMembershipType(data.portal_membership_type_id) | translate }}
					</a>
				</div>
				<div class="gs-result__act" *ngIf="user.role_code !== LuUserRoleCode.ADMIN" [attr.aria-label]="('lup.users.filtering_options.user_type' | translate) + ' ' + (translatePortalMembershipType(data.portal_membership_type_id) | translate)">
					<lu-icon [type]="LuIconType.SOLID" icon="thumbtack gray-icon"></lu-icon>
					<span>{{ translatePortalMembershipType(data.portal_membership_type_id) | translate }}</span>
				</div>
			</div>
			<div class="o-flex1">
				<a class="gs-result__act" [href]="'/users/' + data.user_id + '/enrollments'" [attr.aria-label]="('lup.dashboard.enrolled_courses' | translate) + ' ' + data.enrollment_count">
					<lu-icon [type]="LuIconType.SOLID" icon="book gray-icon"></lu-icon>
					{{ data.enrollment_count }}
				</a>
			</div>
		</div>

		<div class="crd__act o-flex o-flex--vcenter">
			<div class="o-flex order-1 dots-ddm dots-ddm--sm">
				<button class="dots-ddm__btn link-c def-focus"
								(click)="toggleEditMode()"
								[title]="(editMode ? 'close' : 'lup.a11y.more_options') | translate"
								[attr.aria-label]=""
								[attr.aria-pressed]="editMode"
								[attr.aria-expanded]="editMode"
								[attr.aria-haspopup]="editMode">
					<lu-icon [type]="LuIconType.SOLID" icon="ellipsis-v" *ngIf="!editMode"></lu-icon>
					<lu-icon [type]="LuIconType.SOLID" icon="times" *ngIf="editMode"></lu-icon>
				</button>
			</div>
			<div class="o-flex" *ngIf="editMode">
				<ul class="gs-action-list" [attr.aria-hidden]="!editMode">
					<li>
						<a [href]="'/users/' + data.user_id" [attr.aria-label]="'lup.global_search.edit_user' | translate">
							<lu-icon [type]="LuIconType.SOLID" icon="list gray-icon"></lu-icon>{{ 'lup.global_search.edit_user' | translate }}
						</a>
					</li>
					<li>
						<a [href]="'/users/' + data.user_id + '/enrollments'" [attr.aria-label]="'lup.global_search.enrollments' | translate">
							<lu-icon [type]="LuIconType.SOLID" icon="bookmark gray-icon"></lu-icon>{{ 'lup.global_search.enrollments' | translate }}
						</a>
					</li>
					<li>
						<a [href]="'/enrollments/new?user_id=' + data.user_id" [attr.aria-label]="'lup.global_search.create_enrollment' | translate">
							<lu-icon [type]="LuIconType.SOLID" icon="exchange gray-icon"></lu-icon>{{ 'lup.global_search.create_enrollment' | translate }}
						</a>
					</li>
					<li>
						<a [href]="'/users/' + data.user_id + '/groups'" [attr.aria-label]="'lup.global_search.groups' | translate">
							<lu-icon [type]="LuIconType.SOLID" icon="users gray-icon"></lu-icon>{{ 'lup.global_search.groups' | translate }}
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>
