import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { StateService } from '..';
import { CoreRoutes } from 'src/app/core';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

const AUTH_HEADER = 'Authorization';
const AJAX_EMBED_HEADER = 'X-Ajax-Embed';
const AUTH_TOKEN_KEY_LOCAL = 'authToken';

/**
 * Interceptor for Authorization token
 *
 * We intercept request and add "Authorization" to header (read from Local Storage)
 * We also add a header to help to identify embedded requests / learner endpoints
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	/**
	 * Constructor
	 */
	constructor(
		private readonly stateService: StateService,
		private readonly localStorageService: LocalStorageService
	) {}

	/**
	 * Intercept - implemented method from HttpInterceptor
	 */
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// We cannot gate this interceptor behind a flag
		// Since we can only get the feature flag status after we have retrieved information
		// from an authenticated user
		// So we have to be careful to ensure this middleware is backwards compatible

		if (this.isEmbedded() && this.isLearnUponResource(request.url)) {
			request = request.clone({ headers: request.headers.set(AJAX_EMBED_HEADER, '1') });
			const authToken = this.localStorageService.getData(AUTH_TOKEN_KEY_LOCAL);
			if (authToken != null) {
				request = request.clone({ headers: request.headers.set(AUTH_HEADER, 'Bearer-Embed ' + authToken) });
			}
		}

		return next.handle(request).pipe(
			filter(event => event instanceof HttpResponse),
			tap((event: HttpResponse<any>) => {
				if (
					request.url.includes(CoreRoutes.SIGN_IN) &&
					request.method === 'POST' &&
					event.headers.has(AUTH_HEADER) &&
					this.isEmbedded()
				) {
					this.localStorageService.setData(AUTH_TOKEN_KEY_LOCAL, event.headers.get(AUTH_HEADER));
				}
			})
		);
	}

	isLearnUponResource = (url: string): boolean => {
		return (url[0] == '/' || url.includes(window.location.hostname)) && !url.includes('.html');
	};

	isEmbedded = (): boolean => {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	};
}
