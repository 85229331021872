import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseComponent } from '../../../base/base.component';
import { TileConfig, LuCatalogTile } from '../../models/tiles.models';

/**
 * Catalog Tile Component - renders results fetched from global smart search for catalog searches
 */
@Component({
	templateUrl: './catalog-tile.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatalogTileComponent extends BaseComponent implements TileConfig<LuCatalogTile> {
	/**
	 * Data that is passed in for each tile
	 */
	data: LuCatalogTile;
}
