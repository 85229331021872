<span class="bodyCssDirectiveHost" [angieLayoutCssTrack]="layoutCss.body"></span>
<angie-primary-spinner></angie-primary-spinner>
<angie-skip-link></angie-skip-link>
<div *ngIf="!isLearner && !isInLearnerView" [ngClass]="{'admin-nav-offset': !!flashMessageStatus}" [style.--custom-offset]="customOffset">
  <angie-primary-admin-nav class="angie-primary-admin-nav" *angieLayoutToggle="layoutStructure.showAdminNav" (clickToggle)="toggle($event)"></angie-primary-admin-nav>
</div>
<div [angieLayoutCssTrack]="layoutCss.adminWrap" class="admin-wrap" [ngClass]="{'admin-wrap--collapsed' : !navOpened, 'admin-wrap--z-index' : !!zIndexClassToggle, 'no-sidenav': isLearner || isInLearnerView}">
	<header [angieLayoutCssTrack]="layoutCss.header" angieContrast contrastClass="contrast"
					[ngClass]="{'a11y-foc-area': a11Focus, 'header-offset': !!flashMessageStatus, 'no-offset': isLearner}" class="header header--fixed breadcrumb-bg" luScrollWatch
					[style.--custom-offset]="customOffset">
		<div class="flash-messages-header">
			<angie-flash-messages *ngIf="!!flashMessageStatus"></angie-flash-messages>
			<angie-proxy-banner *ngIf="stateService?.luUser?.impersonation_active"></angie-proxy-banner>
			<angie-default-header *angieLayoutToggle="layoutStructure.showDefaultTopHeader"
														(a11Focus)="a11Focus = true"
														(overlayState)="zIndexClass($event)"></angie-default-header>
		</div>
		<angie-minimal-header *angieLayoutToggle="layoutStructure.showMinimalTopHeader"></angie-minimal-header>
		<angie-sub-header *angieLayoutToggle="layoutStructure.showDefaultSubHeader"></angie-sub-header>
		<div [ngClass]="!isLearner && {'breadcrumb--nav-opened': navOpened, 'breadcrumb--nav-collapsed': !navOpened}">
			<lu-breadcrumbs></lu-breadcrumbs>
		</div>
	</header>
	<angie-drawer>
		<section id="main-content" angieAppLayoutType tabindex="0"
							[attr.aria-label]="'lup.a11y.default_main_content' | translate">
			<h1 class="sr-only">{{ (metaData$ | async)?.title | translate }}</h1>
			<div class="wrap wrap--fixed-hd" [ngClass]="{'wrapper--flash-message': !!flashMessageStatus}"
						[angieLayoutCssTrack]="layoutCss.content">
				<router-outlet></router-outlet>
			</div>
		</section>
		<div class="footer">
			<angie-default-footer *angieLayoutToggle="layoutStructure.showDefaultFooter"></angie-default-footer>
			<lu-scroll-top [buttonTitle]="'lup.a11y.go_to_top' | translate"></lu-scroll-top>
			<lu-action-footer class="lu-action-footer" [angieLayoutCssTrack]="layoutCss.footer"></lu-action-footer>
		</div>
	</angie-drawer>
</div>
