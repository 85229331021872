import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LuIconModule } from 'lu-icon';
import { LuModalModule } from 'lu-modal';
import { LanguagePickModalComponent } from './language-pick-modal.component';

/**
 * All of the required declarations that we use inside our language pick modal module
 */
const declarations = [LanguagePickModalComponent];

/**
 * All of the required imports that we use inside our language pick modal module
 */
const imports = [CommonModule, LuIconModule, TranslateModule, LuModalModule];

/**
 * Language Pick Modal Module
 *
 * In charge for showing list of available languages that user can choose and set for his portal
 */
@NgModule({
	declarations,
	imports,
	exports: [...declarations]
})
export class LanguagePickModalModule {}
