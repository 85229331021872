import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LogoComponent } from './logo.component';

@NgModule({
	declarations: [LogoComponent],
	imports: [CommonModule, TranslateModule, RouterModule],
	exports: [LogoComponent]
})
export class LogoModule {}
