import { CatalogTileComponent } from '../tiles/catalog-tile/catalog-tile.component';
import { CoursesTileComponent } from '../tiles/courses-tile/courses-tile.component';
import { GroupsTileComponent } from '../tiles/groups-tile/groups-tile.component';
import { LearningPathTileComponent } from '../tiles/learning-path-tile/learning-path-tile.component';
import { ResourcesTileComponent } from '../tiles/resources-tile/resources-tile.component';
import { UserTileComponent } from '../tiles/user-tile/user-tile.component';

/**
 * Search options for global smart search
 */
export enum SearchOptions {
	COURSES = 1,
	USERS = 2,
	GROUPS = 3,
	LEARNING_PATHS = 4,
	CATALOG = 5,
	RESOURCES = 6
}

/**
 * Placeholder keys based on Search Options chosen
 */
export enum PlaceHolderKey {
	'lup.enrollments.search_enrollments' = SearchOptions.COURSES,
	'lup.enrollments.search_users' = SearchOptions.USERS,
	'lup.enrollments.search_groups' = SearchOptions.GROUPS,
	'lup.enrollments.search_learning_paths' = SearchOptions.LEARNING_PATHS,
	'lup.catalog.search_catalog' = SearchOptions.CATALOG,
	'lup.learner_resources.search_resources' = SearchOptions.RESOURCES
}

/**
 * Not found keys based on Smart Options chosen
 */
export enum NotFoundKeys {
	'lup.global_search.not_found.courses' = SearchOptions.COURSES,
	'lup.global_search.not_found.users' = SearchOptions.USERS,
	'lup.global_search.not_found.groups' = SearchOptions.GROUPS,
	'lup.global_search.not_found.learning_paths' = SearchOptions.LEARNING_PATHS,
	'lup.global_search.not_found.catalog' = SearchOptions.CATALOG,
	'lup.global_search.not_found.resources' = SearchOptions.RESOURCES
}

/**
 * Smart Search Component config
 */
export interface AngieSmartSearchConfig {
	/**
	 * API URL where we send global search request to
	 */
	apiUrl: string;
	/**
	 * Is Opened flag
	 */
	isOpened?: boolean;
	/**
	 * Minimum Chart Required to perform search
	 */
	minimumCharsRequired?: number;
}

/**
 * Smart Search Grid Tiles based on Search Options
 */
export enum AngieSmartSearchGridTile {
	CoursesTileComponent = SearchOptions.COURSES,
	UserTileComponent = SearchOptions.USERS,
	GroupsTileComponent = SearchOptions.GROUPS,
	LearningPathTileComponent = SearchOptions.LEARNING_PATHS,
	CatalogTileComponent = SearchOptions.CATALOG,
	ResourcesTileComponent = SearchOptions.RESOURCES
}

/**
 * Tile Component to render based on Search Options
 */
export const angieSmartSearchGridTileComponents = {
	CoursesTileComponent: CoursesTileComponent,
	UserTileComponent: UserTileComponent,
	GroupsTileComponent: GroupsTileComponent,
	LearningPathTileComponent: LearningPathTileComponent,
	CatalogTileComponent: CatalogTileComponent,
	ResourcesTileComponent: ResourcesTileComponent
};
