import { Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { DrawerService } from '../../../../../core/services/drawer.service';
@Component({
	template: ''
})
export class DrawerBaseComponent<T = any> implements OnDestroy {
	private _data: T;
	emitter$ = new BehaviorSubject<any>(null);
	destroy$: Subject<void> = new Subject<void>();

	constructor(public drawerService: DrawerService) {}

	set data(data: T) {
		if (!data) return;
		this._data = data;
		this.drawerService.setDrawerData<T>(data);
	}

	get data(): T {
		return this._data;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
		this.emitter$.complete();
	}
}
