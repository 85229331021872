import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { angieRoutePrefix } from 'src/app/globals';
import { WindowRefService } from '../../../../../core';
import { BaseComponent } from '../../../base/base.component';
import { ResourcesDescriptionModalComponent } from '../../../modals/resources-description-modal/resources-description-modal.component';
import { LuResourcesTile, TileConfig } from '../../models/tiles.models';

/**
 * Resources Tile Component - renders results fetched from global smart search for resources searches
 */

@Component({
	templateUrl: './resources-tile.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourcesTileComponent extends BaseComponent implements TileConfig<LuResourcesTile> {
	/**
	 * Data that is passed in for each tile
	 */
	data: LuResourcesTile;
	/**
	 * If we preview(download) resource - open up modal
	 */
	resourcesDescriptionRef: BsModalRef;
	/**
	 * Variable for route used in template, for each resource tile with download anchor tag
	 */
	angieRoutePrefix = angieRoutePrefix;

	/**
	 * Constructor
	 */
	constructor(
		private readonly bsModalService: BsModalService,
		private readonly translateService: TranslateService,
		private readonly windowRefService: WindowRefService
	) {
		super();
	}

	/**
	 * Pass in data for view description of the resource - opens up modal
	 */
	viewDescription(): void {
		const nullStateDescription = this.data.file_size
			? 'lup.learner_view.no_description_blurb'
			: 'lup.learner_view.no_description_no_down_blurb';
		this.resourcesDescriptionRef = this.bsModalService.show(ResourcesDescriptionModalComponent, {
			initialState: {
				resource: this.data,
				nullStateOptions: {
					title: this.translateService.instant('lup.learner_view.no_description'),
					icon: 'folder-open',
					text: this.translateService.instant(nullStateDescription)
				},
				modalOptions: {
					title: this.data.title,
					primaryActions: [
						{
							label: this.translateService.instant('course_assignments.download'),
							icon: 'download btn__ico',
							class: 'btn--theme',
							onClick: () => {
								const downloadTab = this.windowRefService
									.getWindow()
									.open(`${angieRoutePrefix}/learner_resources/${this.data.id}/download`, '_blank');
								if (downloadTab) {
									downloadTab.focus();
								}
							}
						},
						{
							label: this.translateService.instant('close'),
							onClick: () => {
								this.resourcesDescriptionRef.hide();
							}
						}
					]
				}
			}
		});
	}
}
