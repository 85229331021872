export function makeFile(file: File | Blob, fileName: string): File {
	try {
		return new File([file], fileName);
	} catch {
		// file constructor does not work in IE 11 and no polyfills for this one
		const fileAsBlob = new Blob([file]) as any;
		fileAsBlob.name = fileName;
		fileAsBlob.lastModifiedDate = new Date();
		return <File>fileAsBlob;
	}
}

export function objectToBase64String(object: any): string {
	if (!object) return;
	try {
		return btoa(JSON.stringify(object));
	} catch (err) {
		return;
	}
}

export function base64StringToObject<T = any>(base64String: string): T {
	if (!base64String) return;
	try {
		return JSON.parse(atob(base64String));
	} catch (err) {
		return;
	}
}
