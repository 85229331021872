import { PageMeta } from '../insights/insights.models';

export const LEARNING_JOURNEY_LIST_PAGE_META: Record<string, PageMeta> = {
	'^/learning-journey$': {
		pagegroup: 'learning-journey',
		pagename: 'list'
	}
};

export const LEARNING_JOURNEY_EDIT_PAGE_META: Record<string, PageMeta> = {
	'^/learning-journey/.+/.+/edit': {
		pagegroup: 'learning-journey',
		pagename: 'edit'
	}
};

export const LEARNING_JOURNEY_ENROLLMENTS_PAGE_META: Record<string, PageMeta> = {
	'^/learning-journey/.+/.+/enrollments': {
		pagegroup: 'learning-journey',
		pagename: 'learner-activity'
	}
};
