import { angieRoutePrefix } from 'src/app/globals';

export enum CatalogRoutesConfig {
	CATALOG_BASE_URL = 'catalog',
	CATALOG_COURSES_URL = 'catalog/courses',
	CATALOG_CATEGORIES_URL = '/catalog/categories.json',
	CATALOG_ENROLL_URL = '/catalog/enroll.json',
	CATALOG_REQUEST_ENROLL_URL = '/catalog/request_enroll.json',
	CATALOG_RECOMMENDED_COURSES_URL = '/catalog/recommended_courses.json',
	CATALOG_MONTLY_AVAILABLE_SESSIONS_URL = '/catalog/calendar/monthly_available_sessions.json',
	CATALOG_TRAINING_SESSIONS_URL = '/catalog/calendar/training_sessions.json',
	CATALOG_REGISTER_SESSION_URL = '/catalog/calendar/register_to_session.json',
	CATALOG_CANCEL_SESSION_URL = '/catalog/calendar/cancel_registration.json',
	CATALOG_JOIN_WAITLIST_URL = '/catalog/calendar/join-waitlist.json',
	CATALOG_LEAVE_WAITLIST_URL = '/catalog/calendar/leave-waitlist.json'
}

export class CatalogRoutes {
	public static CATALOG_LIST = angieRoutePrefix.concat(`/${CatalogRoutesConfig.CATALOG_BASE_URL}/content_data.json`);
	public static CATALOG_CATEGORIES = angieRoutePrefix.concat(CatalogRoutesConfig.CATALOG_CATEGORIES_URL);
	public static CATALOG_ENROLL = angieRoutePrefix.concat(CatalogRoutesConfig.CATALOG_ENROLL_URL);
	public static CATALOG_REQUEST_ENROLL = angieRoutePrefix.concat(CatalogRoutesConfig.CATALOG_REQUEST_ENROLL_URL);
	public static CATALOG_BANNERS = angieRoutePrefix.concat(`/${CatalogRoutesConfig.CATALOG_BASE_URL}/banners.json`);
	public static CATALOG_METADATA = angieRoutePrefix.concat(`/${CatalogRoutesConfig.CATALOG_BASE_URL}/metadata.json`);
	public static CATALOG_RECOMMENDED_COURSES = angieRoutePrefix.concat(
		CatalogRoutesConfig.CATALOG_RECOMMENDED_COURSES_URL
	);
	public static CATALOG_MONTLY_AVAILABLE_SESSIONS_URL = angieRoutePrefix.concat(
		CatalogRoutesConfig.CATALOG_MONTLY_AVAILABLE_SESSIONS_URL
	);
	public static CATALOG_TRAINING_SESSIONS = angieRoutePrefix.concat(CatalogRoutesConfig.CATALOG_TRAINING_SESSIONS_URL);
	public static CATALOG_REGISTER_SESSION = angieRoutePrefix.concat(CatalogRoutesConfig.CATALOG_REGISTER_SESSION_URL);
	public static CATALOG_CANCEL_SESSION = angieRoutePrefix.concat(CatalogRoutesConfig.CATALOG_CANCEL_SESSION_URL);
	public static CATALOG_JOIN_WAITLIST = angieRoutePrefix.concat(CatalogRoutesConfig.CATALOG_JOIN_WAITLIST_URL);
	public static CATALOG_LEAVE_WAITLIST = angieRoutePrefix.concat(CatalogRoutesConfig.CATALOG_LEAVE_WAITLIST_URL);
}
