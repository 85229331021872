import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

/**
 * DocumentRefService - Wrapper around Document with additional methods
 *
 * Helper Service that would manipulate with document
 */
@Injectable({
	providedIn: 'root'
})
export class DocumentRefService {
	/**
	 * Constructor
	 */
	constructor(@Inject(DOCUMENT) public readonly document: Document) {}

	/**
	 * Add class to document.body
	 */
	addBodyClass(className: string = 'modal-open'): void {
		this.document.body.classList.add(className);
	}

	/**
	 * Remove class from document.body
	 */
	removeBodyClass(className: string = 'modal-open'): void {
		this.document.body.classList.remove(className);
	}

	getBodyRef(): HTMLElement {
		return this.document.body;
	}
}
