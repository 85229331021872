<div class="crd crd--list crd--no-effect">
	<div class="crd__bd">
		<div class="o-flex3 o-flex3--ellipsis-cntr">
				<a class="ellipsis" (click)="viewDescription()" [attr.aria-label]="('lup.a11y.resources_title' | translate) + ' ' + data.title">
					{{ data.title }}
				</a>
		</div>
		<div class="o-flex1 text-right" *ngIf="data.file_size">
			<a class="gs-result__act" [href]="angieRoutePrefix + '/learner_resources/' + data.id + '/download'" target="_blank" [attr.aria-label]="'course_assignments.download' | translate">
				<lu-icon [type]="LuIconType.SOLID" icon="download gray-icon"></lu-icon>
				<span class="hidden-xs"> {{ 'course_assignments.download' | translate }}</span>
			</a>
		</div>
	</div>
</div>
