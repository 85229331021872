import { Injectable } from '@angular/core';
import { ScrollService as SharedScrollService } from 'lu-services';

/**
 * Scroll service - inherits SharedScrollService (lu-services)
 *
 * Core service that we use to inject to our libs to use this reference instead of having multiple instances
 */
@Injectable({
	providedIn: 'root'
})
export class ScrollService extends SharedScrollService {
	/**
	 * Constructor
	 */
	constructor() {
		super();
	}
}
