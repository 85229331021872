import { Directive, ElementRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';
import { LayoutType } from 'src/app/core';
import { BaseComponent } from '../../../angie-shared/components/base/base.component';

@Directive({
	selector: '[angieAppLayoutType]'
})
export class LayoutTypeDirective extends BaseComponent {
	layoutBaseClass = 'middle blur';

	constructor(
		private readonly element: ElementRef,
		private readonly activatedRoute: ActivatedRoute,
		private readonly router: Router
	) {
		super();
		this.router.events
			.pipe(
				takeUntil(this.destroy$),
				filter(event => event instanceof NavigationEnd),
				map(() => {
					let child = this.activatedRoute.firstChild;
					while (child) {
						if (child.firstChild) {
							child = child.firstChild;
						} else if (child.snapshot.data) {
							return child.snapshot.data.layout;
						}
					}
					return null;
				})
			)
			.subscribe((layout: LayoutType) => {
				this.setClassToLayout(layout);
			});
	}

	setClassToLayout(layout: LayoutType): void {
		if (!!layout) {
			this.element.nativeElement.className = `${this.layoutBaseClass} ${layout}`;
		} else if (layout === LayoutType.BASE) {
			this.element.nativeElement.className = `${this.layoutBaseClass}`;
		} else {
			this.element.nativeElement.className = `${this.layoutBaseClass} ${LayoutType.DEFAULT}`;
		}
	}
}
