<div id="mainHeader" class="lu-container-fluid mainheader mainheader--admin mainheader-fixed-top" [ngClass]="{'mainheader--learner': isLearner || isInLearnerView}">
	<div class="lu-row">

		<div class="col blur" [ngClass]="isLearner || isInLearnerView ? 'o-flex xs-6 sm-6 md-8 lg-8' : 'col xs-2 sm-2 md-6 lg-6'">
			<angie-logo [altTag]="headerImage?.alt_tag" [brandLearner]="isLearner || isInLearnerView" [link]="isInLearnerView ? AngieAppRoutes.LEARNER_DASHBOARD : AngieAppRoutes.DASHBOARD"></angie-logo>
			<angie-primary-learner-nav *ngIf="isLearner || isInLearnerView" class="ml-24"></angie-primary-learner-nav>
		</div>

		<nav class="col info-wrap blur" [ngClass]="isLearner || isInLearnerView ? 'xs-6 sm-6 md-4 lg-4' : 'xs-10 sm-10 md-6 lg-6'"
				[attr.aria-label]="'lup.a11y.status_and_settings' | translate">
			<ul class="list-inline status-settings" role="list">
				<li role="listitem">
					<button type="button" class="btn-clear header-btn def-focus admin-search-btn" (click)="openSmartSearch()"
									[attr.aria-label]="'lup.a11y.global' | translate">
									<lu-icon class="icon-large header-color-icon" icon="lu-search" customClass="header-color-icon"></lu-icon>
									<span class="hdn-to-sm">{{ 'search' | translate }}</span>
					</button>
				</li>
				<li role="listitem" *ngIf="(isAdmin && !isInLearnerView) || isTaskTracerEnabled">
					<button type="button" class="btn-clear header-btn def-focus"
									[attr.aria-label]="'lup.task_tracker.navigation_action'"
									[tooltip]="'lup.task_tracker.navigation_action' | translate" [tooltipAnimation]="false"
									[routerLink]="['/task-tracking']">
									<lu-icon class="icon-large" icon="lu-action-tracker" customClass="header-color-icon"></lu-icon>
					</button>
				</li>
				<li role="listitem" *ngIf="!portalMeta.disable_messaging">
					<angie-inbox></angie-inbox>
				</li>
				<li role="listitem" *ngIf="portalMeta.show_portal_jumper">
					<angie-my-portals></angie-my-portals>
				</li>
				<li role="listitem">
					<angie-user-menu [userMenu]="userMenu"></angie-user-menu>
				</li>
			</ul>
		</nav>

		<section id="search-wrap" class="search-wrap search-wrap--admin"
						[ngClass]="{'is-active': smartSearchOptions.isOpened, 'hidden': !smartSearchOptions.isOpened}">
			<angie-smart-search [options]="smartSearchOptions" (stateChange)="onSmartSearchStateChange($event)">
			</angie-smart-search>
		</section>
	</div>
</div>
