<ng-container *ngIf="actions$ | async as actions">
	<button type="button" *ngIf="actions.length && actions.length === 1" class="btn btn--theme btn--actions-nav btn--ico-sm" (click)="$event.stopPropagation(); actions[0].onClick()">
		<lu-icon class="btn__ico" [type]="LuIconType.SOLID" [icon]="actions[0].iconClass"></lu-icon><span class="btn__txt">{{ actions[0].label | translate }}</span>
	</button>
	<nav *ngIf="actions.length > 1" dropdown [attr.aria-label]="'dashboard.actions' | translate" (isOpenChange)="toggleAdminNavZIndexOrder($event)" >
		<div class="overlay"></div>
		<button type="button" class="btn btn--theme btn--actions-nav dropdown-toggle" dropdownToggle>
			<lu-icon class="btn__ico" [type]="LuIconType.SOLID" icon="ellipsis-v"></lu-icon>
			<span class="btn__txt">{{ 'dashboard.actions' | translate }}</span>
		</button>
		<ul class="action-nav dropdown-menu dropdown-menu--action-nav list-unstyled dropdown-menu-right" *dropdownMenu role="menu">
			<li *ngFor="let action of actions" role="none" class="action-nav__item">
				<a href="javascript:void(0);" class="action-nav__link" (click)="action.onClick($event)" role="menuitem">
					<lu-icon [type]="LuIconType.SOLID" [icon]="action.iconClass" class="action-nav__ico"></lu-icon>{{ action.label | translate }}
				</a>
			</li>
		</ul>
	</nav>
</ng-container>
