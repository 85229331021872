import { ButtonsHeaderWrapperOptions } from 'src/app/angie-shared/components/angie-buttons-header-wrapper/angie-buttons-header-wrapper.models';
import { RouterTab } from 'src/app/angie-shared/components/router-tabs/router-tabs.models';
import { CustomUserData, CustomUserDataFieldType } from 'src/app/core/core.models';
import { environment } from 'src/environments/environment';

//Supported providers
export enum IntegrationType {
	CONTENT = 1,
	HR = 2,
	CRM = 3,
	NOTIFICATION = 5
}

export interface IntegrationProvider {
	code: ProviderCode;
	name: string;
	type: IntegrationType;
}

export enum ProviderCode {
	GO1 = 'Go1',
	OPEN_SESAME = 'OpenSesame',
	PERSONIO = 'Personio',
	BAMBOOHR = 'Bamboohr',
	HUBSPOT = 'HubSpot',
	SLACK_NOTIFICATIONS = 'slack',
	LINKEDIN_LEARNING = 'linkedin-learning',
	EASY_GENERATOR = 'easygenerator',
	HIBOB = 'Hibob',
	WORKDAY = 'Workday',
	UKG_PRO = 'Ukg_pro',
	ADP = 'Adp',
	SAP_SUCCESSFACTORS = 'Sap_successfactors',
	DEEL = 'Deel',
	PAYCOR = 'Paycor',
	DAYFORCE = 'Dayforce',
	GUSTO = 'Gusto',
	PAYLOCITY = 'Paylocity'
}

export const ProviderCodesFromMerge: Array<ProviderCode> = [
	ProviderCode.HIBOB,
	ProviderCode.WORKDAY,
	ProviderCode.UKG_PRO,
	ProviderCode.ADP,
	ProviderCode.SAP_SUCCESSFACTORS,
	ProviderCode.DEEL,
	ProviderCode.PAYCOR,
	ProviderCode.DAYFORCE,
	ProviderCode.GUSTO,
	ProviderCode.PAYLOCITY
];

export interface IntegrationProviderConfig {
	param: string;
}

export const ProviderConfig: { [key in ProviderCode]: IntegrationProviderConfig } = {
	[ProviderCode.GO1]: {
		param: 'go1'
	},
	[ProviderCode.OPEN_SESAME]: {
		param: 'opensesame'
	},
	[ProviderCode.PERSONIO]: {
		param: 'personio'
	},
	[ProviderCode.BAMBOOHR]: {
		param: 'bamboohr'
	},
	[ProviderCode.HUBSPOT]: {
		param: 'hubspot'
	},
	[ProviderCode.LINKEDIN_LEARNING]: {
		param: 'linkedin-learning'
	},
	[ProviderCode.EASY_GENERATOR]: {
		param: 'easygenerator'
	},
	[ProviderCode.SLACK_NOTIFICATIONS]: {
		param: 'slack'
	},
	[ProviderCode.HIBOB]: {
		param: 'hibob'
	},
	[ProviderCode.WORKDAY]: {
		param: 'workday'
	},
	[ProviderCode.UKG_PRO]: {
		param: 'ukg_pro'
	},
	[ProviderCode.ADP]: {
		param: 'adp'
	},
	[ProviderCode.SAP_SUCCESSFACTORS]: {
		param: 'sap_successfactors'
	},
	[ProviderCode.DEEL]: {
		param: 'deel'
	},
	[ProviderCode.PAYCOR]: {
		param: 'paycor'
	},
	[ProviderCode.DAYFORCE]: {
		param: 'dayforce'
	},
	[ProviderCode.GUSTO]: {
		param: 'gusto'
	},
	[ProviderCode.PAYLOCITY]: {
		param: 'paylocity'
	}
};

export const Providers: { [key in ProviderCode]: IntegrationProvider } = {
	[ProviderCode.GO1]: {
		code: ProviderCode.GO1,
		name: 'Go1',
		type: IntegrationType.CONTENT
	},
	[ProviderCode.OPEN_SESAME]: {
		code: ProviderCode.OPEN_SESAME,
		name: 'OpenSesame',
		type: IntegrationType.CONTENT
	},
	[ProviderCode.PERSONIO]: {
		code: ProviderCode.PERSONIO,
		name: 'Personio',
		type: IntegrationType.HR
	},
	[ProviderCode.BAMBOOHR]: {
		code: ProviderCode.BAMBOOHR,
		name: 'BambooHR',
		type: IntegrationType.HR
	},
	[ProviderCode.HUBSPOT]: {
		code: ProviderCode.HUBSPOT,
		name: 'HubSpot',
		type: IntegrationType.CRM
	},
	[ProviderCode.LINKEDIN_LEARNING]: {
		code: ProviderCode.LINKEDIN_LEARNING,
		name: 'LinkedIn Learning',
		type: IntegrationType.CONTENT
	},
	[ProviderCode.EASY_GENERATOR]: {
		code: ProviderCode.EASY_GENERATOR,
		name: 'Easy Generator',
		type: IntegrationType.CONTENT
	},
	[ProviderCode.SLACK_NOTIFICATIONS]: {
		code: ProviderCode.SLACK_NOTIFICATIONS,
		name: 'Slack',
		type: IntegrationType.NOTIFICATION
	},
	[ProviderCode.HIBOB]: {
		code: ProviderCode.HIBOB,
		name: 'HiBob',
		type: IntegrationType.HR
	},
	[ProviderCode.WORKDAY]: {
		code: ProviderCode.WORKDAY,
		name: 'Workday',
		type: IntegrationType.HR
	},
	[ProviderCode.UKG_PRO]: {
		code: ProviderCode.UKG_PRO,
		name: 'UKG Pro',
		type: IntegrationType.HR
	},
	[ProviderCode.ADP]: {
		code: ProviderCode.ADP,
		name: 'ADP Workforce Now',
		type: IntegrationType.HR
	},
	[ProviderCode.SAP_SUCCESSFACTORS]: {
		code: ProviderCode.SAP_SUCCESSFACTORS,
		name: 'SAP SuccessFactors',
		type: IntegrationType.HR
	},
	[ProviderCode.DEEL]: {
		code: ProviderCode.DEEL,
		name: 'Deel',
		type: IntegrationType.HR
	},
	[ProviderCode.PAYCOR]: {
		code: ProviderCode.PAYCOR,
		name: 'Paycor',
		type: IntegrationType.HR
	},
	[ProviderCode.DAYFORCE]: {
		code: ProviderCode.DAYFORCE,
		name: 'Dayforce',
		type: IntegrationType.HR
	},
	[ProviderCode.GUSTO]: {
		code: ProviderCode.GUSTO,
		name: 'Gusto',
		type: IntegrationType.HR
	},
	[ProviderCode.PAYLOCITY]: {
		code: ProviderCode.PAYLOCITY,
		name: 'Paylocity',
		type: IntegrationType.HR
	}
};

export const ContentProviderKeys = {
	[ProviderCode.GO1]: {
		key: 'go1'
	},
	[ProviderCode.LINKEDIN_LEARNING]: {
		key: 'linkedin_learning'
	},
	[ProviderCode.EASY_GENERATOR]: {
		key: 'easygenerator'
	}
};

export interface IntegrationDto {
	id: number;
	name: string;
	active: boolean;
	connection_url?: string;
	initialSyncComplete: boolean;
}

export interface Integration {
	id: number;
	name: string;
	provider: IntegrationProvider;
	active: boolean;
	providerImg: string;
	connectionUrl?: string;
	altTag: string;
	integrationInfo: string;
	activationSteps: string;
	additionalDescription: string;
	initialSyncComplete: boolean;
	additionalData?: CrmAdditionalData; // prop to send specific integration data, eg: CrmAdditionalData | SomeOtherIntegrationAdditionalData
}

export interface CrmAdditionalData {
	isHubspotEnterprise: boolean;
}

export interface IIntegrationIframe {
	id: number;
	url: string;
	validTime: number;
}

export interface AdditionalSettings {
	integration_name: string;
	user_type: string;
	oauth_server_url: string;
	tenant_server_url: string;
	client_id: string;
	client_secret: string;
}
export enum MappingKeys {
	CUD = 'cud',
	ATTRIBUTE = 'attribute',
	MAPPINGS = 'mappings'
}

export interface CudType {
	type: CustomUserDataFieldType;
	options: LuCudMap[];
}
export interface IntegrationAttribute {
	cud_attr: CudAttribute;
	current_map?: LuCudMap;
	cud_type: CustomUserDataFieldType[];
	lu_available_options: LuCudMap[];
}

export interface IntegrationMappingDto {
	[MappingKeys.MAPPINGS]: AttributeMapping[];
}

export interface SaveMappingDto {
	integration_attribute: { key: string; label: string };
	lu_cud: { id: number };
}

export interface AttributeMapping {
	[MappingKeys.ATTRIBUTE]: CudAttribute;
	[MappingKeys.CUD]: CustomUserData;
}

// Rename to something more generic
export interface CudAttribute {
	key: string;
	label: string;
	allowed_types: number[];
}

export interface LuCudMap {
	id: number;
	label: string;
	valid: boolean;
}

export const IntegrationProviders = {
	[Providers[ProviderCode.GO1].code]: {
		imageUrl: `${environment.cdnBase}/assets/images/integrations/go1.svg`,
		info: 'lup.integrations.content_integrations.providers.go1.activated_session',
		activationSteps: 'lup.integrations.content_integrations.providers.go1.steps',
		additionalDescription: 'lup.integrations.content_integrations.providers.go1.description'
	},
	[Providers[ProviderCode.OPEN_SESAME].code]: {
		imageUrl: `${environment.cdnBase}/assets/images/integrations/open-sesame.svg`,
		info: 'lup.integrations.content_integrations.providers.open_sesame.activated_session',
		activationSteps: 'lup.integrations.content_integrations.providers.open_sesame.steps',
		additionalDescription: 'lup.integrations.content_integrations.providers.open_sesame.description'
	},
	[Providers[ProviderCode.PERSONIO].code]: {
		imageUrl: `${environment.cdnBase}/assets/images/integrations/personio-logo.svg`,
		info: 'lup.integrations.hr_integrations.personio.description',
		activationSteps: 'lup.integrations.hr_integrations.personio.activation_steps',
		additionalDescription: ''
	},
	[Providers[ProviderCode.BAMBOOHR].code]: {
		imageUrl: `${environment.cdnBase}/assets/images/integrations/bamboohr-logo.svg`,
		info: 'lup.integrations.hr_integrations.bamboohr.description',
		activationSteps: 'lup.integrations.hr_integrations.bamboohr.activation_steps',
		additionalDescription: ''
	},
	[Providers[ProviderCode.HUBSPOT].code]: {
		imageUrl: `${environment.cdnBase}/assets/images/integrations/hubspot-logo.svg`,
		info: 'lup.integrations.crm_integrations.hubspot.description',
		activationSteps: 'lup.integrations.crm_integrations.hubspot.activation_steps',
		additionalDescription: ''
	},
	[Providers[ProviderCode.SLACK_NOTIFICATIONS].code]: {
		imageUrl: `${environment.cdnBase}/assets/images/integrations/slack.svg`,
		info: 'lup.integrations.notification_integrations.slack.description',
		activationSteps: 'lup.integrations.notification_integrations.slack.activation_steps'
	},
	[Providers[ProviderCode.LINKEDIN_LEARNING].code]: {
		imageUrl: `${environment.cdnBase}/assets/images/integrations/linkedin-learning-logo.svg`,
		info: 'lup.integrations.content_integrations.linkedin_learning.description',
		activationSteps: 'lup.integrations.content_integrations.linkedin_learning.activation_steps',
		additionalDescription: 'lup.integrations.content_integrations.linkedin_learning.additional_description'
	},
	[Providers[ProviderCode.EASY_GENERATOR].code]: {
		imageUrl: `${environment.cdnBase}/assets/images/integrations/easygenerator-logo.svg`,
		info: 'lup.integrations.content_integrations.easygenerator.description',
		activationSteps: '',
		additionalDescription: 'lup.integrations.content_integrations.easygenerator.additional_description'
	},
	[Providers[ProviderCode.HIBOB].code]: {
		imageUrl: `${environment.cdnBase}/assets/images/integrations/hibob.svg`,
		info: 'lup.integrations.hr_integrations.hibob.description',
		activationSteps: 'lup.integrations.hr_integrations.hibob.activation_steps',
		additionalDescription: ''
	},
	[Providers[ProviderCode.WORKDAY].code]: {
		imageUrl: `${environment.cdnBase}/assets/images/integrations/workday.svg`,
		info: 'lup.integrations.hr_integrations.workday.description',
		activationSteps: 'lup.integrations.hr_integrations.workday.activation_steps',
		additionalDescription: ''
	},
	[Providers[ProviderCode.UKG_PRO].code]: {
		imageUrl: `${environment.cdnBase}/assets/images/integrations/ukg_pro.svg`,
		info: 'lup.integrations.hr_integrations.ukg_pro.description',
		activationSteps: 'lup.integrations.hr_integrations.ukg_pro.activation_steps',
		additionalDescription: ''
	},
	[Providers[ProviderCode.ADP].code]: {
		imageUrl: `${environment.cdnBase}/assets/images/integrations/adp.svg`,
		info: 'lup.integrations.hr_integrations.adp.description',
		activationSteps: 'lup.integrations.hr_integrations.adp.activation_steps',
		additionalDescription: ''
	},
	[Providers[ProviderCode.SAP_SUCCESSFACTORS].code]: {
		imageUrl: `${environment.cdnBase}/assets/images/integrations/sap_successfactors.svg`,
		info: 'lup.integrations.hr_integrations.sap_successfactors.description',
		activationSteps: 'lup.integrations.hr_integrations.sap_successfactors.activation_steps',
		additionalDescription: ''
	},
	[Providers[ProviderCode.DEEL].code]: {
		imageUrl: `${environment.cdnBase}/assets/images/integrations/deel.svg`,
		info: 'lup.integrations.hr_integrations.deel.description',
		activationSteps: 'lup.integrations.hr_integrations.deel.activation_steps',
		additionalDescription: ''
	},
	[Providers[ProviderCode.PAYCOR].code]: {
		imageUrl: `${environment.cdnBase}/assets/images/integrations/paycor.svg`,
		info: 'lup.integrations.hr_integrations.paycor.description',
		activationSteps: 'lup.integrations.hr_integrations.paycor.activation_steps',
		additionalDescription: ''
	},
	[Providers[ProviderCode.DAYFORCE].code]: {
		imageUrl: `${environment.cdnBase}/assets/images/integrations/dayforce.svg`,
		info: 'lup.integrations.hr_integrations.dayforce.description',
		activationSteps: 'lup.integrations.hr_integrations.dayforce.activation_steps',
		additionalDescription: ''
	},
	[Providers[ProviderCode.GUSTO].code]: {
		imageUrl: `${environment.cdnBase}/assets/images/integrations/gusto.svg`,
		info: 'lup.integrations.hr_integrations.gusto.description',
		activationSteps: 'lup.integrations.hr_integrations.gusto.activation_steps',
		additionalDescription: ''
	},
	[Providers[ProviderCode.PAYLOCITY].code]: {
		imageUrl: `${environment.cdnBase}/assets/images/integrations/paylocity.svg`,
		info: 'lup.integrations.hr_integrations.paylocity.description',
		activationSteps: 'lup.integrations.hr_integrations.paylocity.activation_steps',
		additionalDescription: ''
	}
};

export interface IntegrationsGridDto {
	data: IntegrationGridData[];
	filters: IntegrationGridFilter[];
	has_next: boolean;
	page: number;
	count: number;
}

export interface IntegrationGridData {
	id: number;
	title: string;
	image: string;
	imported: boolean;
	type: string;
	level: string;
	duration: number;
	language: string;
	provider: string;
	description: string;
	previewable: boolean;
	collection: boolean;
}

export interface IntegrationGridFilter {
	key: string;
	options: IntegrationsGridFilterOption[];
}

export interface IntegrationsGridFilterOption {
	name: string;
	key: number;
	count: number;
}

export enum SortOptions {
	POPULARITY = 'popularity',
	LATEST = 'latest',
	MOST_RELEVANT = 'mostrelevant'
}

export interface IntegrationConfig {
	tabConfig: () => RouterTab[];
	headerButtons: () => ButtonsHeaderWrapperOptions;
	headerOptions: {
		title: string;
	};
}

export enum WhoToInviteOptions {
	ALL_EMPLOYEES = 1,
	CERTAIN_EMPLOYEES = 2
}

export enum WhenToInviteOptions {
	ADDED = 1,
	HIRE_DATE = 2
}

export enum HowToCreateUserOptions {
	INVITE = 1,
	CREATE = 2,
	CREATE_WITHOUT_EMAIL = 3
}

export interface PersonioAdditionalConfig {
	who_to_invite: number;
	when_to_invite: number;
	report_id: string;
}
export interface HrIntegrationInviteConfig {
	skipWhoToInvite: boolean;
	getInviteConfigUrl: string;
	setInviteConfigUrl: string;
	translationStringWhenToInviteAdded?: string;
	translationStringOnlyCertainEmployees?: string;
}

export interface AdditionalPensConfig {
	clearSuspendData: boolean;
	createDraftCourse: boolean;
}
/**
 * Object used to set browser tab name based on integration type
 */
export const IntegrationPageTitles = {
	[IntegrationType.CONTENT]: 'lup.integrations.content_integrations.title',
	[IntegrationType.HR]: 'lup.integrations.hr_integrations.title',
	[IntegrationType.CRM]: 'lup.integrations.crm_integrations.title',
	[IntegrationType.NOTIFICATION]: 'lup.integrations.notification_integrations.title'
};

export interface EasyGeneratorCredentials {
	app_id: string;
	pens_key: string;
	pens_url: string;
	library_name: string;
}
