import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StateService } from '..';

/**
 * Interceptor for XSRF
 *
 * We intercept request and add "x-xsrf-token" to header (read from state service)
 */
@Injectable()
export class XsrfInterceptor implements HttpInterceptor {
	/**
	 * Constructor
	 */
	constructor(private readonly stateService: StateService) {}

	/**
	 * Intercept - implemented method from HttpInterceptor
	 */
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (request.method !== 'GET') {
			request = request.clone({
				headers: request.headers.set('x-xsrf-token', this.stateService.xsrfToken)
			});
		}

		return next.handle(request);
	}
}
