import { NgModuleRef, Type } from '@angular/core';
import { HeaderConfig } from '../../../angie-shared/components/angie-drawer-header/angie-drawer-header.model';
import { ActionBarConfig } from '../../../angie-shared/components/angie-form-section/angie-action-bar/angie-action-bar.model';
import { DrawerBaseComponent } from './components/drawer-base/drawer-base.component';

export enum DrawerSize {
	DEFAULT,
	LARGE
}

export enum DrawerType {
	ALWAYS_OPEN,
	COLLAPSABLE
}

export enum DrawerPosition {
	START = 'start',
	END = 'end'
}

export enum DrawerMode {
	OVER = 'over',
	PUSH = 'push',
	SIDE = 'side'
}

export interface DrawerConfig<T = any, K = any> {
	size?: DrawerSize;
	header?: HeaderConfig;
	actionBar?: ActionBarConfig;
	hideDefaultHeader?: boolean;
	content: Type<K>;
	data?: T;
	ngModuleRef?: NgModuleRef<any>;
	type?: DrawerType;
	mode?: DrawerMode;
	position?: DrawerPosition;
}

export type DrawerContent<T = any> = T & DrawerBaseComponent;
