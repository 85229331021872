import { Pipe, PipeTransform } from '@angular/core';
import { LuUserRoleCode } from '../../../core';

@Pipe({
	name: 'roleCodeToLabel'
})
export class RoleCodeToLabelPipe implements PipeTransform {
	transform(roleCode: number): string {
		return {
			[LuUserRoleCode.MEMBER]: 'learner',
			[LuUserRoleCode.ADMIN]: 'admin',
			[LuUserRoleCode.INSTRUCTOR]: 'instructor',
			[LuUserRoleCode.MANAGER]: 'manager'
		}[roleCode];
	}
}
