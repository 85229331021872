import { Component } from '@angular/core';
import { Event } from '@angular/router';
import { FieldType } from '@ngx-formly/core';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'lu-button-formly',
	templateUrl: 'lu-button-formly.component.html'
})
export class LuButtonFormlyComponent extends FieldType {
	constructor() {
		super();
	}

	onClick($event: Event): void {
		if (this.to.buttonOptions.onClick) {
			this.to.buttonOptions.onClick($event, this.model, this.form);
		}
	}
}
