import { AngieAppRoutes } from 'src/app/angie-app.routes';
import { LearnerNavigationItem } from 'src/app/core';

export const learnerNavigation: LearnerNavigationItem[] = [
	{
		iconClass: 'lu-dashboard',
		label: 'lup.tabs.dashboard',
		url: `/${AngieAppRoutes.DASHBOARD}`,
		external: true,
		permissionCheck: ({ userService, stateService }) => {
			const isLearner = userService.isLearner();
			const everyoneCanAccess =
				userService.isAdmin() || userService.isManager() || userService.isInstructor() || isLearner;
			const learnerWithDashboardEnabled = isLearner && stateService?.portalMeta?.learner_dashboard_enabled;
			return !(learnerWithDashboardEnabled || stateService.isInLearnerView()) && everyoneCanAccess;
		}
	},
	{
		iconClass: 'lu-home',
		label: 'lup.learner_dashboard_nav_title_home',
		url: `/${AngieAppRoutes.LEARNER_DASHBOARD}`,
		external: true,
		permissionCheck: ({ userService, stateService }) => {
			const isLearner = userService.isLearner();
			const everyoneCanAccess =
				userService.isAdmin() || userService.isManager() || userService.isInstructor() || isLearner;
			const learnerWithDashboardEnabled = isLearner && stateService?.portalMeta?.learner_dashboard_enabled;
			return (learnerWithDashboardEnabled || stateService.isInLearnerView()) && everyoneCanAccess;
		}
	},
	{
		iconClass: 'lu-courses',
		label: 'lup.my_learning_title',
		url: `/${AngieAppRoutes.MY_LEARNING}`,
		external: true,
		permissionCheck: ({ userService, stateService }) => {
			const everyoneCanAccess =
				userService.isAdmin() || userService.isManager() || userService.isInstructor() || userService.isLearner();
			return stateService.portalMeta.my_learning_enabled && everyoneCanAccess;
		}
	},
	{
		iconClass: 'lu-courses',
		label: 'lup.subnav.lup_dashboard',
		url: `/${AngieAppRoutes.DASHBOARD}`,
		external: true,
		permissionCheck: ({ userService, stateService }) => {
			const { learner_dashboard_enabled, my_learning_enabled } = stateService.portalMeta;
			const everyoneCanAccess = userService.isLearner();
			return learner_dashboard_enabled && !my_learning_enabled && everyoneCanAccess;
		}
	},
	{
		iconClass: 'lu-catalog',
		label: 'lup.tabs.catalog',
		url: `/${AngieAppRoutes.CATALOG}`,
		external: true,
		permissionCheck: ({ stateService, userService }) => {
			const { catalog_enabled } = stateService.portalMeta;
			const roleCheck =
				userService.isAdmin() || userService.isManager() || userService.isInstructor() || userService.isLearner();
			return roleCheck && catalog_enabled;
		}
	},
	{
		iconClass: 'lu-resources',
		label: 'lup.tabs.resources',
		url: `/${AngieAppRoutes.LEARNER_RESOURCE_LIST}`,
		external: false,
		permissionCheck: ({ userService, stateService }) => {
			const { learner_resources_count } = stateService.portalMeta;
			const roleCheck =
				userService.isAdmin() || userService.isManager() || userService.isInstructor() || userService.isLearner();
			return roleCheck && !!learner_resources_count;
		}
	},
	{
		iconClass: 'lu-store',
		label: 'lup.tabs.store',
		url: `/${AngieAppRoutes.STORE}`,
		external: true,
		permissionCheck: ({ stateService, userService }) => {
			const { ecomms_enabled } = stateService.portalMeta;
			const roleCheck =
				userService.isAdmin() || userService.isManager() || userService.isInstructor() || userService.isLearner();
			return roleCheck && ecomms_enabled;
		}
	},
	{
		iconClass: 'lu-forums',
		label: 'lup.tabs.forums',
		url: `/${AngieAppRoutes.FORUMS}`,
		external: true,
		permissionCheck: ({ stateService, userService }) => {
			const { portalMeta, luUser } = stateService;
			const roleCheck =
				userService.isAdmin() || userService.isManager() || userService.isInstructor() || userService.isLearner();
			return roleCheck && portalMeta.learner_view_forums_enabled && !luUser.impersonation_active;
		}
	}
];
