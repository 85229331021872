import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AngieAppRoutes } from './angie-app.routes';
import { canActivateLearningJourneyGuardFn } from './angie-shared/guards/learning-journeys/can-activate-learning-journey.guard';
import { canActivateLiveTrainingsGuardFn } from './angie-shared/guards/can-activate-live-trainings.guard';
import { DefaultLayoutComponent } from './layouts';
import { EmptyLayoutComponent } from './layouts/empty-layout/empty-layout.component';
import { certEditorAccessGuard, certRedesignGuard } from './modules/certificates/guards/certificates-guards';

const authRoutes = [
	{
		path: `${AngieAppRoutes.AUTH}/sign_in`,
		loadChildren: () => import('./modules/auth/pages/sign-in/sign-in.module').then(m => m.SignInModule)
	},
	{
		path: `${AngieAppRoutes.AUTH}/sign_up`,
		loadChildren: () => import('./modules/auth/pages/sign-up/sign-up.module').then(m => m.SignUpModule)
	},
	{
		path: `${AngieAppRoutes.AUTH}/password/new`,
		loadChildren: () =>
			import('./modules/auth/pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
	},
	{
		path: `${AngieAppRoutes.AUTH}/password/change_password_first`,
		loadChildren: () =>
			import('./modules/auth/pages/change-password/change-password.module').then(m => m.ChangePasswordModule)
	},
	{
		path: `${AngieAppRoutes.AUTH}/password/edit`,
		loadChildren: () =>
			import('./modules/auth/pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
	},
	{
		path: `${AngieAppRoutes.AUTH}/password/default/edit`,
		loadChildren: () =>
			import('./modules/auth/pages/reset-default-password/reset-default-password.module').then(
				m => m.ResetDefaultPasswordModule
			)
	},
	{
		path: `${AngieAppRoutes.AUTH}/confirmation`,
		loadChildren: () => import('./modules/auth/pages/confirmation/confirmation.module').then(m => m.ConfirmationModule)
	},
	{
		path: `${AngieAppRoutes.AUTH}/code_redeem`,
		loadChildren: () => import('./modules/auth/pages/code-redeem/code-redeem.module').then(m => m.CodeRedeemModule)
	},
	{
		path: `${AngieAppRoutes.AUTH}/terms_of_service`,
		loadChildren: () =>
			import('./modules/terms-and-service/terms-and-service.module').then(m => m.TermsAndServiceModule)
	},
	{
		path: `${AngieAppRoutes.AUTH}/privacy-policy`,
		loadChildren: () =>
			import('./modules/auth/pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
	}
];

/**
 * Routes schema of our app routing module - Root Level routes
 */
const routes = [
	...authRoutes,
	{
		path: `embed`,
		loadChildren: () => import('./modules/auth/pages/embed/embed.module').then(m => m.EmbedModule)
	},
	{
		path: AngieAppRoutes.CATALOG,
		loadChildren: () => import('./modules/catalog/catalog.module').then(m => m.CatalogModule)
	},
	{
		path: '',
		component: DefaultLayoutComponent,
		children: [
			{
				path: AngieAppRoutes.LEARNER_DASHBOARD,
				loadChildren: () =>
					import('./modules/learner-dashboard/learner-dashboard.module').then(m => m.LearnerDashboardModule)
			},
			{
				path: AngieAppRoutes.MY_PROFILE,
				loadChildren: () => import('./modules/my-profile/my-profile.module').then(m => m.MyProfileModule)
			},
			{
				path: AngieAppRoutes.DASHBOARD,
				loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
			},
			/**
			 * We have same module behind different routes - Dashboard and Manager Dashboard
			 * to keep all dashboard pages inside same module on dedicated routes
			 */
			{
				path: AngieAppRoutes.MANAGER_DASHBOARD,
				loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
			},
			{
				path: AngieAppRoutes.USERS.USERS,
				loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
				data: {
					breadcrumb: 'lup.breadcrumbs.users.index',
					breadcrumbExternal: true
				}
			},
			{
				path: AngieAppRoutes.COURSES.COURSES,
				loadChildren: () => import('./modules/courses/courses.module').then(m => m.CoursesModule),
				data: {
					breadcrumb: 'lup.breadcrumbs.courses.index',
					breadcrumbExternal: true
				}
			},
			{
				path: AngieAppRoutes.ENROLLMENTS.BASE,
				loadChildren: () => import('./modules/enrollments/enrollments.module').then(m => m.EnrollmentsModule)
			},
			{
				path: AngieAppRoutes.ILTs.ILTs,
				loadChildren: () =>
					import('./modules/instructor-led-trainings/trainings.module').then(m => m.LiveTrainingsModule),
				canActivate: [canActivateLiveTrainingsGuardFn],
				data: {
					breadcrumb: 'tabs.live_learning',
					breadcrumbExternal: false
				}
			},
			{
				path: AngieAppRoutes.PORTALS_LICENSES.PORTALS_LICENSES,
				loadChildren: () =>
					import('./modules/portals-licenses/portals-licenses.module').then(m => m.PortalsLicensesModule),
				data: {
					breadcrumb: 'lup.tabs.licenses'
				}
			},
			{
				path: AngieAppRoutes.GROUPS,
				loadChildren: () => import('./modules/groups/groups.module').then(m => m.GroupsModule),
				data: {
					breadcrumb: 'lup.tabs.groups',
					breadcrumbExternal: true
				}
			},
			{
				path: AngieAppRoutes.REPORTS_BUILDER,
				loadChildren: () => import('./modules/reports-builder/reports-builder.module').then(m => m.ReportsBuilderModule)
			},
			{
				path: AngieAppRoutes.LEARNING_JOURNEY.LJ_HOME_ROUTE,
				loadChildren: () =>
					import('./modules/learning-journey/learning-journey.module').then(m => m.LearningJourneyModule),
				canActivate: [canActivateLearningJourneyGuardFn],
				data: {
					breadcrumb: 'lup.ljw.learning_journeys'
				}
			},
			{
				path: AngieAppRoutes.LEARNING_JOURNEY_LEARNER_VIEW.LJ_PROGRESSION_HOME_ROUTE,
				loadChildren: () =>
					import('./modules/learning-journey-progression/learning-journey-progression.module').then(
						m => m.LearningJourneyProgressionModule
					)
			},
			{
				path: AngieAppRoutes.SETTINGS,
				loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
				data: {
					breadcrumb: 'lup.tabs.settings',
					breadcrumbExternal: true
				}
			},
			{
				path: AngieAppRoutes.FORBIDDEN,
				loadChildren: () => import('./modules/forbidden/forbidden.module').then(m => m.ForbiddenModule)
			},
			{
				path: AngieAppRoutes.NOT_FOUND,
				loadChildren: () => import('./modules/not-found/not-found.module').then(m => m.NotFoundModule)
			},
			{
				path: AngieAppRoutes.TASK_TRACKING.TASK_TRACKING_BASE_URL,
				loadChildren: () => import('./modules/task-tracking/task-tracking.module').then(m => m.TaskTrackingModule)
			},
			{
				path: AngieAppRoutes.LEARNER_RESOURCES.LEARNER_RESOURCES_BASE_URL,
				loadChildren: () =>
					import('./modules/learner-resources/learner-resources.module').then(m => m.LearnerResourcesModule)
			},
			{
				path: AngieAppRoutes.MY_LEARNING,
				loadChildren: () => import('./modules/my-learning/my-learning.module').then(m => m.MyLearningModule)
			},
			{
				path: AngieAppRoutes.CERTIFICATES.CERTIFICATES,
				loadChildren: () => import('./modules/certificates/certificates.module').then(m => m.CertificatesModule),
				canActivate: [certRedesignGuard, certEditorAccessGuard]
			},
			{
				path: 'library',
				loadChildren: () => import('./modules/library/library.module').then(m => m.LibraryModule)
			},
			{
				path: 'audit-trails', // Add to enum
				loadChildren: () => import('./modules/audit-trails/audit-trails.module').then(m => m.AuditTrailsModule)
			},
			{
				path: AngieAppRoutes.CONTENT_DETAILS,
				loadChildren: () =>
					import('./modules/catalog-item-details/catalog-item-details.module').then(m => m.CatalogItemDetailsModule)
			},
			{
				path: 'reflections',
				loadChildren: () => import('./modules/reflections/reflections.module').then(m => m.ReflectionsModule)
			}
		]
	},
	{
		path: AngieAppRoutes.OAUTH,
		component: EmptyLayoutComponent,
		children: [
			{
				path: '',
				loadChildren: () => import('./modules/public-oauth/public-oauth.module').then(m => m.PublicOauthModule)
			}
		]
	}

	// {
	// 	path: '**',
	// 	redirectTo: `/${ AngieAppRoutes.DASHBOARD }`
	// },
];

/**
 * App Routing routes definition - Define all App root level routing here
 */
@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			// enable router debug
			enableTracing: false,
			useHash: false,
			anchorScrolling: 'enabled',
			scrollPositionRestoration: 'enabled'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
