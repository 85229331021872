<div
	*ngFor="let option of to.options | formlySelectOptions: field | async; let i = index"
	class="form-check custom-checkbox"
	[ngClass]="{
		'form-check-inline': to.formCheck === 'inline' || to.formCheck === 'inline-switch',
		'form-switch': to.formCheck === 'switch' || to.formCheck === 'inline-switch'
	}"
>
	<input
		type="checkbox"
		[id]="id + '_' + i"
		class="form-check-input"
		[value]="option.value"
		[checked]="isChecked(option)"
		[formlyAttributes]="field"
		(change)="onChange(option.value, $event.target.checked)"
		[attr.disabled]="option.disabled || formControl.disabled ? true : null"
	/>
	<label class="form-check-label" [for]="id + '_' + i" [ngClass]="{ disabled: option.disabled }">
		{{ option.label }}
	</label>
</div>
