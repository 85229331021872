import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WindowRefService } from 'lu-services';

/**
 * Service for file generation and file download
 */
@Injectable({
	providedIn: 'root'
})
export class FileService {
	constructor(
		private windowService: WindowRefService,
		private translateService: TranslateService
	) {}

	/**
	 * Downloads file
	 * @param blob - data that represents some file
	 * @param filenameWithExtension - eg. report.csv
	 */
	downloadFile(blob: Blob, filenameWithExtension: string): void {
		const doc = this.windowService.getWindow().document;
		const dwldLink = doc.createElement('a');
		const url = URL.createObjectURL(blob);

		dwldLink.setAttribute('href', url);
		dwldLink.setAttribute('download', filenameWithExtension);
		dwldLink.style.visibility = 'hidden';
		doc.body.appendChild(dwldLink);
		dwldLink.click();
		doc.body.removeChild(dwldLink);
	}

	/**
	 * Generates CSV data using an object and list of columns (object keys)
	 * @param dataRows - array of objects - each object represents row in csv
	 * @param tableKeys - array of strings - each string is key from a object
	 * @param header(optional) - will be added on top of a csv file as a column names
	 */
	convertToCSV(dataRows: any[], tableKeys: string[], header: string = null): string {
		let csvData = '';

		dataRows.forEach(row => {
			let csvLine = '';

			tableKeys.forEach(key => {
				let value = row[key];
				const multipleValues = !(typeof value === 'string' && value.indexOf(',') === -1);
				if (multipleValues) {
					csvLine += `"${value}",`;
				} else {
					csvLine += `${value},`;
				}
			});

			// Removes last comma from line
			csvLine = csvLine.slice(0, -1);
			csvData += csvLine + '\r\n';
		});

		if (header) {
			csvData = header + '\r\n' + csvData;
		}

		return csvData;
	}

	/**
	 * Downloads CSV file (creates blob from string and downloads it)
	 */
	downloadCSVFile(csvData: string, filename: string = 'file'): void {
		const blob = new Blob(['\ufeff' + csvData], {
			type: 'text/csv;charset=utf-8;'
		});

		this.downloadFile(blob, filename + '.csv');
	}
}
