import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, pairwise, switchMap, takeUntil, tap, startWith } from 'rxjs/operators';
import { BaseComponent } from '../../../angie-shared/components/base/base.component';
import { LayoutStructureService } from '../../services/layout-structure.service';
import { LayoutConfig } from '../models/layout-structures.models';

export const drawerClasses = {
	defaultTopAndSubHeader: 'drawer--default-headers',
	defaultTopHeader: 'drawer--default-topheader',
	minimalTopHeader: 'drawer--minimal-topheader',
	minimalAndSubHeader: 'drawer--minimal-and-subheader'
};

@Directive({
	selector: '[angieDrawerLayoutCssTrack]'
})
export class DrawerLayoutCssDirective extends BaseComponent implements OnInit {
	currentClass: string;
	constructor(
		private element: ElementRef,
		private layoutStructureService: LayoutStructureService,
		private renderer: Renderer2
	) {
		super();
	}

	ngOnInit(): void {
		this.subscribeToLayoutChanges();
	}

	private subscribeToLayoutChanges(): void {
		this.layoutStructureService.currentLayout$
			.pipe(
				takeUntil(this.destroy$),
				switchMap(layout => this.calculateDrawerClass(layout)),
				distinctUntilChanged(),
				startWith(''),
				pairwise(),
				tap(([prev, next]) => {
					this.resetClasses(prev);
					this.renderer.addClass(this.element.nativeElement, next);
				})
			)
			.subscribe();
	}

	private resetClasses(className: string): void {
		!!className && this.renderer.removeClass(this.element.nativeElement, className);
	}

	private calculateDrawerClass(layout: LayoutConfig): Observable<string> {
		const { structure } = layout;
		if (structure.showDefaultTopHeader && structure.showDefaultSubHeader)
			return of(drawerClasses.defaultTopAndSubHeader);
		if (structure.showDefaultTopHeader) return of(drawerClasses.defaultTopHeader);
		if (structure.showMinimalTopHeader && structure.showDefaultSubHeader) return of(drawerClasses.minimalAndSubHeader);
		if (structure.showMinimalTopHeader) return of(drawerClasses.minimalTopHeader);
	}
}
