import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PortalMeta, StateService } from 'src/app/core';
import { Footer } from '../default.layout.models';

/**
 * Default Footer - used for our default layout inside application
 *
 * In charge for handling all of the things related to footer
 */

@Component({
	selector: 'angie-default-footer',
	templateUrl: './default-footer.component.html',
	styleUrls: ['./default-footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultFooterComponent {
	/**
	 * Current Year that we show in footer when powered_by is not removed
	 */
	currentYear: number = new Date().getFullYear();
	/**
	 * Footer object- read from state service
	 */
	footer: Footer = this.stateService.footer;
	/**
	 * Portal meta - read from state service
	 */
	portalMeta: PortalMeta = this.stateService.portalMeta;

	/**
	 * Constructor
	 */
	constructor(private readonly stateService: StateService) {}
}
