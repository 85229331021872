import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { BaseNavigationComponent } from 'src/app/angie-shared/components/base/base-navigation.component';
import { UserService } from 'src/app/angie-shared/services/user.service';
import { LearnerNavigationItem, NavigationService, PrimaryNavigationItem, StateService } from 'src/app/core';
import { WindowRefService } from 'lu-services';

@Component({
	selector: 'angie-primary-learner-nav',
	templateUrl: './primary-learner-nav.component.html',
	styleUrls: ['./primary-learner-nav.component.scss']
})
export class PrimaryLearnerNavComponent extends BaseNavigationComponent implements OnInit {
	learnerNavigationItems: LearnerNavigationItem[];
	activeNavItem: LearnerNavigationItem;

	constructor(
		changeDetectorRef: ChangeDetectorRef,
		navigationService: NavigationService,
		public router: Router,
		private readonly stateService: StateService,
		private readonly userService: UserService,
		private readonly windowService: WindowRefService
	) {
		super(router, changeDetectorRef, navigationService);
		this.initNavigationItems();
	}

	ngOnInit(): void {
		super.ngOnInit();

		const urlPath = this.router.url.split('?')[0];
		this.updateActiveNav(urlPath);
	}

	redirect(navItem: PrimaryNavigationItem): void {
		if (navItem?.external) {
			this.windowService.getWindow().location.href = navItem.url;
		} else {
			this.router.navigateByUrl(navItem.url);
		}
	}

	protected onRouteChangeCallback(event): void {
		this.updateActiveNav(event.urlAfterRedirects);
	}

	private initNavigationItems(): void {
		this.navigationService
			.getPrimaryLeanerNavigation()
			.pipe(
				takeUntil(this.destroy$),
				map(learnerNav =>
					learnerNav.filter(navItem =>
						navItem.permissionCheck
							? navItem.permissionCheck({
									stateService: this.stateService,
									userService: this.userService
								})
							: true
					)
				)
			)
			.subscribe(navigation => {
				this.learnerNavigationItems = navigation;
			});
	}

	private updateActiveNav(url: string): void {
		url = this.navigationService.removeParams(`/${url.split('/')[1]}`);
		const navigationItems = this.learnerNavigationItems;

		for (const navItem of navigationItems) {
			if (navItem.url === url) {
				this.activeNavItem = navItem;
				this.changeDetectorRef.detectChanges();
				return;
			}
		}
	}
}
