import { Directive, HostListener } from '@angular/core';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

@Directive({
	selector: '[angieCloseOnEscapeKey]'
})
export class CloseOnEscapeKeyDirective {
	constructor(private bsDropdownDirective: BsDropdownDirective) {}

	@HostListener('keydown.escape', ['$event'])
	closeOnEscape(event: KeyboardEvent): void {
		this.bsDropdownDirective.isOpen = false;
	}
}
