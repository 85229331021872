import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LuModalModule } from 'lu-modal';
import { SupportAccessModalComponent } from './support-access-modal.component';

/**
 * All of the required declarations that we use inside our support access modal module
 */
const declarations = [SupportAccessModalComponent];

/**
 * All of the required declarations that we use inside our support access modal module
 */
const imports = [CommonModule, TranslateModule, LuModalModule];

/**
 * Support Access Modal Module - feature module
 *
 * Handles support access modal if profile is not verified
 */
@NgModule({
	declarations,
	imports,
	exports: [...declarations]
})
export class SupportAccessModalModule {}
