import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Smart Search Service - handles some common operations in smart search behaviour
 */

@Injectable()
export class AngieSmartSearchService {
	/**
	 * Once edit mode from tile has been triggered / we track it from within service
	 */
	tileEditMode$: Subject<number> = new Subject();
}
