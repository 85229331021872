import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AngieAppRoutes } from '../../../angie-app.routes';
import { UserService } from '../../services/user.service';

@Injectable({
	providedIn: 'root'
})
export class CanActivateLearningJourneyGuard {
	userService = inject(UserService);
	router = inject(Router);

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return this.allowOrRedirect(this.userService.canAccessLearningJourneys());
	}

	allowOrRedirect(canPass: boolean): boolean {
		if (canPass) {
			return true;
		}
		this.router.navigate([AngieAppRoutes.FORBIDDEN]);
		return false;
	}
}

export const canActivateLearningJourneyGuardFn: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
) => {
	return inject(CanActivateLearningJourneyGuard).canActivate(route, state);
};
