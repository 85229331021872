import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StateService } from 'src/app/core';
import { BaseComponent } from '../../../angie-shared/components/base/base.component';
import { AngieAppRoutes } from 'src/app/angie-app.routes';
@Component({
	selector: 'angie-public-header',
	templateUrl: './public-header.component.html',
	styleUrls: ['./public-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublicHeaderComponent extends BaseComponent {
	signInRoute = AngieAppRoutes.SIGN_IN;
	altTag = this.stateService.headerLogoAltTag || 'logo';

	constructor(private readonly stateService: StateService) {
		super();
	}
}
