import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LanguageCodeToAngularLocaleCodes } from '../../globals';
import { BaseModel, DsConfig } from '../core.models';
import { DataService } from './data.service';

/**
 * Language definition
 */
export interface Language extends BaseModel {
	/**
	 * Language code
	 */
	code: string;
	/**
	 * Language name
	 */
	name: string;

	/**
	 * Language id
	 */
	locale_id?: number;
}

/**
 * Language Service
 *
 * in charge for handling language load and set inside our application
 */

@Injectable()
export class LanguageService {
	/**
	 * Current language that is set (on app init)
	 */
	language: Language;
	/**
	 * Is language updated? We'll emit this Subject if there are some language changes so we can stream it down in component level
	 */
	languageUpdated$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	/**
	 * Constructor
	 */
	constructor(
		private readonly dataService: DataService,
		/**
		 * Translate Service - in charge for translations
		 */
		public readonly translateService: TranslateService
	) {}

	/**
	 * Get Language
	 *
	 * Uses data service to fetch language based on language code that is set inside service
	 */
	getLanguage(): Observable<[languageData: any, angularLocale: any]> {
		const config: DsConfig = this.dataService.getDefaultConfig(
			`${environment.cdnBase}/languages/${this.language.code}.json`
		);
		config.primarySpinnerConfig.show = false;

		return forkJoin([
			this.dataService.get<Language>(config).pipe(map(data => data[this.language.code])),
			this.loadAngularLocaleStrings(this.language.code)
		]);
	}

	/**
	 * Set language.
	 *
	 * Sets new language through translate Service
	 */
	setLanguage(languageJson: any, angularLocale: any): void {
		this.translateService.setTranslation(this.language.code, languageJson);
		this.translateService.use(this.language.code);
		registerLocaleData(angularLocale.default);
		// After languages are loaded, we do set translation strings to data service toastr titles
		// We can't do this on dataService level since we don't have language loaded at the moment of data service init
		this.dataService.responseMsgTitle = this.dataService.setToastrTitles();
	}

	/**
	 * Load Angular locale translation strings on demand
	 */
	loadAngularLocaleStrings(localeCode: string): Promise<any> {
		const localeId = LanguageCodeToAngularLocaleCodes[localeCode];

		/* webpackInclude: /(en|es|fr|de|en-GB|nl|pt|it|pl|ru|ja|zh-Hans|fr-CA|zh-Hant|cs|da|hu|nb|nl-BE|pt|sk|sv|tr|ko|fi|ar|id|vi-vn)\.mjs$/ */
		/* webpackExclude: /(global|extra)/ */

		/*
		 * Angular uses ISO 639-2 to handle locale codes, by that standard `ko-kr` or `fi-fl` are not valid but translation
		 * agency uses those codes so we need to remap them to angular valid ones.
		 * Reference: https://angular.io/guide/i18n-common-locale-id
		 */

		const localeOverrides = {
			'ko-kr': 'ko',
			'fi-fl': 'fi',
			'vi-vn': 'vi'
		};

		const overrideLocaleId = localeOverrides[localeId] ?? localeId;

		return import(`@/../@angular/common/locales/${overrideLocaleId}.mjs`);
	}
}
