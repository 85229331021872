import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ActionNavigationItem, DocumentRefService, NavigationService } from 'src/app/core';
import { BaseNavigationComponent } from '../../../../angie-shared/components/base/base-navigation.component';

/**
 * Action Nav Component - in charge for handling sub header action navigation
 *
 * Communicate with service to fetch active navigation items
 *
 * If one - show only button / if multtiple - dropdown
 */

@Component({
	selector: 'angie-action-nav',
	templateUrl: './action-nav.component.html',
	styleUrls: ['./action-nav.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionNavigationComponent extends BaseNavigationComponent implements OnInit {
	/**
	 * Observable of ActionNavigationItems from service
	 */
	actions$: Observable<ActionNavigationItem[]>;

	/**
	 * Constructor
	 */
	constructor(
		private readonly documentRef: DocumentRefService,
		router: Router,
		changeDetectorRef: ChangeDetectorRef,
		navigationService: NavigationService
	) {
		super(router, changeDetectorRef, navigationService);
	}

	/**
	 * OnInit LifeCycle Hook
	 */
	ngOnInit(): void {
		super.ngOnInit();
		this.actions$ = this.navigationService.getActionNavigation();
	}

	toggleAdminNavZIndexOrder(event: boolean): void {
		const isDropdownOpen = event;
		const adminNav = this.documentRef.document.querySelector<HTMLElement>('.admin-nav');

		if (!adminNav) return;

		if (isDropdownOpen) {
			adminNav.style.zIndex = '1030';
		} else {
			adminNav.style.zIndex = '1080';
		}
	}
	/**
	 * On route change we clear out action navigation items
	 */
	protected onRouteChangeCallback(): void {
		// this.navigationService.clearActionNavigation();
	}
}
