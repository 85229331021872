import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { DataService, DsConfig, FlashMessage, FlashMessageStyle, StateService } from 'src/app/core';
import { BaseComponent } from '../../../angie-shared/components/base/base.component';
import { FlashMessagesRoutes } from './routes/flash-messages.routes';

/**
 * Flash Message Component
 *
 * in charge for tracking if we should show/hide flash message
 *
 * Rather than that we also have ability to close it from inside of this component
 */

@Component({
	selector: 'angie-flash-messages',
	templateUrl: './flash-messages.component.html',
	styleUrls: ['./flash-messages.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlashMessagesComponent extends BaseComponent implements OnInit {
	/**
	 * FLash Message object that we read from state Service
	 */
	flashMessage: FlashMessage;
	/**
	 * FLash Message Style instance which we'll use inside our html to do some comparations
	 */
	FlashMessageStyle = FlashMessageStyle;

	/**
	 * Constructor
	 */
	constructor(
		private readonly stateService: StateService,
		private readonly dataService: DataService
	) {
		super();
	}

	/**
	 * Dismiss flash Message after "X" button was clicked
	 */
	dismissFlashMsg(): void {
		const dsConfig: DsConfig = {
			url: FlashMessagesRoutes.FLASH_MESSAGE_DISMISS(this.stateService.flashMessage.id),
			primarySpinnerConfig: {
				show: false
			}
		};

		this.dataService
			.put(dsConfig, null)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.stateService.flashMessageStatus$.next(null);
			});
	}

	/**
	 * OnInit LifeCycle hook
	 */
	ngOnInit(): void {
		this.flashMessage = this.stateService.flashMessage;
	}
}
