import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular-ivy';

// Enable Sentry only on staging environment
if (environment.env === 'staging') {
	Sentry.init({
		environment: environment.env,
		dsn: 'https://339236fe71c6df902baea9f0b4433cf5@us.sentry.io/4506699741724672',
		integrations: [Sentry.browserTracingIntegration()],
		// Performance Monitoring
		tracesSampleRate: 0.1, //  Capture 10% of the transactions
		tracePropagationTargets: ['localhost', /^https:\/\/lupdev\.com/, /^https:\/\/luptest\.com/],
		// Session Replay
		replaysSessionSampleRate: 0.0, // Capture 0% of the transactions
		replaysOnErrorSampleRate: 0.0 // Capture 0% of the transactions
	});
}

if (environment.production) {
	enableProdMode();

	if (window) {
		window.console.log = (): void => {};
	}
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.error(err));
