<span class="bodyCssDirectiveHost" [angieLayoutCssTrack]="layoutCss.body"></span>
<angie-primary-spinner></angie-primary-spinner>
<angie-skip-link></angie-skip-link>
<header [ngClass]="{ 'a11y-foc-area': a11Focus }">
	<angie-public-header (a11Focus)="a11Focus = true"></angie-public-header>
</header>
<section id="main-content" angieAppLayoutType tabindex="0"
	[attr.aria-label]="'lup.a11y.default_main_content' | translate">
	<h1 class="sr-only">{{ (metaData$ | async)?.title | translate }}</h1>
	<div class="wrap wrap--fixed-hd" [angieLayoutCssTrack]="layoutCss.content">
		<router-outlet></router-outlet>
	</div>
</section>
<lu-scroll-top [buttonTitle]="'lup.a11y.go_to_top' | translate"></lu-scroll-top>
<angie-default-footer *angieLayoutToggle="layoutStructure.showDefaultFooter"></angie-default-footer>
