<div class="crd crd--list crd--3dot crd--no-effect">
	<div class="crd__bd">
		<div class="o-flex3 o-flex3--ellipsis-cntr">
			<a [href]="'/lpaths/' + data.id + '/details'" class="ellipsis" [attr.aria-label]="('lup.a11y.lp_title' | translate) + ' ' + data.name">
				{{ data.name }}
			</a>
		</div>
		<div class="o-flex1 text-right">
			<a class="gs-result__act" [href]="'/lpaths/' + data.id + '/content'" [attr.aria-label]="('course_bundles.number_of_courses' | translate) + ' ' + data.count_on_path">
				<lu-icon [type]="LuIconType.SOLID" icon="book gray-icon"></lu-icon> {{ data.count_on_path }}
			</a>
		</div>
	</div>
</div>
