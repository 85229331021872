import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { LuPrimaryNavigationPermissions, LuSecondaryNavigationPermissions } from 'src/app/core/core.models';

/**
 * Primary Navigation Item definition
 *
 * We use these as our primary navigation items
 */
export interface PrimaryNavigationItem {
	/**
	 * Icon in front of specific primary navigation item
	 */
	iconClass: string;
	/**
	 * Primary navigation item label
	 */
	label: string;
	/**
	 * Primary navigation url to visit
	 */
	url: string;
	/**
	 * External flag is migration process only - if we should go with routerLink or href iteself - eventually this will all be false
	 */
	external?: boolean;
	/**
	 * Group navigation items
	 */
	group: NavigationGroupType;
	/**
	 * Items order inside admin group, this could be removed when feature flag is removed
	 */
	orderIndex: number;
	/**
	 * Permission check callback whether we do or not show navigation item
	 */
	permissionCheck?: (permissions: LuPrimaryNavigationPermissions) => boolean;
	/**
	 * Flag for enabling 'NEW' badge in case this feature is a new one
	 */
	showPromotionBadge?: boolean;
}

export interface LearnerNavigationItem {
	iconClass: string;
	label: string;
	url: string;
	external: boolean;
	permissionCheck?: (permissions: LuPrimaryNavigationPermissions) => boolean;
}

/**
 * Group inside admin nav
 */
export enum NavigationGroupType {
	GROUP_A,
	GROUP_B,
	GROUP_C,
	GROUP_D
}

/**
 * Group inside admin nav
 */
export interface IGroupedMenuNav {
	group_a: PrimaryNavigationItem[];
	group_b: PrimaryNavigationItem[];
	group_c: PrimaryNavigationItem[];
	group_d: PrimaryNavigationItem[];
}

/**
 * Secondary (Middle) Navigation Item definition
 *
 * We use these to define our middle navigation items inside subheader
 */
export interface SecondaryNavigationItem {
	/**
	 * Provide specific key for uniqueness of item
	 */
	id: string;
	/**
	 * Middle navigation item label
	 */
	label: string;
	/**
	 * Middle navigation item URL
	 */
	url: string;
	/**
	 * OnClick callback - NOT IMPLEMENTED yes
	 */
	onClick?: void;
	/**
	 * active flag to know the exactly one that is active so we can highlight it
	 */
	active?: boolean;
	/**
	 * External flag - is it href or routerLink? Eventually this becomes false turing the time.
	 */
	external?: boolean;
	/**
	 * Permission check callback whether we do or not show navigation item
	 */
	permissionCheck?: (permissions: LuSecondaryNavigationPermissions) => boolean;
}

/**
 * Action Navigation Item definition
 *
 * We use these to define our subheader action items
 */
export interface ActionNavigationItem {
	/**
	 * Icon that is in front of action navigation item
	 */
	iconClass: string;
	/**
	 * Label for action navigation item
	 */
	label: string;
	/**
	 * OnClick callback - we have this one as mandatory - no routing out of the box here
	 */
	onClick: (event: Event) => void;
}

/**
 * Navigation Service - in charge of controlling our subheader navigation
 *
 * Subheader:
 *
 * Primary Navigation - left
 *
 * Middle Navigation - middle
 *
 * Action Navigation - right
 */
@Injectable()
export class NavigationService {
	/**
	 * Primary Navigation Subject - Array of PrimaryNavigationItem
	 */
	private primaryNavigation$ = new BehaviorSubject<PrimaryNavigationItem[]>([]);
	/**
	 * Middle Navigation Subject - Array of SecondaryNavigationItem
	 */
	private middleNavigation$ = new BehaviorSubject<SecondaryNavigationItem[]>([]);
	/**
	 * Action Navigation Subject - Array of ActionNavigationItem
	 */
	private actionNavigation$ = new BehaviorSubject<ActionNavigationItem[]>([]);
	/**
	 * Primary Navigation Subject - Array of PrimaryNavigationItem
	 */
	private primaryLearnerNavigation$ = new BehaviorSubject<LearnerNavigationItem[]>([]);

	/**
	 * Get primary navigation as Observable (not necesarry to cast but we still did it)
	 */
	getPrimaryNavigation(): Observable<PrimaryNavigationItem[]> {
		return this.primaryNavigation$.asObservable();
	}

	/**
	 * Get primary learner navigation as Observable (not necesarry to cast but we still did it)
	 */
	getPrimaryLeanerNavigation(): Observable<LearnerNavigationItem[]> {
		return this.primaryLearnerNavigation$.asObservable();
	}

	/**
	 * Filter and order navigation items inside navigation
	 */
	groupNavigationOptions(primaryNavigationItems: PrimaryNavigationItem[]): IGroupedMenuNav {
		return {
			group_a: this.filterAndOrderNavigationGroups(primaryNavigationItems, NavigationGroupType.GROUP_A),
			group_b: this.filterAndOrderNavigationGroups(primaryNavigationItems, NavigationGroupType.GROUP_B),
			group_c: this.filterAndOrderNavigationGroups(primaryNavigationItems, NavigationGroupType.GROUP_C),
			group_d: this.filterAndOrderNavigationGroups(primaryNavigationItems, NavigationGroupType.GROUP_D)
		};
	}

	/**
	 * Populate primary navigation items
	 */
	populatePrimaryNavigation(navigation: PrimaryNavigationItem[]): void {
		this.primaryNavigation$.next(navigation);
	}

	/**
	 * Populate learner navigation items
	 */
	populatePrimaryLearnerNavigation(navigation: LearnerNavigationItem[]): void {
		this.primaryLearnerNavigation$.next(navigation);
	}

	/**
	 * Clear primary navigation items
	 */
	clearPrimaryNavigation(): void {
		this.primaryNavigation$.next([]);
	}

	/**
	 * Get middle navigation items
	 */
	getMiddleNavigation(): Observable<SecondaryNavigationItem[]> {
		return this.middleNavigation$.asObservable();
	}

	/**
	 * Populate middle navigation items
	 */
	populateMiddleNavigation(navigation: SecondaryNavigationItem[]): void {
		this.middleNavigation$.next(navigation);
	}

	/**
	 * Clear middle navigation items
	 */
	clearMiddleNavigation(): void {
		this.middleNavigation$.next([]);
	}

	/**
	 * Get action navigation items
	 */
	getActionNavigation(): Observable<ActionNavigationItem[]> {
		return this.actionNavigation$.pipe(delay(0));
	}

	/**
	 * Populate action navigation items
	 */
	populateActionNavigation(navigation: ActionNavigationItem[]): void {
		this.actionNavigation$.next(navigation);
	}

	/**
	 * Clear action navigation items
	 */
	clearActionNavigation(): void {
		this.actionNavigation$.next([]);
	}

	/**
	 * Remove query params from current string
	 */
	removeParams(url: string): string {
		if (url.includes('?')) {
			return url.substring(0, url.indexOf('?'));
		}
		if (url.includes('#')) {
			return url.substring(0, url.indexOf('#'));
		}
		return url;
	}

	/**
	 * Filter and order navigation items inside navigation
	 */
	private filterAndOrderNavigationGroups(
		primaryNavigationItems: PrimaryNavigationItem[],
		navigationGroupType: NavigationGroupType
	): PrimaryNavigationItem[] {
		const temp = primaryNavigationItems.filter(item => item.group === navigationGroupType);
		temp.sort((a, b) => a.orderIndex - b.orderIndex);
		return temp;
	}
}
