import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { LuCkeditorComponent, LuCkeditorOptions } from 'lu-ck-editor';

@Component({
	templateUrl: './lu-ckeditor-formly.component.html',
	styleUrls: ['./lu-ckeditor-formly.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LuCKEditorFormlyComponent extends FieldType implements OnInit, AfterViewInit {
	@ViewChild(LuCkeditorComponent, { static: true }) ckEditor!: LuCkeditorComponent;
	ckeditorOptions: Partial<LuCkeditorOptions>;

	get type(): string {
		return this.to.type || 'lu-ckeditor';
	}

	ngOnInit(): void {
		if (this.to.ckeditorOptions) {
			this.ckeditorOptions = this.to.ckeditorOptions;
		}
	}

	ngAfterViewInit(): void {
		this.ckeditorOptions.onReady = () => this.ckEditor.writeValue(this.model[this.field.key as string]);
	}

	change($event): void {
		if (!!this.to && !!this.to.change) {
			this.to.change($event, this.field);
		}
	}
}
