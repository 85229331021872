export enum EnrollmentRoutesConfig {
	BASE = 'enrollments',
	NEW = 'new',
	CHOOSE_SESSION = 'choose-session',
	ENROLLMENTS = ':enrollment_id',
	EXISTING_ENROLLMENT_COMPONENT = 'page/:enrollment_component_id'
}

/**
 * Enrollment Routes inside Angie app
 */
export const AppEnrollmentRoutes = {
	// URL segment names - used as is for routing modules
	BASE: EnrollmentRoutesConfig.BASE,
	NEW: EnrollmentRoutesConfig.NEW,
	CHOOSE_SESSION: EnrollmentRoutesConfig.CHOOSE_SESSION,
	ENROLLMENTS: EnrollmentRoutesConfig.ENROLLMENTS,

	base(): string {
		return AppEnrollmentRoutes.BASE;
	},

	new(): string {
		return `${AppEnrollmentRoutes.base()}/${AppEnrollmentRoutes.NEW}`;
	},

	existingEnrollment(enrollmentId: number): string {
		return `${AppEnrollmentRoutes.base()}/${enrollmentId}`;
	},

	chooseSession(enrollmentId: number): string {
		return `${AppEnrollmentRoutes.existingEnrollment(enrollmentId)}/${AppEnrollmentRoutes.CHOOSE_SESSION}`;
	}
};
