import { AfterContentInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs/internal/Observable';
import { delay, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/angie-shared/components/base/base.component';
import { MetaData, MetaService } from 'src/app/angie-shared/services';
import { UserService } from 'src/app/angie-shared/services/user.service';
import { NavigationService } from 'src/app/core/services/navigation.service';
import {
	ActionFooterService,
	ChatType,
	DocumentRefService,
	FlashMessage,
	LuUser,
	LuUserRoleCode,
	StateService
} from '../../core';
import { LayoutCssClassesMembers, LayoutStructureMembers } from './models/layout-structures.models';
import { learnerNavigation } from './navigations/learner-navigation.const';
import { primaryAdminNavigation } from './navigations/primary-admin-navigation.const';

/**
 * Default Layout Component
 *
 * Contains all of the information regarding our layout and all of our components that are layout related
 */
@Component({
	selector: 'angie-default-layout',
	templateUrl: './default-layout.component.html',
	styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent extends BaseComponent implements OnInit, AfterContentInit {
	showSubNavbar: boolean;
	navOpened: boolean = true;

	/**
	 * Tracks if we have flash message or not (get this one from stateService)
	 */
	flashMessageStatus: FlashMessage;

	layoutCss = LayoutCssClassesMembers;
	layoutStructure = LayoutStructureMembers;

	/**
	 * Page meta data
	 */
	metaData$: Observable<MetaData> = this.metaService.metaData$.asObservable().pipe(delay(0));
	/**
	 * If skip to content has been clicked
	 */
	a11Focus: boolean;
	/**
	 * Modal triggers
	 */
	modalTriggers: HTMLElement[] = [];

	zIndexClassToggle: boolean = false;

	/**
	 * Used for sidenav/header top offset adjustment, the value is updated using ResizeObserver
	 * and passed to the css variable --custom-offset through template style bindings
	 *
	 * The offset value is the height off flash-messages-header wrap
	 */
	customOffset: string;

	user: LuUser = this.stateService.luUser;
	LuUserRoleCode = LuUserRoleCode;
	isLearner = this.userService.isLearner();
	isInLearnerView = this.stateService.isInLearnerView();

	constructor(
		public userService: UserService,
		private readonly documentRef: DocumentRefService,
		private readonly navigationService: NavigationService,
		public readonly stateService: StateService,
		private readonly metaService: MetaService,
		private readonly bsModalService: BsModalService,
		private readonly actionFooterService: ActionFooterService,
		private readonly cdr: ChangeDetectorRef
	) {
		super();
	}

	/**
	 * OnInit LifeCycle hook
	 */
	ngOnInit(): void {
		this.stateService.flashMessageStatus$.pipe(takeUntil(this.destroy$)).subscribe(flashMessageStatus => {
			this.flashMessageStatus = flashMessageStatus;
		});

		this.navigationService.populatePrimaryNavigation(primaryAdminNavigation);
		this.navigationService.populatePrimaryLearnerNavigation(learnerNavigation);
		this.watchForModalOpen();
		this.watchForModalClose();
		this.adaptWidgetPositions();
	}

	ngAfterContentInit(): void {
		this.observeHeaderElementsResizing();
		const flashMessagesHeader = this.documentRef.document.querySelector('.flash-messages-header') as HTMLElement;
		this.customOffset = `${flashMessagesHeader?.offsetHeight}px`;
		this.updateAdminNavOffset(this.customOffset);
		this.cdr.detectChanges();
	}

	/**
	 * Toggle for open/close admin nav
	 */
	toggle(event: boolean): void {
		this.navOpened = event;
	}

	zIndexClass(event): void {
		this.zIndexClassToggle = event;
	}

	/**
	 * Necessary for updating header/admin-nav top offsets
	 */
	private observeHeaderElementsResizing(): void {
		const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
			this.customOffset = `${entries[0].contentRect.height}px`;
			this.updateAdminNavOffset(this.customOffset);
		});

		const flashMessagesHeader = this.documentRef.document.querySelector('.flash-messages-header');
		resizeObserver.observe(flashMessagesHeader);
	}

	private updateAdminNavOffset(offset: string): void {
		const adminNav = this.documentRef.document.querySelector('.admin-nav__nav') as HTMLElement;

		if (adminNav) {
			adminNav.style.setProperty('top', offset);
			adminNav.style.setProperty('padding-bottom', offset);
		}
	}
	/**
	 * Watch for modal close and after it gets closed - focus on button that opened it
	 */
	private watchForModalClose(): void {
		this.bsModalService.onHidden.pipe(takeUntil(this.destroy$)).subscribe(() => {
			if (this.modalTriggers.length) {
				this.modalTriggers[this.modalTriggers.length - 1].focus();
				this.modalTriggers.pop();
			}
		});
	}

	/**
	 * On modal open - remember reference to element so we can focus it after we close modal
	 */
	private watchForModalOpen(): void {
		this.bsModalService.onShow.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.modalTriggers = [...this.modalTriggers, this.documentRef.document.activeElement as HTMLElement];
		});
	}

	/**
	 * Listens action footer service, and if there are some CTA in it, move chat widgets so that footer actions are visible
	 */
	private adaptWidgetPositions(): void {
		this.actionFooterService
			.getFooterActions()
			.pipe(takeUntil(this.destroy$))
			.subscribe(actions => {
				// If we have some footer CTAs defined, move chat widgets
				this.repositionWidgets(!actions.length);
			});
	}

	/**
	 * Check witch integrations is active and offset its CTA
	 * defaultPosition - footer not visible
	 */
	private repositionWidgets(defaultPosition: boolean = true): void {
		if (this.stateService.chatSettings) {
			if (this.stateService.chatSettings.active_chat === ChatType.INTERCOM) {
				// Intercom default chat button
				const intercomButton = this.documentRef.document.querySelector<HTMLElement>(
					'.intercom-lightweight-app-launcher'
				);

				// Intercom widget wrapper
				const parentElement = this.documentRef.document.querySelector<HTMLElement>('.intercom-app');

				if (intercomButton) {
					intercomButton.style.bottom = defaultPosition ? '20px' : '65px';
				}

				if (parentElement) {
					const children = parentElement.querySelectorAll('div');
					children[0].style.bottom = defaultPosition ? '84px' : '120px'; //.intercom-messenger-frame
					children[1].style.bottom = defaultPosition ? '20px' : '65px'; // element with dynamic class, open chat button
				}
			}

			if (this.stateService.chatSettings.active_chat === ChatType.ZENDESK) {
				// Zendesk iframe has no unique attribute, we need to find it by child element
				const iframes = this.documentRef.document.querySelectorAll('iframe') as unknown as HTMLIFrameElement[];

				iframes.forEach(iframe => {
					if (iframe.contentWindow) {
						const zendeskBtn = iframe.contentWindow.document.querySelector<HTMLElement>('[data-garden-id]');

						if (zendeskBtn) {
							iframe.style.bottom = defaultPosition ? '20px' : '65px';
						}
					}
				});
			}
		}
	}
}
