import { angieRoutePrefix } from '../../../globals';

export enum CoursesRoutesConfig {
	COURSES = 'courses',
	CONTENT = 'content',
	ENROLLMENTS = 'enrollments',
	GROUPS = 'groups',
	INSTRUCTORS = 'instructors',
	QUESTIONS = 'questions',
	ASSIGNMENTS = 'assignments',
	FORUMS = 'forums',
	EDIT = 'edit',
	COURSE_ID = ':course_id',
	COMPONENT_ID = ':component_id',
	PREVIEW = 'preview',
	CHECKLISTS = 'checklists_observation'
}

/**
 * Courses Routes inside Angie app
 */
export const AppCoursesRoutes = {
	// URL segment names - used as is for routing modules
	COURSES: CoursesRoutesConfig.COURSES,
	CONTENT: CoursesRoutesConfig.CONTENT,
	ENROLLMENTS: CoursesRoutesConfig.ENROLLMENTS,
	GROUPS: CoursesRoutesConfig.GROUPS,
	INSTRUCTORS: CoursesRoutesConfig.INSTRUCTORS,
	CHECKLISTS: CoursesRoutesConfig.CHECKLISTS,
	QUESTIONS: CoursesRoutesConfig.QUESTIONS,
	ASSIGNMENTS: CoursesRoutesConfig.ASSIGNMENTS,
	FORUMS: CoursesRoutesConfig.FORUMS,
	EDIT: CoursesRoutesConfig.EDIT,
	COURSE_ID: CoursesRoutesConfig.COURSE_ID,
	COMPONENT_ID: CoursesRoutesConfig.COMPONENT_ID,
	PREVIEW: CoursesRoutesConfig.PREVIEW,
	MODULE_EDIT: `${CoursesRoutesConfig.COMPONENT_ID}/${CoursesRoutesConfig.EDIT}`,

	courses(): string {
		return AppCoursesRoutes.COURSES;
	},

	existingCourse(courseId: number): string {
		return `${AppCoursesRoutes.COURSES}/${courseId}`;
	},

	existingCourseContent(courseId: number): string {
		return `${AppCoursesRoutes.existingCourse(courseId)}/${AppCoursesRoutes.CONTENT}`;
	},

	existingCourseEnrollments(courseId: number): string {
		return `${AppCoursesRoutes.existingCourse(courseId)}/${AppCoursesRoutes.ENROLLMENTS}`;
	},

	existingCourseGroups(courseId: number): string {
		return `${AppCoursesRoutes.existingCourse(courseId)}/${AppCoursesRoutes.GROUPS}`;
	},

	existingCourseInstructors(courseId: number): string {
		return `${AppCoursesRoutes.existingCourse(courseId)}/${AppCoursesRoutes.INSTRUCTORS}`;
	},

	existingCourseChecklists(courseId: number): string {
		return `${AppCoursesRoutes.existingCourse(courseId)}/${AppCoursesRoutes.CHECKLISTS}`;
	},

	existingCourseQuestions(courseId: number): string {
		return `${AppCoursesRoutes.existingCourse(courseId)}/${AppCoursesRoutes.QUESTIONS}`;
	},

	existingCourseAssignments(courseId: number): string {
		return `${AppCoursesRoutes.existingCourse(courseId)}/${AppCoursesRoutes.ASSIGNMENTS}`;
	},

	existingCourseForums(courseId: number): string {
		return `${AppCoursesRoutes.existingCourse(courseId)}/${AppCoursesRoutes.FORUMS}`;
	},

	existingCourseModuleEdit(courseId: number, moduleId: number): string {
		return `${AppCoursesRoutes.existingCourseContent(courseId)}/${moduleId}/${AppCoursesRoutes.EDIT}`;
	},

	previewCourse(courseId: number, enrollmentPreviewId: number): string {
		return `${AppCoursesRoutes.existingCourse(courseId)}/${AppCoursesRoutes.PREVIEW}/${enrollmentPreviewId}`;
	}
};

export class CourseRoutes {
	public static GET_COURSE = (courseId: number): string => angieRoutePrefix.concat(`/courses/${courseId}.json`);
	public static PUBLISH_COURSE = (courseId: number): string => `/courses/${courseId}.json`;
	public static CREATE_PREVIEW_COURSE = (courseId: number): string =>
		`/courses/${courseId}/create_preview_enrollment.json`;
	public static GET_COURSE_CERTIFICATION = (courseId: number): string =>
		angieRoutePrefix.concat(`/courses/${courseId}/certification.json`);
}
