import { CertificatesRoutesConfig } from './certificates-routes.config';

export class CertificatesRoutes {
	public static readonly CERTIFICATES = CertificatesRoutesConfig.CERTIFICATES_BASE_ROUTE;
	public static readonly CREATE_CERTIFICATE = CertificatesRoutesConfig.NEW_CERTIFICATE_URL_SEGMENT;
	public static readonly EDIT_CERTIFICATE = `${CertificatesRoutesConfig.EDIT_CERTIFICATE_URL_SEGMENT}/${CertificatesRoutesConfig.CERTIFICATE_ID_PARAM}`;

	public static readonly CERTIFICATES_HOME_ROUTE = `/${CertificatesRoutes.CERTIFICATES}`;

	public static getCreateCertificateRoute(): string {
		return `${CertificatesRoutes.CERTIFICATES_HOME_ROUTE}/${CertificatesRoutes.CREATE_CERTIFICATE}`;
	}
	public static getEditCertificateRoute(certificateId: number): string {
		return `${CertificatesRoutes.CERTIFICATES_HOME_ROUTE}/${CertificatesRoutesConfig.EDIT_CERTIFICATE_URL_SEGMENT}/${certificateId}`;
	}
}
