import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { LayoutStructureService } from 'src/app/layouts/services/layout-structure.service';

@Directive({
	selector: '[angieContrast]'
})
export class ContrastDirective implements OnInit {
	@Input() contrastClass: string;

	constructor(
		private readonly hostElement: ElementRef,
		private readonly renderer: Renderer2,
		private readonly layoutStructureService: LayoutStructureService
	) {}

	ngOnInit(): void {
		this.addClass();
	}

	private addClass(): void {
		const layout = this.layoutStructureService.currentLayout$.getValue();

		//TODO: should be more generic, will be refactored with PH card
		if (layout?.cssClasses?.body && layout?.cssClasses?.body.includes('bg--white')) {
			this.renderer.addClass(this.hostElement.nativeElement, this.contrastClass);
		}
	}
}
