import { DOCUMENT } from '@angular/common';
import { Injectable, NgZone, inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd } from '@angular/router';
import { angieRoutePrefix } from 'src/app/globals';
import { ROUTER_PAGE_META } from '../insights.config';
import { environment } from 'src/environments/environment';
import {
	TrackEvent,
	ActionEventParams,
	ActionEvent,
	InternalEventType,
	PageViewParams,
	PageViewEvent,
	PageLoadParams,
	PageLoadEvent,
	PageMeta
} from '../insights.models';

@Injectable({
	providedIn: 'root'
})
export class InsightsService {
	private buffer: TrackEvent[] = [];

	private readonly FLUSH_INTERVAL = 5000;
	private readonly ngZone = inject(NgZone);
	private readonly document = inject(DOCUMENT);
	private readonly meta = inject(Meta);

	private isInitialized = false;
	private currentPageViewId: string;
	private currentPageGroup: string;
	private currentPageName: string;

	/**
	 * Initialize InsightService by setting up a periodic timer
	 * to send buffer of events to Tracking backend service. It
	 * also send them when users leave the page.
	 */
	init(): void {
		if (this.isInitialized) {
			throw new Error('InsightsService is already initialized');
		}

		this.ngZone.runOutsideAngular(() => {
			setInterval(() => {
				this.flushBuffer();
			}, this.FLUSH_INTERVAL);

			this.document.addEventListener('visibilitychange', () => {
				if (this.document.visibilityState === 'hidden') {
					this.flushBuffer();
				}
			});

			this.document.defaultView.addEventListener('pagehide', () => {
				this.flushBuffer();
			});

			this.isInitialized = true;
		});
	}

	/**
	 * Capture page interaction events
	 * @param event describe action event details
	 */
	actionEvent(event: ActionEventParams): void {
		const origin = {
			pageelement: event.pageelement,
			pagegroup: this.currentPageGroup,
			pagename: this.currentPageName
		};

		const request: ActionEvent = {
			origin: origin,
			pageviewId: this.currentPageViewId,
			action_subtype: event.action_subtype,
			action_type: event.action_type,
			parameters: event.parameters,
			url_path: this.document.defaultView.location.pathname,
			unixtimestamp: new Date().valueOf(),
			type: InternalEventType.CLICK_EVENT
		};

		if (this.isTrackEventValid(request)) {
			this.buffer.push(request);
		}
	}

	/**
	 * Capture when page is visited
	 * @param event describe page view event details
	 */
	pageViewEvent(event: NavigationEnd | PageViewParams): void {
		this.currentPageViewId = this.getUUID();

		const pageMeta = event instanceof NavigationEnd ? this.getPageMeta(event) : event;

		this.currentPageGroup = pageMeta?.pagegroup;
		this.currentPageName = pageMeta?.pagename;

		if (environment.env !== 'production') {
			if (!this.currentPageGroup) {
				console.warn('WARNING - Missing page group');
			}

			if (!this.currentPageName) {
				console.warn('WARNING - Missing page name');
			}
		}

		const request: PageViewEvent = {
			page: {
				pagegroup: this.currentPageGroup,
				pagename: this.currentPageName
			},
			url_path: this.document.defaultView.location.pathname,
			pageviewId: this.currentPageViewId,
			unixtimestamp: new Date().valueOf(),
			type: InternalEventType.PAGE_VIEW
		};

		if (this.isTrackEventValid(request)) {
			this.buffer.push(request);
		}
	}

	/**
	 * Capture when page content is loaded from backend
	 * @param event describe page load event details
	 */
	pageLoadEvent(event: PageLoadParams): void {
		const request: PageLoadEvent = {
			page: {
				pagegroup: this.currentPageGroup,
				pagename: this.currentPageName
			},
			page_contents: event.page_contents,
			pageviewId: this.currentPageViewId,
			url_path: this.document.defaultView.location.pathname,
			unixtimestamp: new Date().valueOf(),
			type: InternalEventType.PAGE_LOAD
		};

		if (this.isTrackEventValid(request)) {
			this.buffer.push(request);
		}
	}

	/** Send all event in buffer to tracking API service */
	sendEvents(): void {
		this.flushBuffer();
	}

	private flushBuffer(): void {
		const events: TrackEvent[] = this.buffer.splice(0);

		if (events.length > 0) {
			const csrfParam = this.meta.getTag('name="csrf-param"').content;
			const csrfToken = this.meta.getTag('name="csrf-token"').content;

			const timestamp = `${new Date().valueOf()}`;

			const payload = new FormData();
			payload.append('events', JSON.stringify(events));
			payload.append('local_timestamp', timestamp);
			payload.append(csrfParam, csrfToken);

			const url = angieRoutePrefix.concat(`/data-insight.json`);

			if (environment.env !== 'production') {
				this.logEvents(events, timestamp);
			}

			if (navigator.sendBeacon) {
				navigator.sendBeacon(url, payload);
			} else {
				fetch(url, {
					method: 'post',
					keepalive: true,
					body: payload
				});
			}
		}
	}

	private logEvents(events: TrackEvent[], timestamp): void {
		console.groupCollapsed(`Tracking events (${timestamp}})`);
		console.table(
			events.map(e => ({
				type: e.type,
				pagegroup: 'page' in e ? e.page.pagegroup : e.origin.pagegroup,
				pagename: 'page' in e ? e.page.pagename : e.origin.pagename,
				pageelement: 'origin' in e && e.origin.pagename,
				pageviewId: e.pageviewId,
				url_path: e.url_path,
				unixtimestamp: e.unixtimestamp,
				raw: e
			}))
		);
		console.groupEnd();
	}

	private getUUID(): string {
		return crypto.randomUUID().replace(/-/g, '');
	}

	private getPageMeta(routerEvent: NavigationEnd): PageMeta {
		const pageKey = Object.keys(ROUTER_PAGE_META).find(key => new RegExp(key).test(routerEvent.url));
		const pageMeta = ROUTER_PAGE_META[pageKey];

		return pageMeta;
	}

	private isTrackEventValid(trackEvent: TrackEvent): boolean {
		if ('page' in trackEvent) {
			return trackEvent.page?.pagegroup?.length > 0 && trackEvent.page?.pagename?.length > 0;
		}
		if ('origin' in trackEvent) {
			return trackEvent.origin.pagegroup.length > 0 && trackEvent.origin.pagename?.length > 0;
		}

		return false;
	}
}
