import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LayoutConfigBuilder } from 'src/app/layouts/default-layout/builders/layout-config.builder';
import { LayoutConfig } from 'src/app/layouts/default-layout/models/layout-structures.models';

@Injectable()
export class LayoutStructureService {
	public currentLayout$: BehaviorSubject<LayoutConfig> = new BehaviorSubject(
		LayoutConfigBuilder.getDefaultLayoutPreset()
	);

	constructor(
		private readonly router: Router,
		private readonly activatedRoute: ActivatedRoute
	) {
		this.listenToRoutingChanges();
	}

	private listenToRoutingChanges(): void {
		this.router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				map(() => {
					let child = this.activatedRoute.firstChild;
					while (child) {
						if (child.firstChild) {
							child = child.firstChild;
						} else if (child.snapshot.data) {
							return child.snapshot.data;
						}
					}
					return null;
				})
			)
			.subscribe(data => {
				const layoutConfig = data?.layoutConfig || LayoutConfigBuilder.getDefaultLayoutPreset();
				this.currentLayout$.next(layoutConfig);
			});
	}
}
