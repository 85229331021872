import { SortDirection } from '@angular/material/sort';

/**
 * String comparator helpers
 * Avoids various encode value pitfalls e.g. ("99" > "100" === true | "a" > "A" === true)
 *
 * @param direction Sort direction 'asc' | 'desc'
 * @param a first compare value
 * @param b second compare value
 * @param optional value validator fn
 * @returns number -1 | 1 | 0
 */
export const compareString = (
	direction: SortDirection,
	a: string,
	b: string,
	validator?: (value: string) => boolean
): number => {
	if (validator && !validator(a)) return 1;
	if (validator && !validator(b)) return -1;

	const compareResult = a.localeCompare(b, undefined, { caseFirst: 'upper' });
	if (compareResult === 0) return 0;
	return direction === 'asc' ? (compareResult < 0 ? -1 : 1) : compareResult < 0 ? 1 : -1;
};

export const compareNumericString = (
	direction: SortDirection,
	a: string,
	b: string,
	validator?: (value: string) => boolean
): number => {
	if (validator && !validator(a)) return 1;
	if (validator && !validator(b)) return -1;

	const compareResult = a.localeCompare(b, undefined, { numeric: true });
	if (compareResult === 0) return 0;
	return direction === 'asc' ? (compareResult < 0 ? -1 : 1) : compareResult < 0 ? 1 : -1;
};

/**
 * Parses datetime strings depending on format
 * converts to 'YYYY/mm/dd hh:MM' that's naturally compared
 *
 * @param direction Sort direction 'asc' | 'desc'
 * @param a first compare value
 * @param b second compare value
 * @param dateFormat Date format e.g. 'dd/mm/yyyy'
 * @returns number -1 | 1 | 0
 */

/**
 * Compares arrays based on their length
 *
 * @param direction Sort direction 'asc' | 'desc
 * @param a first compare value
 * @param b second compare value
 * @returns number -1 | 1 | 0
 */
export const compareArray = (direction: SortDirection, a: any[], b: any[]): number => {
	const ascending = direction === 'asc';
	if (!a || !Array.isArray(a) || !a.length) return 1;
	if (!b || !Array.isArray(b) || !b.length) return -1;

	if (a.length === b.length) return 0;

	return ascending ? (a.length < b.length ? -1 : 1) : a.length < b.length ? 1 : -1;
};

export const compareInteger = (direction: SortDirection, a: any, b: any): number => {
	const ascending = direction === 'asc';
	if (!a || typeof a !== 'number') return 1;
	if (!b || typeof b !== 'number') return -1;

	if (a === b) return 0;

	return ascending ? (a < b ? -1 : 1) : a < b ? 1 : -1;
};
