import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActionFooterService as SharedActionFooterService } from 'lu-services';

/**
 * Action Footer Item
 *
 * This items are used for footer action definition
 */
export interface ActionFooterItem {
	/**
	 * Button class for specific action footer item
	 */
	buttonClass: string;
	/**
	 * Label inside button inside action footer item
	 */
	label: string;
	/**
	 * On click callback - defines click behaviour of specific item
	 */
	onClick: () => void;
	/**
	 * Disabled/enabled flag
	 */
	disabled?: boolean;
}

/**
 * Action Footer Service - extends Shared Action Footer Service (from lu-services)
 *
 * This one inherits all of the methods and configs from our lu-services and it serves as wrapper
 *
 * Injected on app initialization so we use it globally
 */
@Injectable()
export class ActionFooterService extends SharedActionFooterService {
	/**
	 * Constructor
	 */
	constructor(private readonly translateService: TranslateService) {
		super();
	}

	defaultFormActions(successCallback: () => void, failureCallback: () => void): ActionFooterItem[] {
		return [
			{
				buttonClass: 'btn btn--success',
				label: this.translateService.instant('save'),
				onClick: successCallback
			},
			{
				buttonClass: 'btn btn--default',
				label: this.translateService.instant('cancel'),
				onClick: failureCallback
			}
		];
	}
}
