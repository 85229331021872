import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoleCodeToLabelPipe } from './role-code-to-label.pipe';

const declarations = [RoleCodeToLabelPipe];

const imports = [CommonModule];
@NgModule({
	declarations,
	exports: [...declarations],
	imports
})
export class RoleCodeToLabelModule {}
