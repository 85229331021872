<div class="dropdown" dropdown (onShown)="loadPortals()" (onHidden)="onHidden()" #dropdown="bs-dropdown" [cdkTrapFocus]="dropdown.isOpen" angieCloseOnEscapeKey>
	<button type="button" class="header-btn def-focus dropdown-toggle pjbtn"
	[tooltip]="'lup.tips.header.portal_jumper' | translate"
	[tooltipAnimation]="false"
	[attr.aria-label]="'lup.tips.header.portal_jumper' | translate"
	[attr.aria-expanded]="dropdown.isOpen"	[attr.aria-haspopup]="dropdown.isOpen" dropdownToggle>
	<lu-icon customClass="icon-large header-color-icon" icon="lu-portals" *ngIf="luUser.role_code === LuUserRoleCode.ADMIN"></lu-icon>
	<lu-icon customClass="icon-large header-color-icon" icon="lu-portals" *ngIf="luUser.role_code !== LuUserRoleCode.ADMIN"></lu-icon>
	</button>
	<div role="menu" class="dropdown-menu dropdown-menu-right header-dropdown header-dropdown--msg-switch ss-ddm" *dropdownMenu (click)="$event.stopPropagation()">
		<header class="ss-ddm__header ss-ddm__header--pj">
			<div class="ss-ddm__hdg o-flex o-flex--space-between">
				<h3>{{ 'lup.header.my_portals' | translate }}</h3>
				<div class="responsive-dropdown-close hdn-from-sm">
					<button (click)="dropdown.hide()" class="btn btn--ico btn--ghost" [title]="'close' | translate">
						<lu-icon [type]="LuIconType.SOLID" icon="times"></lu-icon>
					</button>
				</div>
			</div>
			<div class="ss-ddm__act">
				<div class="search-component pj-search">
					<form role="search" [formGroup]="searchForm">
						<formly-form [form]="searchForm" [model]="searchModel" [fields]="searchField"></formly-form>
						<lu-icon class="icon-large" [type]="LuIconType.SOLID" icon="search magnifier"></lu-icon>
					</form>
				</div>
			</div>
		</header>
		<div class="blank-state md" *ngIf="portals && !portals.length && !loading">
			<img class="blank-state-img" [src]="cdnPath + '/assets/images/blank-state/no-portals.svg'" aria-hidden="true" [alt]="'lup.support_access.no_portals_found' | translate">
			<h4 class="blank-state-title">{{ 'layouts.no_matching_portals'  | translate }}</h4>
		</div>
		<section class="pj-wrap" *ngIf="portals && portals.length && !loading">
			<div class="clients-list" luInfiniteScroll (scrolled)="loadMore()" #clientsList>
				<ul class="list-unstyled smart-list" role="listbox">

					<li class="ss-ddm__info ss-ddm--msg-switch" *ngFor="let item of portals; let i = index;" role="option">
						<a class="ss-ddm__item ss-ddm__item--pj def-focus" [href]="item.url" tabindex="0"
						[attr.aria-label]="'lup.a11y.portal_jumper.portal_item' | translate:{ title: item.title, index: (i + 1), size: portals.length }">
							<div class="o-flex0">
								<div *ngIf="!item.logo_url" class="client-avatar" [ngStyle]="{ 'background-color' : item.header_color }">
									<p>{{ item.title | slice:0:1 }}</p>
								</div>
								<div *ngIf="!!item.logo_url" class="client-avatar" [ngStyle]="{ 'background-color' : item.header_color }">
									<img [src]="item.logo_url" [alt]="item.title">
								</div>
							</div>
							<div class="o-flex1 o-flex1--ellipsis">
								<span class="ellipsis">{{ item.title }}</span>
							</div>
						</a>
					</li>

				</ul>
			</div>
		</section>
		<div class="ss-ddm__loader ss-ddm__loader--pj" *ngIf="loading">
			<ul class="list-unstyled smart-list no-top">
				<li class="ss-ddm__item ss-ddm__item--pj" *ngFor="let item of [1, 2, 3, 4, 5]">
					<div class="o-flex0">
						<div class="loader-placeholder avatar-initials-small mr-12"></div>
					</div>
					<div class="o-flex1 o-flex1--ellipsis">
						<div class="loader-placeholder loader-text is-12"></div>
					</div>
				</li>
			</ul>
		</div>
	</div>
</div>
