import { AppCoursesRoutes } from './modules/courses/routes/courses.routes';
import { DashboardRoutes } from './modules/dashboard/routes/dashboard.routes';
import { AppEnrollmentRoutes } from './modules/enrollments/routes/enrollments.routes';
import { IltsRoutes } from './modules/instructor-led-trainings/routes/ilts.routes';
import { LearnerResourcesRoutes } from './modules/learner-resources/routes/learner-resources.routes';
import { LearningJourneyProgressionRoutes } from './modules/learning-journey-progression/routes/learning-journey-progression-routes';
import { LearningJourneyRoutes } from './modules/learning-journey/routes/learning-journey-routes';
import { PortalLicensesRoutes } from './modules/portals-licenses/routes/portal-licenses.routes';
import { IntegrationsRoutes } from './modules/settings/integrations/pages/integrations-hub/routes/integrations.routes';
import { TaskTrackingRoutes } from './modules/task-tracking/routes/task-tracking.routes';
import { AppUsersRoutes } from './modules/users/routes/users.routes';
import { CertificatesRoutes } from './modules/certificates/routes/certificates.routes';

/**
 * Angie App Routes is class which would provide us way of not having hardcoded stings inside our app routing
 */
export class AngieAppRoutes {
	// TBR with static classes of each modules routes
	// like: DASHBOARD = DashboardRoutes.DASHBOARD

	public static readonly AUTH = 'users'; // Flag for new sign in
	/**
	 * Dashboard route definition
	 */
	public static readonly DASHBOARD = 'dashboard';
	/**
	 * Manager Dashboard route definition
	 */
	public static readonly MANAGER_DASHBOARD = DashboardRoutes.MANAGER_DASHBOARD;
	/**
	 * Learner Dashboard route definition
	 */
	public static readonly LEARNER_DASHBOARD = 'learner-dashboard';
	/**
	 * My Learning route definition
	 */
	public static readonly MY_LEARNING = 'my-learning';
	/**
	 * My Profile route definition
	 */
	public static readonly MY_PROFILE = 'my-profile';
	/**
	 * Users route definition
	 */
	public static readonly USERS = AppUsersRoutes;
	/**
	 * Groups route definition
	 */
	public static readonly GROUPS = 'groups';
	/**
	 * Reports route definition
	 */
	public static readonly REPORTS = 'reports';
	/**
	 * Settings route definition
	 */
	public static readonly SETTINGS = 'settings';
	/**
	 * Forbidden route definition
	 */
	public static readonly FORBIDDEN = 'forbidden';
	/**
	 * Not found route definition
	 */
	public static readonly NOT_FOUND = 'not-found';
	/**
	 * Courses route definition
	 */
	public static readonly COURSES = AppCoursesRoutes;
	/**
	 * Enrollments route definition
	 */
	public static readonly ENROLLMENTS = AppEnrollmentRoutes;
	/**
	 * Learner Resource List route definition
	 */
	public static readonly LEARNER_RESOURCE_LIST = 'learner_resource_list';
	/**
	 * Store route definition
	 */
	public static readonly STORE = 'store';
	/**
	 * Catalog route definition
	 */
	public static readonly CATALOG = 'catalog';
	/**
	 * Clients route definition
	 */
	public static readonly CLIENTS = 'clients';
	/**
	 * Components route definition
	 */
	public static readonly COMPONENTS = 'components';
	/**
	 * Forums route definition
	 */
	public static readonly FORUMS = 'forums';
	/**
	 * Content integrations route definition
	 */
	public static readonly INTEGRATIONS = IntegrationsRoutes;
	/**
	 * Task tracking route definition
	 */
	public static readonly TASK_TRACKING = TaskTrackingRoutes;
	/**
	 * Learner resources route definition
	 */
	public static readonly LEARNER_RESOURCES = LearnerResourcesRoutes;
	/**
	 * Content details route definition
	 */
	public static readonly CONTENT_DETAILS = 'content-details';
	/**
	 * Certificates route definition
	 */
	public static readonly CERTIFICATES = CertificatesRoutes;
	/**
	 * ILTs route definition
	 */
	public static readonly ILTs = IltsRoutes;
	/**
	 * Portals and Licenses route definition
	 */
	public static readonly PORTALS_LICENSES = PortalLicensesRoutes;
	/**
	 * Learning Journey route definition
	 */
	public static readonly LEARNING_JOURNEY = LearningJourneyRoutes;
	/**
	 * Learning Journey learner view route definition
	 */
	public static readonly LEARNING_JOURNEY_LEARNER_VIEW = LearningJourneyProgressionRoutes;
	/**
	 * Clients route definition
	 */
	public static readonly OAUTH = 'oauth';
	/**
	 * OIDC route definition
	 */
	public static readonly OIDC = 'oidc';

	/**
	 * Embed route definition
	 */
	public static readonly EMBED = 'embed';
	/**
	 * Reports Builder BETA route definition
	 */
	public static readonly REPORTS_BUILDER = 'reports-builder';
	/**
	 * Audit Trails route definition
	 */
	public static readonly AUDIT_TRAILS = 'audit-trails';

	public static readonly getSlashed = (route: string): string => `/${route}`;
	/**
	 * Auth Routes definition
	 */

	public static readonly SIGN_IN = '/users/sign_in';
	public static readonly AuthRoutes = [
		'sign_in',
		'sign_up',
		'password',
		'confirmation',
		'code_redeem',
		'privacy-policy'
	];
	public static readonly isAuth = (route: string): boolean => {
		// Skip this rotue we need myacc.json here.
		if (route && route.includes('change_password_first')) {
			return false;
		}

		if (route && route.includes('embed/courses')) {
			return true;
		}

		return AngieAppRoutes.AuthRoutes.includes(route.split('/')[2]); // TODO Check this function
	};
}
