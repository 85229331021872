export type ActionType = 'CLICK_BUTTON' | 'CLICK_FILTER' | 'CLICK_SEARCH' | string;
export type ActionEventParameters = { [k: string]: string | number | boolean | Array<unknown> | ActionEventParameters };
export interface DataInsightPageLoadContent {
	list_values?: unknown[];
	filters?: unknown;
	form_data?: unknown;
	parameters?: unknown;
	metrics?: {
		num_of_list_values: number;
	};
}

export interface PageMeta {
	pagename: string;
	pagegroup: string;
}
export interface ActionEventParams {
	pageelement: string;
	action_subtype: string;
	action_type?: ActionType;
	parameters?: ActionEventParameters;
}

export type PageViewParams = PageMeta;
export interface PageLoadParams {
	page_contents?: Object;
}
/**  Kafka message type */
export enum InternalEventType {
	PAGE_VIEW = 'view',
	PAGE_LOAD = 'load',
	CLICK_EVENT = 'click'
}
interface BaseEventRequest {
	pageviewId: string;
	unixtimestamp: number;
	type: InternalEventType;
	url_path: string;
}
export interface ActionEvent extends BaseEventRequest {
	origin: {
		pageelement: string;
		pagegroup: string;
		pagename: string;
	};
	parameters: ActionEventParameters;
	action_subtype: string;
	action_type: ActionType;
}
export interface PageViewEvent extends BaseEventRequest {
	page: PageMeta;
}
export interface PageLoadEvent extends BaseEventRequest {
	page: PageMeta;
	page_contents?: DataInsightPageLoadContent;
}
export type TrackEvent = ActionEvent | PageViewEvent | PageLoadEvent;
