<div class="crd crd--list crd--3dot crd--no-effect">
	<div class="crd__bd">
		<div class="o-flex3 o-flex3--ellipsis-cntr">
				<a [href]="'/catalog/' + data.type + '/' + data.id"
						class="ellipsis"
						[attr.aria-label]="('lup.a11y.catalog_data_name' | translate) + ' ' + data.name">{{ data.name }}</a>
		</div>
		<div class="o-flex1 text-right">
			<a class="gs-result__act" [href]="'/catalog/' + data.type +'/' + data.id + '/content'"
					*ngIf="data.type === 'learning-paths'"
					[attr.aria-label]="'course_bundles.number_of_courses' | translate">
				<lu-icon [type]="LuIconType.SOLID" icon="book gray-icon"></lu-icon> {{ data.content_count }}
			</a>
			<a class="gs-result__act" [href]="'/catalog/' + data.type + '/' + data.id + '/content'"
					*ngIf="data.type === 'courses'"
					[attr.aria-label]="'lup.tips.number_of_modules' | translate">
				<lu-icon [type]="LuIconType.SOLID" icon="list gray-icon"></lu-icon> {{ data.content_count }}
			</a>
		</div>
	</div>
</div>
