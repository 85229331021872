import { PageMeta } from '../insights/insights.models';

export const MANAGER_DASHBOARD_LANDING_PAGE_META: Record<string, PageMeta> = {
	'^/manager-dashboard$': {
		pagegroup: 'dashboard',
		pagename: 'manager-dashboard-landing'
	}
};

export const MANAGER_DASHBOARD_LIST_LEARNERS: Record<string, PageMeta> = {
	'^/manager-dashboard/list-learners/.*': {
		pagegroup: 'dashboard',
		pagename: 'manager-dashboard-list-learners'
	}
};
