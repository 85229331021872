<div id="mainHeader" class="lu-container-fluid mainheader pt-16 pb-16">
	<div class="lu-row">
		<div class="col xs-6">
			<angie-logo [brandLearner]="true" [altTag]="altTag" position="left" link=""></angie-logo>
		</div>
		<nav class="col xs-6">
			<ul data-qa-id="navbar_link" class="public-nav o-flex--flex-end">
				<li>
					<a class="public-nav__item pt-16 pb-16" [href]="signInRoute">
						<lu-icon class="public-nav__ico mr-8" customClass="header-color-icon" [icon]="'lu-users'"></lu-icon>
						<span class="public-nav__txt"> {{ 'lup.login.login_label' | translate }}</span>
					</a>
				</li>
			</ul>
		</nav>
	</div>
</div>
