import { LayoutConfigBuilder } from '../../../layouts/default-layout/builders/layout-config.builder';
import { LayoutConfigPresets } from '../../../layouts/default-layout/models/layout-structures.models';

export enum PortalLicensesRoutesConfig {
	PORTAL_LICENSES_BASE_URL = 'portals-licenses',
	LICENSES_URL_SEGMENT = 'licenses',
	LICENSES_LIST_SEGMENT = 'list',
	LICENSES_NEW_SEGMENT = 'new',
	LICENSES_EDIT_SEGMENT = 'edit',
	SINGLE_LICENSE_EDIT_URL = ':licenseId/edit',

	PORTALS_URL_SEGMENT = 'clients'
}
export const PortalLicensesRouteTitles = {
	portals: 'lup.pagetitles.clients',
	licenses: 'lup.pagetitles.licenses',
	createLicense: 'lup.licenses.breadcrumb.new',
	editLicense: 'lup.a11y.edit_license'
};

export const licenceLayoutConfig = new LayoutConfigBuilder(LayoutConfigPresets.default)
	.setBodyCssClasses(['bg--white'])
	.build();
