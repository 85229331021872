import { StringOrNumber } from '../../../core';
import { LearningJourneyRoutesConfig } from './learning-journey-routes.config';

export class LearningJourneyRoutes {
	public static LJ_EDIT_ROUTE = LearningJourneyRoutesConfig.EDIT_LJ_SEGMENT;
	public static LJ_WORKFLOW_ROUTE = `${LearningJourneyRoutesConfig.LJ_ID_VERSION_SEGMENT}`;
	public static readonly LJ_HOME_ROUTE = LearningJourneyRoutesConfig.LJ_BASE_URL;
	public static readonly LJ_ENROLLMENTS_ROUTE = LearningJourneyRoutesConfig.ENROLLMENTS_SEGMENT;
	public static getEditLJRoute(id: StringOrNumber, version: StringOrNumber): string {
		return `${LearningJourneyRoutes.LJ_HOME_ROUTE}/${id}/${version}/${LearningJourneyRoutes.LJ_EDIT_ROUTE}`;
	}
	public static getEnrollmentsRoute(id: StringOrNumber, version: StringOrNumber): string {
		return `${LearningJourneyRoutes.LJ_HOME_ROUTE}/${id}/${version}/${LearningJourneyRoutes.LJ_ENROLLMENTS_ROUTE}`;
	}
}
