import { Component, Input } from '@angular/core';
import { NullStateOptions } from './null-state.models';

/**
 * Null State - global null state component that we'll use across
 */
@Component({
	selector: 'angie-null-state',
	templateUrl: './null-state.component.html',
	styleUrls: ['./null-state.component.scss']
})
export class NullStateComponent {
	/**
	 * Null State Options
	 */
	@Input() options: NullStateOptions;
}
