import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LuModalConfig } from 'lu-modal';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ErrorPosition, LuFormlyInputBuilder } from 'lu-formly';
import { TranslateService } from '@ngx-translate/core';
import { inputFieldsValidationConstants } from '../../../../globals';

@Component({
	selector: 'angie-proxy-user-modal',
	templateUrl: './proxy-user-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProxyUserModalComponent {
	modalOptions: LuModalConfig;

	formGroup = new FormGroup({});
	formFields: FormlyFieldConfig[] = this.buildProxyUserMessageInput();
	formModel = {};

	constructor(private translateService: TranslateService) {}

	buildProxyUserMessageInput(): FormlyFieldConfig[] {
		const proxyMessageInput = new LuFormlyInputBuilder(
			'proxy_message',
			this.translateService.instant('lup.proxy_user.impersonation_resolution_message.modal_body'),
			''
		)
			.setMaxLength(inputFieldsValidationConstants.MAX_TEXT_INPUT_LENGTH)
			.setMaxLengthMessage(true, inputFieldsValidationConstants.MAX_TEXT_INPUT_LENGTH)
			.hideRequiredMarker(true)
			.setErrorPosition(ErrorPosition.LEFT)
			.build();

		return [proxyMessageInput];
	}
}
