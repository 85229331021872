import { angieRoutePrefix } from 'src/app/globals';

/**
 * Core Routes definition
 */
export class CoreRoutes {
	/**
	 * Main routes that is in charge for initial user and portal meta fetch
	 */
	public static MY_ACCOUNT = angieRoutePrefix.concat('/my-account.json');

	/**
	 * Loads all portal meta before sign in, same as my account without current user.
	 */
	public static AUTH_INFO = angieRoutePrefix.concat('/auth-info.json');

	/**
	 * Sign in
	 */
	public static SIGN_IN = '/users/sign_in.json';

	/**
	 * Sign up
	 */
	public static SIGN_UP = '/users/sign_up.json';

	/**
	 * Code redeem
	 */
	public static CODE_REDEEM = '/users/code_redeem.json';

	/**
	 * Forgot password
	 */
	public static FORGOT_PASSWORD = 'users/password/new.json';

	/**
	 * RESET password
	 */
	public static RESET_PASSWORD = 'users/password/edit.json';

	/**
	 * RESET default password
	 */
	public static RESET_DEFAULT_PASSWORD = 'users/password/default/edit.json';

	/*
	 * Change Password
	 */

	public static CHANGE_PASSWORD = '/users/password/changing_password_for_the_first_time.json';

	/*
	 * Terms and service
	 */

	public static TERMS_OF_SERVICE = 'users/users_terms_of_service.json';
	public static ACCEPT_TERMS = '/users/terms_of_service/accept.json';
	public static REJECT_TERMS = '/users/terms_of_service/reject.json';

	/**
	 * Load Inbox messages for specific user
	 */
	public static INBOX = angieRoutePrefix.concat('/messages.json');
	/**
	 * Load list of subportals for specific portal
	 */
	public static MY_PORTALS = angieRoutePrefix.concat('/my-portals.json');
	/**
	 * List out all available languages for specific user
	 */
	public static LANGUAGES = angieRoutePrefix.concat('/languages.json');
	/**
	 * Global search on our portal searches for records (users/resources/groups etc.)
	 */
	public static SMART_SEARCH = angieRoutePrefix.concat('/search.json');

	/**
	 * Start sidekiq job which we'll use for pooling
	 */
	public static START_JOB = '/download_jobs.json';
	/**
	 * After we start job - we'll ping it and check for progress on that job
	 */
	public static PING_JOB = (code: string, type?: number): string =>
		`/download_jobs/${code}/status.json${type ? '?type=' + type : ''}`;

	public static PROFILE_IMAGE_PING_JOB = (code: string): string =>
		angieRoutePrefix.concat(`/uploads/job_status/${code}/status.json`);

	/**
	 * Breadcrumbs routes
	 */
	public static BREADCRUMBS = (type: string, id: number): string => angieRoutePrefix.concat(`/${type}/${id}.json`);

	/**
	 * Angie Job tracker
	 */
	public static JOB_TRACKER = (job_id: string): string => angieRoutePrefix.concat(`/job-tracker/${job_id}.json`);
}
