import { ViewportScroller } from '@angular/common';
import { Component, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { LuIconType } from 'lu-icon';
import { DocumentRefService } from 'src/app/core';

@Component({
	selector: 'angie-skip-link',
	templateUrl: './skip-link.component.html',
	styleUrls: ['./skip-link.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkipLinkComponent {
	@Output() a11yFocus: EventEmitter<void> = new EventEmitter<void>();
	public readonly LuIconType = LuIconType;

	constructor(
		private readonly viewportScroller: ViewportScroller,
		private readonly documentRef: DocumentRefService
	) {}

	/**
	 * Skip to content functionality
	 */
	skipToContent(): void {
		const offset = 100;
		this.viewportScroller.scrollToPosition([0, offset]);
		this.a11yFocus.next();
		(this.documentRef.document.querySelector('#main-content') as HTMLElement).focus();
	}

	/**
	 * If skip to content has focus - we scroll page to top
	 */
	skipToContentHasFocus(): void {
		this.viewportScroller.scrollToPosition([0, 0]);
	}
}
