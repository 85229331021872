import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LuIconModule } from 'lu-icon';
import { AngieDrawerHeaderComponent } from './drawer-header.component';

@NgModule({
	declarations: [AngieDrawerHeaderComponent],
	exports: [AngieDrawerHeaderComponent],
	imports: [CommonModule, LuIconModule, RouterLink]
})
export class AngieDrawerHeaderModule {}
