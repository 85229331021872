import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { LuIconModule } from 'lu-icon';

import { LuButtonFormlyComponent } from './lu-button-formly.component';

export const imports = [
	CommonModule,
	ReactiveFormsModule,
	LuIconModule,
	FormlyModule.forChild({
		types: [
			{
				name: 'lu-button-formly',
				component: LuButtonFormlyComponent,
				wrappers: ['form-field']
				// defaultOptions: {
				// 	templateOptions: {
				// 		btnType: 'default',
				// 		type: 'button'
				// 	}
				// }
			}
		]
	})
];

export const declarations = [LuButtonFormlyComponent];

@NgModule({
	imports,
	declarations,
	exports: [...declarations],
	providers: []
})
export class LuButtonFormlyModule {}
