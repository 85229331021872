import { Injectable } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { WindowRefService } from './window-ref.service';

/**
 * Window Resize definition
 */
export interface WindowSize {
	/**
	 * Window Height
	 */
	h: number;
	/**
	 * Window Width
	 */
	w: number;
}

/**
 * Window Resizer service to track window resize and act accordingly
 */

@Injectable({
	providedIn: 'root'
})
export class WindowSizerService {
	/**
	 * Native window that is loaded from within our window service
	 */
	window = this.windowService.nativeWindow;
	/**
	 * Resize Observable - emits value on each resize
	 */
	resizeObservable$: Observable<WindowSize>;

	/**
	 * Constructor - tracks resize
	 */
	constructor(private readonly windowService: WindowRefService) {
		this.resizeObservable$ = fromEvent(this.window, 'resize').pipe(
			startWith(this.calculateWindowSize()),
			map(() => {
				return this.calculateWindowSize();
			})
		);
	}

	/**
	 * Format Data according to our WindowSize definition
	 */
	private calculateWindowSize(): WindowSize {
		return {
			h: this.window.innerHeight,
			w: this.window.innerWidth
		};
	}
}
