import {
	DefaultLayoutStructure,
	LayoutConfig,
	LayoutConfigPresets,
	MinimalHeadersLayoutStructure
} from '../models/layout-structures.models';

export class LayoutConfigBuilder {
	// Empty structure initially, which makes
	// all of the elements off by default.
	// This is because we will have just few
	// out of potentially a lot elements
	// elements visible at the time.
	// Therefore, best default setting is off for all.
	// Their switches being off  means that we wont have to track
	// their state to toggle them manually later on, unless we want
	// them on.
	layoutConfig = new LayoutConfig();

	public static getDefaultLayoutPreset(): LayoutConfig {
		const layoutConfig = new LayoutConfig(new DefaultLayoutStructure());
		return layoutConfig;
	}

	public static getMinimalLayoutPreset(): LayoutConfig {
		const layoutConfig = new LayoutConfig(new MinimalHeadersLayoutStructure());
		return layoutConfig;
	}

	public static getEmptyLayoutPreset(): LayoutConfig {
		return new LayoutConfig();
	}

	/**
	 *
	 * @param preset Layout config preset
	 *
	 * Returns configurable builder instance
	 * initialized with one of LayoutConfigPresets presets.
	 */
	constructor(preset: LayoutConfigPresets) {
		this.initializeConfig(preset);
	}

	private initializeConfig(preset: LayoutConfigPresets): void {
		switch (preset) {
			case LayoutConfigPresets.default:
				this.setLayoutToDefault();
				break;
			case LayoutConfigPresets.minimal:
				this.setLayoutToMinimal();
				break;
		}
	}

	setLayoutToDefault(): LayoutConfigBuilder {
		this.layoutConfig = LayoutConfigBuilder.getDefaultLayoutPreset();
		return this;
	}

	setLayoutToMinimal(): LayoutConfigBuilder {
		this.layoutConfig = LayoutConfigBuilder.getMinimalLayoutPreset();
		return this;
	}

	showDefaultTopHeader(show: boolean): LayoutConfigBuilder {
		this.layoutConfig.structure.showDefaultTopHeader = show;
		return this;
	}

	showDefaultSubHeader(show: boolean): LayoutConfigBuilder {
		this.layoutConfig.structure.showDefaultSubHeader = show;
		return this;
	}

	showDefaultFooter(show: boolean): LayoutConfigBuilder {
		this.layoutConfig.structure.showDefaultFooter = show;
		return this;
	}

	showMinimalTopHeader(show: boolean): LayoutConfigBuilder {
		this.layoutConfig.structure.showMinimalTopHeader = show;
		return this;
	}

	setBodyCssClasses(classes: string[]): LayoutConfigBuilder {
		this.layoutConfig.cssClasses.body = classes;
		return this;
	}

	setHeaderCssClasses(classes: string[]): LayoutConfigBuilder {
		this.layoutConfig.cssClasses.header = classes;
		return this;
	}

	setContentCssClasses(classes: string[]): LayoutConfigBuilder {
		this.layoutConfig.cssClasses.content = classes;
		return this;
	}

	setFooterCssClasses(classes: string[]): LayoutConfigBuilder {
		this.layoutConfig.cssClasses.footer = classes;
		return this;
	}

	setAdminWrapCssClasses(classes: string[]): LayoutConfigBuilder {
		this.layoutConfig.cssClasses.adminWrap = classes;
		return this;
	}

	setHideAdminNav(value: boolean): LayoutConfigBuilder {
		this.layoutConfig.structure.showAdminNav = !value;
		return this;
	}

	/**
	 *
	 * @param elementKey Key under which to look for elements classes in cssclasses config portion.
	 * @param classes CSS class array.
	 *
	 * Will set classes for any element regardlesly of whether its part of current element declaration.
	 */
	setAnyElementCssClasses(elementKey: string, classes: string[]): LayoutConfigBuilder {
		this.layoutConfig.cssClasses[elementKey] = classes;
		return this;
	}

	/**
	 *
	 * @param elementKey Key under which element has been registered.
	 * @param show To show element or not.
	 *
	 * Will toggle any element regardlesly of whether its part of current element declaration.
	 */
	setShowAnyElement(elementKey: string, show: boolean): LayoutConfigBuilder {
		this.layoutConfig.structure[elementKey] = show;
		return this;
	}

	build(): LayoutConfig {
		return this.layoutConfig;
	}
}
