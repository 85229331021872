<div class="minimal-header o-flex o-flex--vcenter o-flex--space-between" [ngClass]="{'disabled minimal-header--disabled': disabled$ | async}">
	@if (linkConfig$ | async; as linkConfig) {
		<a
		class="link-back minimal-header__link"
		[routerLink]="linkConfig?.routerLink"
		(click)="linkConfig?.callback && linkConfig.callback()">
			<!-- Placement of linkConfigTest is due to mistery whitespace which appears otherwise -->
			<lu-icon [icon]="linkConfig?.icon || 'lu-arrow-left'" class="mr-4"></lu-icon>{{linkConfig?.text}}
		</a>
	}
	@if (mainActionConfig$ | async; as mainActionConfig) {
		<div class="o-flex o-flex--flex-center o-flex--vcenter minimal-header__main-action-holder">
			<p class="h-m mr-4" [ngClass]="mainActionConfig?.class">{{ mainActionConfig.label }}</p>
			@if (mainActionConfig.button) {
				<button
				type="button"
				(click)="mainActionConfig.button.onClick && mainActionConfig.button.onClick()"
				class="btn btn--icon"
				[ngClass]="mainActionConfig.button.class || 'btn--ghost-theme'"
				[class.disabled]="mainActionConfig.button.disabled"
				[disabled]="mainActionConfig.button.disabled">
				<lu-icon [icon]="mainActionConfig.button.icon" [ngClass]="{'btn__ico': mainActionConfig.button.label}"></lu-icon>
				@if (mainActionConfig.button.label) {
					<span class="btn__txt">{{ mainActionConfig.button.label }}</span>
				}
			</button>
			}
		</div>
	}
	<div class="minimal-header__actions-holder">
		@if (actionConfig$ | async; as actionConfig) {
			<div class="o-flex">
				<button
					*ngFor="let button of actionConfig.buttons"
					type="button" class="btn btn--actions-nav"
					[ngClass]="button.class || 'btn--default'"
					[class.disabled]="button.disabled"
					[disabled]="button.disabled"
					(click)="button.onClick && button.onClick()">
					@if (button.icon) {
						<lu-icon class="btn__ico" [icon]="button.icon"></lu-icon>
					}
					<span class="btn__txt">{{ button.label }}</span>
				</button>
		
				@if(actionConfig?.menuConfig; as menuConfig) {
					<angie-drop-down class="ml-12" [dropDownItems]="menuConfig?.dropDownItems" [dropDownDisabled]="menuConfig?.dropDownDisabled" [customClass]="menuConfig?.class" [popoverPosition]="menuConfig?.popoverPosition" [popoverPositionCustomClass]="menuConfig?.popoverPositionClass"></angie-drop-down>
				}
			</div>
		}
	</div>
</div>
