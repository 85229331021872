import { ChangeDetectorRef, Directive, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { NavigationService } from '../../../core';
import { BaseComponent } from './base.component';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseNavigationComponent extends BaseComponent implements OnInit {
	protected constructor(
		protected readonly router: Router,
		protected readonly changeDetectorRef: ChangeDetectorRef,
		protected readonly navigationService: NavigationService
	) {
		super();
	}

	ngOnInit(): void {
		this.clearOutMiddleNavigationOnRouteChange();
	}

	protected abstract onRouteChangeCallback(event?: NavigationEnd): void;

	/**
	 * After route change
	 */
	private clearOutMiddleNavigationOnRouteChange(): void {
		this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.onRouteChangeCallback(event);
				this.changeDetectorRef.detectChanges();
			}
		});
	}
}
