import { LayoutConfigBuilder } from '../../../layouts/default-layout/builders/layout-config.builder';
import { LayoutConfigPresets } from '../../../layouts/default-layout/models/layout-structures.models';

export enum CertificatesRoutesConfig {
	CERTIFICATES_BASE_ROUTE = 'certificates',
	EDIT_CERTIFICATE_URL_SEGMENT = 'edit',
	NEW_CERTIFICATE_URL_SEGMENT = 'new',
	CERTIFICATE_ID_PARAM = ':certificateId'
}

export const CertificatesRouteTitles = {
	listPage: 'lup.pagetitles.certifications'
};

export const certificatesListLayoutConfig = new LayoutConfigBuilder(LayoutConfigPresets.default)
	.setBodyCssClasses(['bg--gray-lighter'])
	.build();

export const certificatesEditRoutesLayoutConfig = new LayoutConfigBuilder(LayoutConfigPresets.minimal)
	.setBodyCssClasses(['bg--gray-lighter'])
	.setContentCssClasses(['wrap--no-subheader', 'wrap--action-footer'])
	.setHeaderCssClasses(['header--no-scroll'])
	.setFooterCssClasses(['footer-white'])
	.setAdminWrapCssClasses(['admin-wrap--no-sidenav'])
	.build();
