import { AfterContentInit, Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { StateService } from './core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { InsightsService } from './modules/insights/services/insights.service';

/**
 * List of urls where tracking must be disabled.
 * e.g. Authorization pages
 */
const SKIP_TRACKING: RegExp[] = [
	/^\/users\/sign_in/,
	/^\/users\/auth/,
	/^\/users\/sign_up/,
	/^\/users\/confirmation/,
	/^\/users\/code_redeem/,
	/^\/accept_invitation/,
	/^\/users\/password/,
	/^\/users\/privacy-policy/
];

/**
 * App Component - our main bootstrap component.
 * This component has been initialized first and all the other components will get loaded from here.
 * This one is used inside our index.html.erb where we define our root level tag (this).
 */
@Component({
	selector: 'angie-app',
	templateUrl: './app.component.html'
})
export class AppComponent implements AfterContentInit, OnInit {
	/**
	 * Constructor
	 */
	constructor(
		private readonly meta: Meta,
		private readonly stateService: StateService,
		private readonly router: Router,
		private readonly insightsService: InsightsService
	) {}

	ngOnInit(): void {
		this.router.events
			.pipe(
				filter((event): event is NavigationEnd => event instanceof NavigationEnd),
				filter((event): event is NavigationEnd => SKIP_TRACKING.findIndex(it => it.test(event.url)) < 0)
			)
			.subscribe(event => this.insightsService.pageViewEvent(event));
	}

	/**
	 * After View Init LifeCycle hook
	 */
	ngAfterContentInit(): void {
		this.setXSRFToken();
	}

	/**
	 * Method to get called after content has been inited
	 * We're going to set xsrfToken token here which we'll read from meta csrf-token and set it into state service
	 */
	private setXSRFToken(): void {
		this.stateService.xsrfToken = this.meta.getTag('name="csrf-token"').content;
	}
}
