import { isNull, omitBy } from 'lodash-es';
import { ActionBarConfig } from '../../../angie-shared/components/angie-form-section/angie-action-bar/angie-action-bar.model';
import { DrawerConfig, DrawerMode, DrawerPosition, DrawerSize, DrawerType } from './drawer.model';

export const drawerDefaultConfig: Partial<DrawerConfig> = {
	size: DrawerSize.DEFAULT,
	mode: DrawerMode.OVER,
	type: DrawerType.COLLAPSABLE,
	position: DrawerPosition.END,
	actionBar: {
		hidden$: false,
		className: 'action-bar--no-background',
		actionItems: null
	},
	hideDefaultHeader: false
};

export function setDefaultDrawerValues(config: DrawerConfig): DrawerConfig {
	config.actionBar = config.actionBar || null;
	if (!!config.actionBar) {
		const actionBarConfig: Partial<ActionBarConfig> = {
			...omitBy(drawerDefaultConfig.actionBar, isNull),
			...config.actionBar
		};
		config.actionBar = actionBarConfig as ActionBarConfig;
	}
	config = {
		...drawerDefaultConfig,
		...config
	} as DrawerConfig;

	return config;
}
