<div class="crd crd--list crd--3dot crd--no-effect">
	<div class="crd__bd">
		<!--User name-->
		<div class="o-flex2 o-flex2--ellipsis-cntr o-flex o-flex--vcenter">
			<a [href]="'/groups/' + data.id" class="ellipsis" [attr.aria-label]="('custom_email_templates.group_name' | translate) + ' ' + data.title">
				{{ data.title }}
			</a>
		</div>
		<!--Action list-->
		<div class="o-flex1 crd__act o-flex o-flex--vcenter">
			<div class="o-flex order-1 dots-ddm dots-ddm--sm">
				<button class="dots-ddm__btn link-c def-focus"
								(click)="toggleEditMode()"
								[title]="(editMode ? 'close' : 'lup.a11y.more_options') | translate"
								[attr.aria-pressed]="editMode"
								[attr.aria-expanded]="editMode"
								[attr.aria-haspopup]="editMode">
					<lu-icon [type]="LuIconType.SOLID" icon="ellipsis-v" *ngIf="!editMode"></lu-icon>
					<lu-icon [type]="LuIconType.SOLID" icon="times" *ngIf="editMode"></lu-icon>
				</button>
			</div>
			<div class="o-flex">
				<ul class="gs-action-list" *ngIf="editMode" [attr.aria-hidden]="!editMode">
					<li>
						<a [href]="'/groups/' + data.id" [attr.aria-label]="'lup.global_search.edit_group' | translate">
							<lu-icon [type]="LuIconType.SOLID" icon="pencil gray-icon"></lu-icon>{{ 'lup.global_search.edit_group' | translate }}
						</a>
					</li>
					<li>
						<a [href]="'/groups/' + data.id + '/users'" [attr.aria-label]="'lup.global_search.users' | translate">
							<lu-icon [type]="LuIconType.SOLID" icon="users gray-icon"></lu-icon>{{ 'lup.global_search.users' | translate }}
						</a>
					</li>
					<li>
						<a [href]="'/batch-upload?add_to_groups=true&group_id=' + data.id" [attr.aria-label]="'lup.global_search.batch_user_upload'  | translate">
							<lu-icon [type]="LuIconType.SOLID" icon="plus gray-icon"></lu-icon>{{ 'lup.global_search.batch_user_upload'  | translate }}
						</a>
					</li>
					<li>
						<a [href]="'/groups/' + data.id + '/courses'" [attr.aria-label]="'lup.global_search.courses'">
							<lu-icon [type]="LuIconType.SOLID" icon="list gray-icon"></lu-icon>{{ 'lup.global_search.courses' | translate }}
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>
