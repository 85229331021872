import { FormlyFieldConfig } from '@ngx-formly/core/lib/models/fieldconfig';
import { InputType, LuFormlyDateTimeBuilder, LuFormlyInputBuilder, LuFormlyTypeaheadBuilder } from 'lu-formly';
import { LuTypeaheadBuilder } from 'lu-typeahead';
import { CustomUserData, CustomUserDataFieldType, CustomUserDataSelectType } from '../../../core';
import { CUD_DECIMAL_STEP, DATE_FORMAT } from '../../../globals';

export interface CudSelectOption {
	name: string;
	value: number;
}
export const checkIfFieldIsNumericalType = (fieldType: CustomUserDataFieldType): boolean => {
	const numericTypes = [
		CustomUserDataFieldType.DECIMAL,
		CustomUserDataFieldType.INTEGER,
		CustomUserDataFieldType.DECIMAL_CHOICE,
		CustomUserDataFieldType.INTEGER_CHOICE
	];
	return numericTypes.some(type => type === fieldType);
};

export const checkIfFieldIsDecimalType = (fieldType: CustomUserDataFieldType): boolean => {
	return fieldType === CustomUserDataFieldType.DECIMAL;
};

export const checkIfFieldIsChoiceTypeCUD = (fieldType: CustomUserDataFieldType): boolean => {
	return [
		CustomUserDataFieldType.STRING_CHOICE,
		CustomUserDataFieldType.DECIMAL_CHOICE,
		CustomUserDataFieldType.INTEGER_CHOICE
	].some(type => fieldType === type);
};

export const checkIfFieldIsSingleChoiceTypeCUD = (selectType: CustomUserDataSelectType): boolean => {
	return selectType === CustomUserDataSelectType.SINGLE_SELECT;
};

export function buildSelectFormlyOptionsFromCud(cuds: CustomUserData[]): CudSelectOption[] {
	return cuds.map(cud => {
		return { name: cud.field_label, value: cud.id };
	});
}

export function getCudOptionsField(
	cud: CustomUserData,
	formControlName: string,
	label: string,
	placeholder: string = null
): FormlyFieldConfig {
	switch (cud.field_type) {
		case CustomUserDataFieldType.STRING_CHOICE:
		case CustomUserDataFieldType.DECIMAL_CHOICE:
		case CustomUserDataFieldType.INTEGER_CHOICE:
			return buildCudTypeahead(cud, placeholder, label, formControlName);
		case CustomUserDataFieldType.INTEGER:
			return new LuFormlyInputBuilder(formControlName, label, placeholder)
				.setInputType(InputType.NUMBER)
				.setRequired(true)
				.build();
		case CustomUserDataFieldType.DECIMAL:
			const field = new LuFormlyInputBuilder(formControlName, label, placeholder)
				.setInputType(InputType.NUMBER)
				.setRequired(true)
				.build();
			field.templateOptions.step = CUD_DECIMAL_STEP;
			return field;
		case CustomUserDataFieldType.DATE:
			return new LuFormlyDateTimeBuilder(formControlName, label, placeholder)
				.setRequired(true)
				.hideRequiredMarker(true)
				.hideWrapperLabel(true)
				.setHideTimepicker(true)
				.setDateOnly(true)
				.setDateFormat(DATE_FORMAT)
				.build();
		default:
			return new LuFormlyInputBuilder(formControlName, label, placeholder).setRequired(true).build();
	}
}

export function buildCudTypeahead(
	cud: CustomUserData,
	placeholder: string,
	label: string,
	formControlName: string
): FormlyFieldConfig {
	const typeTypeaheadOptions = LuTypeaheadBuilder.buildSimple('value', 'value', 'value', 'value', placeholder, null);

	const initialItems = cud.predefined_values.split(',').map(itemValue => {
		const value = checkIfFieldIsNumericalType(cud.field_type) ? +itemValue : itemValue;
		return { value };
	});

	return new LuFormlyTypeaheadBuilder(formControlName, label, placeholder)
		.setOptions(typeTypeaheadOptions)
		.setRequired(true)
		.setInitialItems(initialItems)
		.build();
}
