import { isNull, parseInt } from 'lodash-es';
import { LuStateTimezone, LuStateTimezoneGMT } from '../../../core';
import { durationConstants } from '../../../globals';

export function convertUtcIsoToLocaleIsoString(isoString: string, formatWithTimezone: boolean = true): string {
	const date = new Date(isoString);
	const timezone = -date.getTimezoneOffset();
	const timezoneDif = timezone >= 0 ? '+' : '-';
	function pad(num: number): string {
		const norm = Math.floor(Math.abs(num));
		return (norm < 10 ? '0' : '') + norm;
	}

	let formatedDate =
		date.getFullYear() +
		'-' +
		pad(date.getMonth() + 1) +
		'-' +
		pad(date.getDate()) +
		'T' +
		pad(date.getHours()) +
		':' +
		pad(date.getMinutes()) +
		':' +
		pad(date.getSeconds());

	if (formatWithTimezone) {
		formatedDate = formatedDate + timezoneDif + pad(timezone / 60) + ':' + pad(timezone % 60);
	}

	return formatedDate;
}

/**
 * Get date delta for a given date.
 * Arguments passed for days/months/years are numbers can be positive or negative
 * depending on the type of delta
 */

export function deltaDate(date: Date, days: number, months: number, years: number): Date {
	return new Date(
		date.getFullYear() + years,
		date.getMonth() + months,
		Math.min(date.getDate() + days, new Date(date.getFullYear() + years, date.getMonth() + months + 1, 0).getDate())
	);
}

/**
 * Offset some date by some number of hours
 */
export function offsetDateHours(date: Date, hours: number, isPM: boolean = false): Date {
	const hoursOffset = isPM ? 12 : 0;
	date.setHours(hours + hoursOffset);
	return date;
}

/**
 * Offset some date by some minutes
 */
export function offsetDateMinutes(date: Date, minutes: number): Date {
	date.setMinutes(minutes);
	return date;
}

/**
 * Convert LuStateTimezone to LuStateTimezoneGMT
 * by converting offset to GMT offset string e.g. from 3600 to (GMT+01:00)
 */
export function fromTimeZoneToTimeZoneGMT(tz: LuStateTimezone): LuStateTimezoneGMT {
	const timeZone = tz?.utc_offset || 0;
	const getOffset = Math.abs(tz.utc_offset / 3600).toString();
	const isAbs = timeZone < 0 ? '-' : '+';
	const hours = parseInt(getOffset);
	const minutesSplit = getOffset.split('.')[1];
	const minutes = minutesSplit ? 60 / (100 / +minutesSplit) : '00';
	const calculatedOffset = `${isAbs}${hours.toString().padStart(2, '0')}:${minutes.toString().padEnd(2, '0')}`;
	const tzGMT: LuStateTimezoneGMT = { ...tz, gmt: `(GMT${calculatedOffset}) ${tz.name}` };
	return tzGMT;
}

/**
 * If we do have date in format of 2020-11-02T00:00:00Z it won't work for all of the timezones
 *
 * new Date will offset this to 2020-11-01 for US so we would need to offset it
 *
 * 2020-11-02T00:00:00Z - to new Date with same values so it remains the same date as 2020-11-02T00:00:00
 */
export function UTCAsLocaleDateObject(dateString: string | Date): Date {
	if (!dateString || isNull(dateString)) return null;
	const date = new Date(dateString);
	const timezoneOffset = new Date().getTimezoneOffset() * 60_000;
	return new Date(date.getTime() + timezoneOffset);
}

/**
 * This method takes two arguments (timeZone and date)
 * and returns that date in users system time with timeZone applied
 * */
export function setDateToLuTimeZone(tz: LuStateTimezone, date: Date): Date {
	const tzGMT: LuStateTimezoneGMT = fromTimeZoneToTimeZoneGMT(tz);
	const gmtOffset = tzGMT.gmt.split(' ')[0].replace('(GMT', '').replace(')', '');
	const dateWithOffset = convertUtcIsoToLocaleIsoString(date.toISOString()).slice(0, -6) + gmtOffset;
	return new Date(dateWithOffset);
}

/**
 * This method takes two arguments (timeZone (LuStateTimezone) and date)
 * and returns that date in target timezone
 * */
export function getDateInSpecificTimezone(tz: LuStateTimezone, date: Date = new Date()): Date {
	const tzGMT: LuStateTimezoneGMT = fromTimeZoneToTimeZoneGMT(tz);
	const targetOffset = tzGMT.utc_offset / durationConstants.HOUR_IN_MINUTES;
	const currentOffset = -date.getTimezoneOffset();
	const timeDifference = (targetOffset - currentOffset) * 60 * 1000;
	return new Date(date.getTime() + timeDifference);
}
