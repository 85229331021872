import { angieRoutePrefix } from '../../../globals';

export enum UsersRoutesConfig {
	USERS = 'users',
	NEW_USER = 'new',
	EXISTING_USER = ':id',
	USER_SETTINGS = 'user-settings',
	CUSTOM_DATA = 'custom-data',
	USERS_PENDING = 'users-pending',
	USERS_LEADERBOARD = 'users-leaderboard',
	RECENT_ACTIVITIES = 'recent-activities',
	SCHEDULED_SFTP_IMPORT = 'scheduled-sftp-import'
}
/**
 * Users Routes inside Angie app
 */
export const AppUsersRoutes = {
	// URL segment names - used as is for routing modules
	USERS: UsersRoutesConfig.USERS,
	EXISTING_USER: UsersRoutesConfig.EXISTING_USER,
	USER_SETTINGS: UsersRoutesConfig.USER_SETTINGS,
	CUSTOM_DATA: UsersRoutesConfig.CUSTOM_DATA,
	NEW_USER: UsersRoutesConfig.NEW_USER,
	USERS_PENDING: UsersRoutesConfig.USERS_PENDING,
	USERS_LEADERBOARD: UsersRoutesConfig.USERS_LEADERBOARD,
	RECENT_ACTIVITIES: UsersRoutesConfig.RECENT_ACTIVITIES,
	SCHEDULED_SFTP_IMPORT: UsersRoutesConfig.SCHEDULED_SFTP_IMPORT,

	users(): string {
		return AppUsersRoutes.USERS;
	},

	usersPending(): string {
		return AppUsersRoutes.USERS_PENDING;
	},

	usersLeaderboard(): string {
		return AppUsersRoutes.USERS_PENDING;
	},

	newUser(): string {
		return `${AppUsersRoutes.users()}/${AppUsersRoutes.NEW_USER}`;
	},

	existingUser(userId: number): string {
		return `${AppUsersRoutes.users()}/${userId}`;
	},

	existingUserSettings(userId: number): string {
		return `${AppUsersRoutes.existingUser(userId)}/${AppUsersRoutes.USER_SETTINGS}`;
	},

	existingUserCustomData(userId: number): string {
		return `${AppUsersRoutes.existingUser(userId)}/${AppUsersRoutes.CUSTOM_DATA}`;
	},

	existingUserRecentActivities(userId: number): string {
		return `${AppUsersRoutes.existingUser(userId)}/${AppUsersRoutes.RECENT_ACTIVITIES}`;
	}
};

export class UsersRoutes {
	public static CREATE_USER = angieRoutePrefix.concat('/users/create.json');
	public static DEACTIVATE_2FA = angieRoutePrefix.concat('/two-factor-authentication/disable-for-another-user.json');
	public static UPDATE_USER = (id: number): string => angieRoutePrefix.concat(`/users/${id}.json`);
	public static UPDATE_USERS_PASSWORD = (id: number): string =>
		angieRoutePrefix.concat(`/users/${id}/change_password.json`);
	public static RESET_USERS_PASSWORD_REQUEST = (id: number): string =>
		angieRoutePrefix.concat(`/users/${id}/reset-password-request.json`);
	public static GET_BASIC_USER_INFO = (id: number): string => angieRoutePrefix.concat(`/users/${id}.json`);
	public static GET_CUSTOM_USER_DATA = (id: number): string => angieRoutePrefix.concat(`/users/${id}.json`);
	public static GET_USER_SETTINGS = (id: number): string => angieRoutePrefix.concat(`/users/${id}.json`);
	public static DELETION_REQUEST = (id: number): string =>
		angieRoutePrefix.concat(`/users/${id}/request_deletion.json`);
	public static CANCEL_DELETION_REQUEST = (id: number): string =>
		angieRoutePrefix.concat(`/users/${id}/cancel_deletion_request.json`);
	public static GET_GROUPS_USER_MANAGE = (id: number): string =>
		angieRoutePrefix.concat(`/users/${id}/groups_manage_fillers.json`);
	public static GET_PROFILE_IMAGE = (id: number): string => angieRoutePrefix.concat(`/users/${id}/profile-image.json`);
	public static DELETE_PROFILE_IMAGE = (id: number): string =>
		angieRoutePrefix.concat(`/users/${id}/profile-image.json`);
	public static GET_RECENT_ACTIVITIES = (id: number): string =>
		angieRoutePrefix.concat(`/users/${id}/recent_activities.json`);
	public static SYNC_SF = (id: number): string => angieRoutePrefix.concat(`/users/${id}/sync_sf.json`);
	public static RUN_SF_RULE = (id: number): string => angieRoutePrefix.concat(`/users/${id}/run_sf_rules.json`);
	public static NUMBER_OF_TUTORED_COURSES = (id: number): string => `users/${id}/no-of-tutored-courses.json`;
	public static DELETE_USER = (pmId: number): string => `/users/${pmId}.json`;
	public static AVAILABLE_INSTRUCTOR_REPLACEMENT = (pmId: number): string =>
		angieRoutePrefix.concat(`/users/${pmId}/available-replacements.json`);
	public static TRAINING_OWNER_REPLACEMENT = angieRoutePrefix.concat(`/users/training-owner-replacements.json`);
	public static RETURN_TO_IMPERSONATOR = (id: number): string =>
		angieRoutePrefix.concat(`/impersonation/${id}/impersonate.json`);
}
